import {Button, ButtonProps, Typography} from "@mui/material";
import {FontAwesomeIcon, FontAwesomeIconProps} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import React, {ReactElement} from "react";
import {TooltipWithWrapper} from "src/components/common/tooltips/TooltipWithWrapper.tsx";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {resolveValueOrProvider, ValueOrProvider} from "src/utils/valueOrProvider.ts";
import {AavoIconButton} from "src/components/common/buttons/AavoIconButton.tsx";
import {logError} from "src/errorHandling/errorLogging.ts";
import {useErrorDialog} from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import {Breakpoint} from "@mui/system";
import {useMediaQueryBreakpointDown} from "src/utils/breakpointUtils.ts";
import {mergeSx} from "src/utils/styles.ts";

export interface AavoButtonProps
	extends Omit<ButtonProps, "startIcon" | "endIcon" | "disabled" | "onSubmit" | "color" | "action"> {
	loading?: boolean;
	reserveSpaceForLoadingSpinner?: boolean;
	label?: string;
	icon?: IconDefinition | null;
	iconProps?: Partial<FontAwesomeIconProps>;
	endIcon?: IconDefinition;
	iconEl?: React.ReactNode;
	endIconEl?: React.ReactNode;
	disabled?: ValueOrProvider<boolean | string>;
	tooltip?: ValueOrProvider<React.ReactNode>;
	iconOnlyIfDown?: Breakpoint;
	color?: Exclude<ButtonProps["color"], "inherit">;
	disableTooltipEl?: boolean;
}

export const AavoButton = React.forwardRef(
	(
		{
			loading = false,
			reserveSpaceForLoadingSpinner = false,
			color,
			disabled: disabledValueProvider,
			tooltip: tooltipProvider,
			endIcon,
			icon,
			iconProps,
			endIconEl,
			iconEl,
			children,
			label,
			onClick,
			sx,
			iconOnlyIfDown,
			variant,
			disableTooltipEl,
			...other
		}: AavoButtonProps,
		ref: ButtonProps["ref"],
	) => {
		const { logErrorAndShowOnDialog } = useErrorDialog();

		const tooltipTitle = resolveTooltip();

		const disabled = resolveValueOrProvider(disabledValueProvider);
		const isDisabled = loading || (typeof disabled === "string" ? disabled.length > 0 : disabled);

		const startIconElement = resolveStartIconEl();

		const shouldShowOnlyIcon = useMediaQueryBreakpointDown(iconOnlyIfDown ?? "xs");

		const noLabelGiven = (label == null || label === "") && !children;

		if (shouldShowOnlyIcon || noLabelGiven) {
			if (!startIconElement) {
				logError("AavoButton must have either label or children or icon");
				return null;
			}

			return (
				<AavoIconButton
					ref={ref}
					color={color}
					disabled={isDisabled}
					iconEl={startIconElement}
					loading={loading}
					onClick={onClick}
					variant={variant === "outlined" ? "text" : variant}
					tooltip={tooltipTitle}
					disableTooltipEl={disableTooltipEl}
					sx={sx}
					{...other}
				/>
			);
		}

		const endIconElement = endIconEl ?? (endIcon && <FontAwesomeIcon icon={endIcon} />);

		return wrapToTooltip(
			<Button
				ref={ref}
				color={color}
				disabled={isDisabled}
				endIcon={endIconElement}
				startIcon={startIconElement}
				variant={variant}
				onClick={(e) => {
					try {
						onClick?.(e);
					} catch (error) {
						logErrorAndShowOnDialog(error);
					}
				}}
				sx={mergeSx(
					sx,
					{
						flex: 1,
						"& .MuiButton-startIcon": {
							fontSize: "inherit",
							"& svg": {
								fontSize: "inherit",
							}
						},
					},
				)}
				{...other}
			>
				{children ?? (
					<Typography
						variant={"button"}
						sx={{
							flex: 1,
							textAlign: "left",
							fontSize: "inherit",
						}}
						children={label}
					/>
				)}
			</Button>,
		);

		function wrapToTooltip(node: ReactElement) {
			return disableTooltipEl ? node : (
					<TooltipWithWrapper title={tooltipTitle} arrow sx={sx}>
						{node}
					</TooltipWithWrapper>
				);
		}

		function resolveTooltip() {
			const disabled = resolveValueOrProvider(disabledValueProvider);
			const givenTooltip = resolveValueOrProvider(tooltipProvider);
			return (
				givenTooltip ? givenTooltip
				: typeof disabled === "string" ? disabled
				: ""
			);
		}

		function resolveStartIconEl() {
			return (
				loading ? <FontAwesomeIcon icon={faSpinnerThird} spin={true} />
				: iconEl ? iconEl
				: icon ? <FontAwesomeIcon icon={icon} fixedWidth {...iconProps} />
				: reserveSpaceForLoadingSpinner ?
					<FontAwesomeIcon icon={faSpinnerThird} visibility={"hidden"} />
				:	undefined
			);
		}
	},
);
