// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { IsoDateString } from "src/types/dateTime";
import { IsoDateTimeString } from "src/types/dateTime";
import { CustomerOrderViewCurrency } from "src/api/generated/erp/db/types/enums/customerOrderViewCurrency";
import { CustomerOrderState } from "src/api/generated/erp/db/types/enums/customerOrderState";
import { CustomerOrderViewCustomerType } from "src/api/generated/erp/db/types/enums/customerOrderViewCustomerType";
import { Country } from "src/api/generated/erp/db/types/enums/country";
import { CustomerOrderViewInvoiceAddressCountry } from "src/api/generated/erp/db/types/enums/customerOrderViewInvoiceAddressCountry";
import { CustomerOrderViewSourceType } from "src/api/generated/erp/db/types/enums/customerOrderViewSourceType";
import { VatHandling } from "src/api/generated/erp/db/types/enums/vatHandling";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PickableCustomerOrdersDataGridApi {
	export async function getRows(args: {
		showOnlyDefaultSite: boolean;
		customerOrderTypeIdFilter: Array<number>;
		searchQuery: string;
		includeNonPickableOrders?: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<PickableCustomerOrdersDataGridApi_PickableCustomerOrder>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/pickableCustomerOrders/getRows",
			method: "POST",
			data: {
				showOnlyDefaultSite: args.showOnlyDefaultSite,
				customerOrderTypeIdFilter: args.customerOrderTypeIdFilter,
				searchQuery: args.searchQuery,
				includeNonPickableOrders: args.includeNonPickableOrders,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<PickableCustomerOrdersDataGridApi_PickableCustomerOrder>;
	}
}

export interface PickableCustomerOrdersDataGridApi_PickableCustomerOrder {
	allLinesReserved: boolean;
	earliestPickingDate: IsoDateString | null | undefined;
	additionalAddress: string;
	approvedAt: IsoDateTimeString | null | undefined;
	approvedByUserId: number | null | undefined;
	automaticReservation: boolean;
	billingPlanEnabled: boolean;
	businessId: string | null | undefined;
	cancelledAt: IsoDateTimeString | null | undefined;
	cancelledByUserId: number | null | undefined;
	capacityReservation: boolean;
	checkedAt: IsoDateTimeString | null | undefined;
	checkedByUserId: number | null | undefined;
	confirmedAt: IsoDateTimeString | null | undefined;
	confirmedByUserId: number | null | undefined;
	consignmentPrintoutText: string;
	contactEmail: string;
	contactName: string;
	contactPhone: string;
	contractDate: IsoDateString | null | undefined;
	contractualTermCode: string | null | undefined;
	contractualTermContent: string | null | undefined;
	contractualTermId: number | null | undefined;
	createdByUserEmail: string;
	createdByUserId: number;
	createdByUserName: string;
	createdDate: IsoDateTimeString;
	currency: CustomerOrderViewCurrency;
	customerGroupId: number | null | undefined;
	customerGroupName: string | null | undefined;
	customerId: number;
	customerName: string;
	customerOrderId: number;
	customerOrderLongName: string | null | undefined;
	customerOrderState: CustomerOrderState;
	customerOrderTypeId: number;
	customerOrderTypeName: string;
	customerPoNo: string;
	customerPriceGroupId: number | null | undefined;
	customerType: CustomerOrderViewCustomerType;
	deliveredAt: IsoDateTimeString | null | undefined;
	deliveryAddress_1: string;
	deliveryAddress_2: string;
	deliveryCity: string;
	deliveryContact: string | null | undefined;
	deliveryCountry: Country;
	deliveryMethodCode: string;
	deliveryMethodDescription: string;
	deliveryMethodId: number;
	deliveryName: string;
	deliveryPostalCode: string;
	deliveryPrintoutText: string;
	deliveryTermsCode: string;
	deliveryTermsDescription: string;
	deliveryTermsDestination: string;
	deliveryTermsId: number;
	externalOrderId: string | null | undefined;
	frozenAt: IsoDateTimeString | null | undefined;
	inputPricesWithVat: boolean;
	invoiceAddressAddress_1: string;
	invoiceAddressAddress_2: string;
	invoiceAddressCity: string;
	invoiceAddressContact: string | null | undefined;
	invoiceAddressCountry: CustomerOrderViewInvoiceAddressCountry;
	invoiceAddressEmail: string | null | undefined;
	invoiceAddressId: number;
	invoiceAddressName: string;
	invoiceAddressPhone: string | null | undefined;
	invoiceAddressPostalCode: string;
	invoicePrintoutText: string;
	isCancelled: boolean;
	lastUpdated: IsoDateTimeString;
	note: string | null | undefined;
	orderConfirmationPrintoutText: string;
	orderReference: string;
	paymentTerm: string;
	paymentTermDesc: string;
	paymentTermId: number;
	paymentTermPaymentDays: number;
	plannedDeliveryDate: IsoDateString;
	priceGroupName: string | null | undefined;
	projectId: number | null | undefined;
	relatedUrl: string | null | undefined;
	releasedAt: IsoDateTimeString | null | undefined;
	releasedByUserId: number | null | undefined;
	responsiblePersonCode: string;
	responsiblePersonId: number;
	responsiblePersonName: string;
	salesCommissionId: number | null | undefined;
	salespersonEmail: string | null | undefined;
	salespersonId: number | null | undefined;
	salespersonName: string | null | undefined;
	siteId: number;
	siteName: string;
	sourceId: number | null | undefined;
	sourceType: CustomerOrderViewSourceType;
	tenantState: string | null | undefined;
	tenantStateChangedAt: IsoDateTimeString | null | undefined;
	transportDuration: number | null | undefined;
	transportRouteId: number | null | undefined;
	transportRouteName: string | null | undefined;
	vatCodeId: number;
	vatHandling: VatHandling;
}
