import { AsyncFunction } from "src/types/functions";
import { AsyncRender, AsyncRenderProps } from "./AsyncRender";
import { useAsyncFetch } from "src/utils/async/asyncFetch.ts";
import { RefreshableElementProps, setRefreshRefValue } from "src/utils/useRefreshRef.ts";
import React from "react";
import { AsyncDataRefreshOptions } from "src/utils/async/asyncState";

export interface AsyncFetchRenderProps<T>
	extends RefreshableElementProps,
		Omit<AsyncRenderProps<T>, "asyncData" | "content"> {
	fetch: AsyncFunction<void, T>;
	defaultValue?: T;
	content: (data: T, reload: AsyncFetchRefreshFunc<T>) => React.ReactNode;
}

export type AsyncFetchRefreshFunc<T> = (options?: AsyncDataRefreshOptions) => Promise<T>;

export const AsyncFetchRender = <Data,>({
	fetch,
	content,
	refreshRef,
	defaultValue,
	...other
}: AsyncFetchRenderProps<Data>) => {
	const [asyncState, reload] = useAsyncFetch<Data, undefined>(fetch, {
		defaultValue: defaultValue,
		fetchOnMount: defaultValue === undefined,
	});

	setRefreshRefValue(refreshRef, reload);

	return (
		<AsyncRender
			asyncData={asyncState}
			reloadOnError={() => reload()}
			content={(data) => {
				return content(data, async (reloadOptions) => {
					return await reload(undefined, reloadOptions);
				});
			}}
			{...other}
		/>
	);
};
