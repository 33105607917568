// This file is automatically generated. Do not edit manually.

import { SalvosSalesCommissionLimit } from "src/api/generated/postgres/db/types/salvos/tables/salvosSalesCommissionLimit";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalvosSalesCommissionLimitManagementApi {
	export async function getCommissionLimits(): Promise<Array<SalvosSalesCommissionLimit>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/salvos/erp/sales/commissionLimits/getCommissionLimits",
			method: "POST",
			data: {},
		});
		return response as Array<SalvosSalesCommissionLimit>;
	}

	export async function insert(args: { limit: SalvosSalesCommissionLimit }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/salvos/erp/sales/commissionLimits/insert",
			method: "POST",
			data: {
				limit: args.limit,
			},
		});
		return response as number;
	}

	export async function update(args: { limit: SalvosSalesCommissionLimit }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/salvos/erp/sales/commissionLimits/update",
			method: "POST",
			data: {
				limit: args.limit,
			},
		});
		return response as void;
	}

	export async function delete_(args: { limitId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/salvos/erp/sales/commissionLimits/delete",
			method: "POST",
			data: {
				limitId: args.limitId,
			},
		});
		return response as void;
	}
}
