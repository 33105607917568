// This file is automatically generated. Do not edit manually.

import { ProductionLine } from "src/api/generated/erp/db/types/tables/productionLine";
import { ProductionLineStatus } from "src/api/generated/erp/production/productionLineStatus/model/productionLineStatus";
import { ShopOrderProductionLineWorkCenterStatus } from "src/api/generated/erp/production/productionLineStatus/model/shopOrderProductionLineWorkCenterStatus";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineStatusViewApi {
	export async function getProductionLinesForStatusViewGeneration(): Promise<Array<ProductionLine>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineStatus/getProductionLinesForStatusViewGeneration",
			method: "POST",
			data: {},
		});
		return response as Array<ProductionLine>;
	}

	export async function getProductionLineStatus(args: { productionLineId: number }): Promise<ProductionLineStatus> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineStatus/getProductionLineStatus",
			method: "POST",
			data: {
				productionLineId: args.productionLineId,
			},
		});
		return response as ProductionLineStatus;
	}

	export async function getShopOrderStatusOnWorkCenter(args: {
		shopOrderId: number;
		workCenterId: number;
		productionLineId: number;
	}): Promise<ShopOrderProductionLineWorkCenterStatus> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineStatus/getShopOrderStatusOnWorkCenter",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
				workCenterId: args.workCenterId,
				productionLineId: args.productionLineId,
			},
		});
		return response as ShopOrderProductionLineWorkCenterStatus;
	}

	export async function moveLineForward(args: { productionLineId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineStatus/moveLineForward",
			method: "POST",
			data: {
				productionLineId: args.productionLineId,
			},
		});
		return response as void;
	}

	export async function moveShopOrderToNextWorkCenter(args: {
		shopOrderId: number;
		productionLineId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineStatus/moveShopOrderToNextWorkCenter",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
				productionLineId: args.productionLineId,
			},
		});
		return response as void;
	}

	export async function moveShopOrderToPreviousWorkCenter(args: {
		shopOrderId: number;
		productionLineId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineStatus/moveShopOrderToPreviousWorkCenter",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
				productionLineId: args.productionLineId,
			},
		});
		return response as void;
	}
}
