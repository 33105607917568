import { AsyncFunction } from "../../types/functions.ts";
import { useEffect } from "react";
import { logError } from "../../errorHandling/errorLogging.ts";
import { AsyncDataRefreshOptions, AsyncState, useAsyncState } from "./asyncState.ts";

export type UseAsyncFetchReturn<TResult, TParam = void> = readonly [
	AsyncState<TResult>,
	(param?: TParam, options?: AsyncDataRefreshOptions) => Promise<TResult>,
];

export const useAsyncFetch = <TResult, TParam = void>(
	fetch: AsyncFunction<TParam | undefined, TResult>,
	{ fetchOnMount = true, defaultValue }: UseAsyncFetchOptions<TResult> = {},
): UseAsyncFetchReturn<TResult, TParam> => {
	const [response, update] = useAsyncState<TResult>({ defaultValue: defaultValue, isInitiallyLoading: fetchOnMount });
	const reload = async (param?: TParam, options?: AsyncDataRefreshOptions) => {
		return await update(() => fetch(param), options);
	};

	useEffect(() => {
		if (fetchOnMount) {
			reload().catch(logError);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [response, reload] as const;
};

export interface UseAsyncFetchOptions<Result> {
	defaultValue?: Result;
	fetchOnMount?: boolean;
}
