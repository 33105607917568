import { AsyncFunction } from "../../types/functions.ts";
import { useCallback, useRef, useState } from "react";
import { logError, LoggedError } from "src/errorHandling/errorLogging.ts";

export interface AsyncState<Result> {
	loading?: boolean;
	data?: Result;
	error?: LoggedError;
}

export type AsyncDataAsyncSetter<Result> = (
	fetchData: () => Promise<Result>,
	options?: AsyncDataRefreshOptions,
) => Promise<Result>;

export interface AsyncDataRefreshOptions {
	silent?: boolean;
}

export type AsyncDataImmediateSetter<Result> = (setter: (data: Result) => Result) => void;

export const useAsyncState = <Result>({
	defaultValue,
	isInitiallyLoading = false,
}: {
	defaultValue?: Result;
	isInitiallyLoading?: boolean;
}): readonly [AsyncState<Result>, AsyncDataAsyncSetter<Result>, AsyncDataImmediateSetter<Result>] => {
	const [asyncState, setAsyncState] = useState<AsyncState<Result>>({
		data: defaultValue,
		loading: isInitiallyLoading,
	});

	const callId = useRef<string>("initial");

	const asyncSetter = useCallback(
		async (fetch: AsyncFunction<void, Result>, options?: AsyncDataRefreshOptions): Promise<Result> => {
			const currentCallId = crypto.randomUUID();
			callId.current = currentCallId;

			const silent = options?.silent ?? false;

			try {
				if (currentCallId === callId.current) {
					setAsyncState((prev) => {
						return {
							data: prev.data,
							loading: !silent,
							error: undefined,
						};
					});
				}
				const fetchResult = await fetch();
				if (currentCallId === callId.current) {
					setAsyncState({
						loading: false,
						data: fetchResult,
						error: undefined,
					});
				}
				return fetchResult;
			} catch (error) {
				const loggedError = logError(error);
				if (currentCallId === callId.current) {
					setAsyncState({ error: loggedError });
				}
				throw error;
			}
		},
		[setAsyncState, callId],
	);

	const immediateSetter = useCallback(
		(setter: (data: Result) => Result) => {
			setAsyncState((prev) => mapAsyncState(prev, setter));
		},
		[setAsyncState],
	);

	return [asyncState, asyncSetter, immediateSetter] as const;
};

export const mapAsyncState = <T, U = T>(state: AsyncState<T>, mapper: (data: T) => U) => {
	if (state.data !== undefined)
		return {
			...state,
			data: mapper(state.data),
		};

	return {
		...state,
		data: undefined,
	};
};
