import { CustomerOrderLineView } from "src/api/generated/erp/db/types/tables/customerOrderLineView.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import i18n from "i18next";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faFileImport } from "@fortawesome/pro-regular-svg-icons";
import { CustomerOrderSummary } from "src/api/generated/erp/sales/customerOrder/service/customerOrderSummary.ts";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { CustomerOrderLinesFilterParams } from "src/components/views/erp/sales/customerOrderLine/CustomerOrderLinesDataGrid.tsx";
import { openLegacyCustomerOrderPreLineImportView } from "src/components/views/legacy/legacyViewAdapters.ts";
import { CustomerOrderOrOfferSummaryText } from "src/components/views/erp/sales/customerOrderLine/CustomerOrderOrOfferSummaryText.tsx";

export interface CustomerOrderLinesDataGridActionBarComponentsProps {
	customerOrderId: number;
	parentLineId: number | null;
	onlySelectedRow: CustomerOrderLineView | undefined;
	refreshData: (params: CustomerOrderLinesFilterParams) => Promise<unknown>;
	customerOrderSummary: CustomerOrderSummary;
}

export const CustomerOrderLinesDataGridActionBarComponents = ({
	customerOrderId,
	onlySelectedRow,
	refreshData,
	parentLineId,
	customerOrderSummary,
}: CustomerOrderLinesDataGridActionBarComponentsProps) => {
	const openLegacyView = useOpenLegacyView();

	return (
		<>
			<DocumentsOfObjectButton
				objectRef={nullableAavoObjectRef("CUSTOMER_ORDER_LINE", onlySelectedRow?.customerOrderLineId)}
				disabled={!onlySelectedRow}
			/>
			<AavoTextField label={i18n.t("search")} onSubmit={(input) => refreshData({ searchQuery: input })} />

			{parentLineId == null && (
				<CustomerOrderOrOfferSummaryText objectType={"CUSTOMER_ORDER"} summary={customerOrderSummary} />
			)}
			{parentLineId != null && (
				<AavoButton
					label={i18n.t("import_lines")}
					icon={faFileImport}
					variant={"outlined"}
					onClick={() => {
						openLegacyCustomerOrderPreLineImportView({
							openLegacyView: openLegacyView,
							targetType: "CUSTOMER_ORDER",
							targetId: customerOrderId,
							targetPackageLineId: parentLineId,
						});
					}}
				/>
			)}
		</>
	);
};
