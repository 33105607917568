import {
	CustomerOrderOrOfferSalesCommissionView,
	CustomerOrderOrOfferSalesCommissionViewProps,
} from "src/components/views/erp/sales/salesCommissions/orderAndOfferViews/CustomerOrderOfOfferSalesCommissionView.tsx";
import { useUserPermissions } from "src/components/views/erp/common/userPermissions.ts";

export interface CustomerOrderSalesCommissionViewProps
	extends Pick<CustomerOrderOrOfferSalesCommissionViewProps, "salesCommissionId" | "salesCommissionViewRefreshRef"> {
	customerOrderId: number;
}

export const CustomerOrderSalesCommissionView = ({
	customerOrderId,
	...other
}: CustomerOrderSalesCommissionViewProps) => {
	const userPermissions = useUserPermissions();
	return (
		<CustomerOrderOrOfferSalesCommissionView
			{...other}
			sourceType={"CUSTOMER_ORDER"}
			sourceId={customerOrderId}
			editingEnabled={true}
			hasManageCommissionPermission={userPermissions.sales.manageCustomerOrderSalesCommissions}
		/>
	);
};
