import {FormCommonProps} from "src/components/common/forms/types.ts";
import {AsyncForm, AsyncFormContentParams} from "src/components/common/forms/AsyncForm.tsx";
import {DeepPartial} from "react-hook-form";
import {
    WorkCenterEditApi,
    WorkCenterEditApi_FormInitData,
} from "src/api/generated/erp/production/basedata/workcenters/workCenterEditApi.ts";
import {Workcenter} from "src/api/generated/erp/db/types/tables/workcenter.ts";
import {FormSelectField} from "src/components/common/forms/fields/FormSelectField.tsx";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import {FormCheckbox} from "src/components/common/forms/fields/FormCheckbox.tsx";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField.tsx";
import {WorkcenterView} from "src/api/generated/erp/db/types/tables/workcenterView.ts";

export interface WorkCenterFormProps extends FormCommonProps<number> {
	workCenter: WorkcenterView | undefined;
}

export const WorkCenterForm = (props: WorkCenterFormProps) => {
	const { workCenter, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() => WorkCenterEditApi.getFormInitData({ workCenterId: workCenter?.workcenterId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues(): DeepPartial<Workcenter> {
		return { ...workCenter };
	}

	async function submit(values: Workcenter) {
		if (values.workcenterId == null) {
			return (await WorkCenterEditApi.insert({ workCenter: values })).workcenterId;
		} else {
			return (await WorkCenterEditApi.update({ workCenter: values })).workcenterId;
		}
	}
};

interface FormContentProps
	extends WorkCenterFormProps,
		AsyncFormContentParams<WorkCenterEditApi_FormInitData, Workcenter> {}

const FormContent = ({ control, data: { siteOptions } }: FormContentProps) => {
	return (
		<>
			<FormSelectField
				control={control}
				name={"siteId"}
				label={i18n.t("site")}
				getOptionKey={(o) => o.siteId}
				getOptionLabel={(o) => o.siteName}
				options={siteOptions}
			/>
			<FormTextField control={control} name={"workcenterNo"} label={i18n.t("work_center")} />
			<FormTextField control={control} name={"workcenterDescription"} label={i18n.t("description")} />
			<FormNumberField control={control} name={"hourlyCost"} label={i18n.t("hourly_cost")} />
			<FormCheckbox control={control} name={"completionEnabled"} label={i18n.t("reporting_enabled")} />
			<FormCheckbox
				control={control}
				name={"partialCompletionEnabled"}
				label={i18n.t("partial_completion_enabled")}
			/>
			<FormCheckbox control={control} name={"andonEnabled"} label={"andon_enabled"} />
		</>
	);
};
