import {
	CustomerOrderOrOfferSalesCommissionView,
	CustomerOrderOrOfferSalesCommissionViewProps,
} from "src/components/views/erp/sales/salesCommissions/orderAndOfferViews/CustomerOrderOfOfferSalesCommissionView.tsx";
import { CustomerOrderOfferView } from "src/api/generated/erp/db/types/tables/customerOrderOfferView.ts";
import { useUserPermissions } from "src/components/views/erp/common/userPermissions.ts";

export interface CustomerOfferSalesCommissionViewProps
	extends Pick<CustomerOrderOrOfferSalesCommissionViewProps, "salesCommissionId" | "salesCommissionViewRefreshRef"> {
	customerOffer: CustomerOrderOfferView;
}

export const CustomerOfferSalesCommissionView = ({
	customerOffer,
	...other
}: CustomerOfferSalesCommissionViewProps) => {
	const userPermissions = useUserPermissions();
	return (
		<CustomerOrderOrOfferSalesCommissionView
			{...other}
			sourceType={"CUSTOMER_OFFER"}
			sourceId={customerOffer.customerOrderOfferId}
			editingEnabled={!customerOffer.isClosed}
			hasManageCommissionPermission={userPermissions.sales.manageCustomerOfferSalesCommissions}
		/>
	);
};
