import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations.ts";

export const plgCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		spc: {
			enabled: true,
			processImprovementsEnabled: true,
		},
		tasks: {
			enabled: true,
		},
		documentsEnabled: true,
	},
} as const;
