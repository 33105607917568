// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalvosSalesCommissionCalculationApi {
	export async function calculateSalesCommission(args: {
		customerOfferId: number;
		salesCommissionId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/salvos/erp/sales/salesCommissions/calculateSalesCommission",
			method: "POST",
			data: {
				customerOfferId: args.customerOfferId,
				salesCommissionId: args.salesCommissionId,
			},
		});
		return response as void;
	}
}
