import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import {
    WorkCenterOperationsApi
} from "src/api/generated/erp/production/basedata/workcenters/workCenterOperationsApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {WorkCenterOperationView} from "src/api/generated/erp/db/types/tables/workCenterOperationView.ts";
import {textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faLink} from "@fortawesome/pro-regular-svg-icons";
import {useGlobalInitData} from "src/contexts/GlobalInitDataContext.ts";
import {useGenericDialog} from "src/components/common/dialogs/GenericDialogContext.ts";
import {AsyncSelectField} from "src/components/common/inputFields/AsyncSelectField.tsx";
import {concatWithPipe} from "src/utils/strings.tsx";
import {Box} from "@mui/system";

export interface WorkCenterOperationsDataGridProps {
	workCenterId: number;
}

interface WorkCenterOperationsFetchParams {
	workCenterId: number;
}

export const WorkCenterOperationsDataGrid = ({ workCenterId }: WorkCenterOperationsDataGridProps) => {
	const { defaultSiteId } = useGlobalInitData();
	const { openDialog } = useGenericDialog();

	return (
		<ClientSideDataGridModel<WorkCenterOperationView[], WorkCenterOperationView, WorkCenterOperationsFetchParams>
			gridId={"E8322340D2216CD3"}
			fetchData={WorkCenterOperationsApi.getWorkCenterOperations}
			initialParams={{
				workCenterId: workCenterId,
			}}
			getRows={(data) => data}
			getRowId={(row) => row.workcenterOperationId}
			render={({ dataGridProps, refreshData }) => {
				return (
					<CrudDataGrid<WorkCenterOperationView>
						columns={[
							textColumn({
								field: "operationNo",
								headerName: i18n.t("operation"),
							}),
							textColumn({
								field: "operationDescription",
								headerName: i18n.t("description"),
								width: 300,
							}),
						]}
						actionBarComponents={
							<>
								<AsyncButton
									icon={faLink}
									label={i18n.t("add_operation")}
									variant={"outlined"}
									onClick={async () => {
										openOperationConnectionDialog(refreshData);
									}}
								/>
							</>
						}
						remove={({ row }) =>
							WorkCenterOperationsApi.delete_({
								workCenterOperationId: row.workcenterOperationId,
							})
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);

	function openOperationConnectionDialog(refreshData: () => void) {
		openDialog(({ closeDialog }) => ({
			title: i18n.t("add_operation"),
			size: "sm",
			content: (
				<Box
					sx={{
						flex: 1,
						margin: "1rem",
					}}
				>
					<AsyncSelectField
						label={i18n.t("select_operation")}
						fetchOptions={(params) =>
							WorkCenterOperationsApi.getWorkCenterAvailableOperationOptions({
								siteId: defaultSiteId,
								workCenterId: workCenterId,
								...params,
							})
						}
						getOptionKey={(o) => o.operationId}
						getOptionLabel={(o) => concatWithPipe(o.operationNo, o.operationDescription)}
						onChange={async (value) => {
							const operationId = value?.operationId;
							if (operationId == null) return;
							await WorkCenterOperationsApi.addOperationToWorkCenter({
								workCenterId: workCenterId,
								operationId: operationId,
							});
							await closeDialog();
							refreshData();
						}}
					/>
				</Box>
			),
		}));
	}
};
