// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionJobQueueOperationActionApi {
	export async function startOperations(args: {
		shopOrderOperationIds: Array<number>;
		moveShopOrderOnProductionLine?: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/jobQueueOperationActions/startOperations",
			method: "POST",
			data: {
				shopOrderOperationIds: args.shopOrderOperationIds,
				moveShopOrderOnProductionLine: args.moveShopOrderOnProductionLine,
			},
		});
		return response as void;
	}

	export async function revertStartOperations(args: {
		shopOrderOperationIds: Array<number>;
		moveShopOrderOnProductionLine?: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/jobQueueOperationActions/revertStartOperations",
			method: "POST",
			data: {
				shopOrderOperationIds: args.shopOrderOperationIds,
				moveShopOrderOnProductionLine: args.moveShopOrderOnProductionLine,
			},
		});
		return response as void;
	}

	export async function hasWorkcenterIncompleteOperations(args: { shopOrderId: number }): Promise<boolean> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/jobQueueOperationActions/hasWorkcenterIncompleteOperations",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as boolean;
	}

	export async function completeOperations(args: {
		shopOrderOperationIds: Array<number>;
		moveShopOrderOnProductionLine?: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/jobQueueOperationActions/completeOperations",
			method: "POST",
			data: {
				shopOrderOperationIds: args.shopOrderOperationIds,
				moveShopOrderOnProductionLine: args.moveShopOrderOnProductionLine,
			},
		});
		return response as void;
	}

	export async function revertCompleteOperations(args: {
		shopOrderOperationIds: Array<number>;
		moveShopOrderOnProductionLine?: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/jobQueueOperationActions/revertCompleteOperations",
			method: "POST",
			data: {
				shopOrderOperationIds: args.shopOrderOperationIds,
				moveShopOrderOnProductionLine: args.moveShopOrderOnProductionLine,
			},
		});
		return response as void;
	}
}
