import { SalesCommissionLineView } from "src/api/generated/erp/db/types/tables/salesCommissionLineView.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import i18n from "i18next";
import { SalesCommissionLineForm } from "src/components/views/erp/sales/salesCommissions/orderAndOfferViews/SalesCommissionLineForm.tsx";
import { SalesCommissionLineEditApi } from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionLineEditApi.ts";
import { useStaticDataGridModel } from "src/components/common/dataGrid/gridModel/useStaticDataGridModel.tsx";
import { SalesCommissionLineActionApi } from "src/api/generated/erp/sales/salesCommissions/api/salesCommissionLineActionApi.ts";
import { faCheck, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { getSalesCommissionLinesDataGridColumns } from "src/components/views/erp/sales/salesCommissions/salesCommissionUtils.ts";
import { OpenObjectChangeLogButton } from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";

export interface CustomerOrderOrOfferSalesCommissionLinesDataGridProps {
	salesCommissionId: number;
	lines: SalesCommissionLineView[];
	refreshData: () => Promise<unknown>;
	sourceType: "CUSTOMER_ORDER" | "CUSTOMER_OFFER";
	editingEnabled: boolean;
	hasManageCommissionPermission: boolean;
}

export const CustomerOrderOrOfferSalesCommissionLinesDataGrid = ({
	salesCommissionId,
	lines,
	refreshData,
	sourceType,
	editingEnabled,
	hasManageCommissionPermission,
}: CustomerOrderOrOfferSalesCommissionLinesDataGridProps) => {
	const { dataGridProps } = useStaticDataGridModel({
		rows: lines,
		gridId: "E8D1DF89EE70F7FE_" + sourceType,
		getRowId: (row) => row.salesCommissionLineId,
	});

	return (
		<CrudDataGrid<SalesCommissionLineView>
			disableMultipleRowSelection
			refreshData={refreshData}
			columns={getSalesCommissionLinesDataGridColumns({
				showSalesperson: true,
				showState: sourceType === "CUSTOMER_ORDER",
				showEstimatedApprovalDate: sourceType === "CUSTOMER_ORDER",
				showApprovalColumns: sourceType === "CUSTOMER_ORDER",
				showPaymentColumns: sourceType === "CUSTOMER_ORDER",
				showCustomerOrderColumns: false,
			})}
			form={{
				addRowEnabled: editingEnabled && hasManageCommissionPermission,
				editEnabled: editingEnabled && hasManageCommissionPermission,
				dialogSize: "sm",
				dialogTitle: i18n.t("installment"),
				component: ({ row, onCompleted, onFormEdited }) => (
					<SalesCommissionLineForm
						salesCommissionId={salesCommissionId}
						salesCommissionLineId={row?.salesCommissionLineId}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
						sourceType={sourceType}
					/>
				),
			}}
			remove={
				editingEnabled &&
				hasManageCommissionPermission &&
				(({ items }) =>
					SalesCommissionLineEditApi.delete_({
						salesCommissionLineIds: items.map((item) => item.salesCommissionLineId),
					}))
			}
			rowContextMenuComponents={({ row }) => [
				sourceType === "CUSTOMER_ORDER" && row.salesCommissionLineState === "INITIAL" && (
					<AsyncMenuButton
						key={"approve"}
						icon={faCheck}
						label={i18n.t("approve")}
						onClick={async () => {
							await SalesCommissionLineActionApi.approveSalesCommissionLines({
								salesCommissionLineIds: [row.salesCommissionLineId],
							});
							await refreshData();
						}}
					/>
				),
				sourceType === "CUSTOMER_ORDER" && row.salesCommissionLineState === "APPROVED" && (
					<AsyncMenuButton
						key={"revertApprove"}
						icon={faUndo}
						label={i18n.t("revert_approve")}
						onClick={async () => {
							await SalesCommissionLineActionApi.revertApproveSalesCommissionLines({
								salesCommissionLineIds: [row.salesCommissionLineId],
							});
							await refreshData();
						}}
					/>
				),
				<OpenObjectChangeLogButton
					key={"changeLog"}
					objectRef={{
						objectType: "SALES_COMMISSION_LINE",
						objectId: row.salesCommissionLineId,
					}}
				/>,
			]}
			{...dataGridProps}
		/>
	);
};
