// This file is automatically generated. Do not edit manually.

import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues";
import { ResolvedPartConfigurationBomLine } from "src/api/generated/erp/configurator/configurationResolving/bom/resolvedPartConfigurationBomLine";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitePartTestConfigurationResultBomApi {
	export async function getTestConfigurationResultBom(args: {
		configurationSessionId: string;
		configuratorType: PartConfiguratorType;
		propertyValues: ConfigurationPropertyValues;
	}): Promise<Array<ResolvedPartConfigurationBomLine>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/testing/sitePartBom/getTestConfigurationResultBom",
			method: "POST",
			data: {
				configurationSessionId: args.configurationSessionId,
				configuratorType: args.configuratorType,
				propertyValues: args.propertyValues,
			},
		});
		return response as Array<ResolvedPartConfigurationBomLine>;
	}
}
