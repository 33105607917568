// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SpcTimeMeasurementApi {
	export async function getChartInfo(args: {
		controlChartId: number | null | undefined;
	}): Promise<SpcTimeMeasurementApi_ChartInfo> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/dataCollection/timeMeasurement/getChartInfo",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
			},
		});
		return response as SpcTimeMeasurementApi_ChartInfo;
	}
}

export interface SpcTimeMeasurementApi_ChartInfo {
	chartName: string;
	isRunning: boolean;
}
