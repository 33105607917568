import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import { SalvosSalesCommissionLimitForm } from "src/tenantCustomizations/tenants/salvos/sales/salesCommissions/SalvosSalesCommissionLimitForm.tsx";
import { SalvosSalesCommissionLimitManagementApi } from "src/api/generated/tenants/salvos/erp/sales/commission/api/salvosSalesCommissionLimitManagementApi.ts";
import { SalvosSalesCommissionLimit } from "src/api/generated/postgres/db/types/salvos/tables/salvosSalesCommissionLimit.ts";
import { floatColumn } from "src/components/common/dataGrid/columns.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid";
import i18n from "i18next";

export const SalvosSalesCommissionLimitManagementView = () => {
	return (
		<ClientSideDataGridModel
			fetchData={() => SalvosSalesCommissionLimitManagementApi.getCommissionLimits()}
			getRows={(data) => data}
			getRowId={(row) => row.salvosSalesCommissionLimitId}
			gridId={"33A60611DD7D303"}
			initialParams={{}}
			render={({ dataGridProps }) => (
				<CrudDataGrid<SalvosSalesCommissionLimit>
					columns={[
						floatColumn({
							headerName: i18n.t("sales_price_with_vat"),
							field: "minSalesPriceWithVat",
							width: 200
						}),
						floatColumn({
							headerName: i18n.t("main_commission"),
							field: "mainCommission",
							width: 200
						}),
						floatColumn({
							headerName: i18n.t("additional_commission"),
							field: "additionalCommission",
							width: 200
						}),
						floatColumn({
							headerName: i18n.t("additional_percent"),
							field: "additionalRelativeCommissionPercent",
							width: 200
						}),
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogSize: "sm",
						dialogTitle: i18n.t("commission_limit"),
						component: ({ row, onCompleted, onFormEdited }) => (
							<SalvosSalesCommissionLimitForm
								commissionLimit={row}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={({ row }) =>
						SalvosSalesCommissionLimitManagementApi.delete_({ limitId: row.salvosSalesCommissionLimitId })
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
