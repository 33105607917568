// This file is automatically generated. Do not edit manually.

import { Workcenter } from "src/api/generated/erp/db/types/tables/workcenter";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkCenterEditApi {
	export async function getFormInitData(args: {
		workCenterId: number | null | undefined;
	}): Promise<WorkCenterEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenters/getFormInitData",
			method: "POST",
			data: {
				workCenterId: args.workCenterId,
			},
		});
		return response as WorkCenterEditApi_FormInitData;
	}

	export async function insert(args: { workCenter: Workcenter }): Promise<Workcenter> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenters/insert",
			method: "POST",
			data: {
				workCenter: args.workCenter,
			},
		});
		return response as Workcenter;
	}

	export async function update(args: { workCenter: Workcenter }): Promise<Workcenter> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenters/update",
			method: "POST",
			data: {
				workCenter: args.workCenter,
			},
		});
		return response as Workcenter;
	}

	export async function delete_(args: { workCenterId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenters/delete",
			method: "POST",
			data: {
				workCenterId: args.workCenterId,
			},
		});
		return response as void;
	}
}

export interface WorkCenterEditApi_FormInitData {
	siteOptions: Array<Site>;
	workCenter: Workcenter | null | undefined;
}
