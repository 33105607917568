import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { ConfiguratorInput } from "src/api/generated/erp/configurator/model/configuratorInput.ts";
import React, { useRef, useState } from "react";
import { ConfiguratorComponent, ConfiguratorComponentApi } from "./components/ConfiguratorComponent";
import { ConfigurationPropertyValue } from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import { useAsyncState } from "src/utils/async/asyncState.ts";
import { AsyncRender, AsyncRenderErrorContentProps } from "src/components/common/async/AsyncRender";
import {
	constructConfiguratorInputFromComponents,
	setConfiguratorInputSingleComponentValue,
} from "./configuratorFormUtils";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues";
import { useAsyncEffectOnMount } from "src/utils/useAsyncEffectOnMount.ts";
import i18n from "i18next";
import { MaybeAsyncFunction } from "src/types/functions.ts";
import { FormResult } from "src/components/common/forms/types.ts";
import { TransformComponentsResult } from "src/api/generated/erp/configurator/api/form/transformComponentsResult.ts";
import {
	PartConfiguratorFormApi,
	PartConfiguratorFormApi_FormInitData,
} from "src/api/generated/erp/configurator/api/form/partConfiguratorFormApi.ts";
import { PartConfiguratorDocumentContextProvider } from "./componentDocument/PartConfiguratorDocumentContextProvider";
import { useMessageDialog } from "src/components/common/dialogs/messageDialog/MessageDialogContext.tsx";
import { getConfiguratorFormErrors } from "src/components/views/erp/configurator/configuratorForm/validation.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { ConfiguratorTabComponent } from "src/components/views/erp/configurator/configuratorForm/components/tab/ConfiguratorTabComponent.tsx";
import { groupConfiguratorComponentsToSections } from "src/components/views/erp/configurator/configuratorForm/components/section/sectionGrouping.ts";
import {
	ConfiguratorSectionComponent,
	ConfiguratorSectionComponentProps,
} from "src/components/views/erp/configurator/configuratorForm/components/section/ConfiguratorSectionComponent.tsx";
import { ConfiguratorSectionComponents } from "src/components/views/erp/configurator/configuratorForm/components/types/ConfiguratorSectionComponents.ts";
import { NonTabTransformedConfigurationComponent } from "src/components/views/erp/configurator/configuratorForm/components/types/NonTabTransformedConfigurationComponent.tsx";
import { useDebounce } from "src/utils/useDebounce.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import { ConfiguratorEvent } from "src/api/generated/erp/configurator/model/configuratorEvent.ts";
import { ConfiguratorFormFooter } from "src/components/views/erp/configurator/configuratorForm/components/ConfiguratorFormFooter.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { RefreshableElementRef, setRefreshRefValue } from "src/utils/useRefreshRef.ts";
import { ConfiguratorInitialInputSource } from "src/api/generated/erp/configurator/misc/configuratorInitialInputSource.ts";
import {
	TransformedConfigurationComponent,
	TransformedConfigurationComponent_Tab,
} from "src/api/generated/erp/configurator/componentTransformation/model/transformedConfigurationComponent.ts";
import {
	VersionedConfiguratorInput
} from "src/components/views/erp/configurator/configuratorForm/VersionedConfiguratorInput.ts";

export interface PartConfiguratorFormProps {
	configurationSessionId: string;
	initialConfiguratorInput?: ConfiguratorInput;
	configuratorType: PartConfiguratorType;
	disabled?: boolean;
	onCompleted?: MaybeAsyncFunction<FormResult<PartConfiguratorFormResult>, unknown>;
	onConfiguratorInputChanged?: (input: ConfiguratorInput, errors: string[]) => void;
	onPropertyValuesChanged?: (propertyValues: ConfigurationPropertyValues) => void;
	componentIdWithActiveDocument: number | null;
	setComponentIdWithActiveDocument: (componentId: number) => void;
	hideFooter?: boolean;
	footerExtraComponents?: React.ReactNode;
	transformComponentsRefreshRef?: RefreshableElementRef;
	transformComponentsErrorContent?: (props: AsyncRenderErrorContentProps) => React.ReactNode;
}

export interface PartConfiguratorFormResult {
	configurationSessionId: string;
	configuratorInput: ConfiguratorInput;
	propertyValues: ConfigurationPropertyValues;
}

export const PartConfiguratorForm = (props: PartConfiguratorFormProps) => {
	const {
		configuratorType,
		configurationSessionId,
		initialConfiguratorInput: initialConfiguratorInputProp,
		onCompleted = () => {},
	} = props;

	const showConfirmDialog = useConfirmDialog();

	const configurationAfterManualEditConfirmed = useRef(false);

	return (
		<AsyncFetchRender fetch={getInitData} content={(initData) => <FormContent {...props} initData={initData} />} />
	);

	async function getInitData(): Promise<PartConfiguratorFormApi_FormInitData> {
		if (initialConfiguratorInputProp) {
			return {
				initialConfiguratorInput: initialConfiguratorInputProp,
				initialValuesFromConfigurationId: null,
			};
		}

		if (!configurationAfterManualEditConfirmed.current) {
			const hasBeenEditedManually = await PartConfiguratorFormApi.getConfigurationHasBeenEditedManually({
				configuratorType: configuratorType,
			});

			if (hasBeenEditedManually) {
				const confirmed = await showConfirmDialog({
					message: i18n.t("confirm_configure_manually_edited_configuration"),
				});
				if (!confirmed) {
					await onCompleted({
						type: "cancel",
						isEdited: false,
					});
				}
			}
			configurationAfterManualEditConfirmed.current = true;
		}

		return await PartConfiguratorFormApi.getFormInitData({
			configurationSessionId: configurationSessionId,
			configuratorType: configuratorType,
		});
	}
};

interface FormContentProps extends PartConfiguratorFormProps {
	initData: PartConfiguratorFormApi_FormInitData;
}

const FormContent = (props: FormContentProps) => {
	const {
		configuratorType,
		configurationSessionId,
		initData,
		onCompleted = () => {},
		onPropertyValuesChanged,
		onConfiguratorInputChanged,
		componentIdWithActiveDocument,
		setComponentIdWithActiveDocument,
		hideFooter,
		footerExtraComponents,
		transformComponentsRefreshRef,
		transformComponentsErrorContent,
		disabled = false,
	} = props;

	const showMessage = useMessageDialog();
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const [configuratorInput, setConfiguratorInput] = useState<ConfiguratorInput>(initData.initialConfiguratorInput);
	const [configuratorInputErrors, setConfiguratorInputErrors] = useState<string[]>([]);
	const [configuratorInputVersion, setConfiguratorInputVersion] = useState<number>(-1);

	const [transformComponentsResultAsync, setTransformComponentsResultAsync] =
		useAsyncState<TransformComponentsResult>({});

	const componentApiRefByComponentIds = useRef<Record<number, ConfiguratorComponentApi>>({});

	const debounceSaveIncompleteConfiguration = useDebounce();

	useAsyncEffectOnMount(async () => {
		await transformComponents(configuratorInput);
	});

	setRefreshRefValue(transformComponentsRefreshRef, async () => {
		await transformComponents(configuratorInput);
	});

	return (
		<AsyncRender
			asyncData={transformComponentsResultAsync}
			reloadOnError={() => resetToDefaultValues()}
			errorContent={transformComponentsErrorContent}
			content={({ components, propertyValues, componentsWithDocument }) => (
				<PartConfiguratorDocumentContextProvider
					componentsWithDocument={componentsWithDocument}
					componentIdWithActiveDocument={componentIdWithActiveDocument}
					setComponentIdWithActiveDocument={setComponentIdWithActiveDocument}
				>
					<ConfiguratorTabsLayout
						configurationSessionId={configurationSessionId}
						configuratorType={configuratorType}
						configuratorInput={{
							...configuratorInput,
							version: configuratorInputVersion
						}}
						configuratorInputErrors={configuratorInputErrors}
						allComponents={components}
						hideFooter={hideFooter || disabled}
						footerExtraComponents={footerExtraComponents}
						onSubmitForm={() => onSubmitForm(components)}
						onCancelForm={onCancelForm}
						resetToDefaultValues={resetToDefaultValues}
						resetToExistingConfiguration={
							initData.initialValuesFromConfigurationId == null ? undefined : resetToExistingConfiguration
						}
						renderChildComponent={(component) => (
							<ConfiguratorComponent
								key={component.configurationComponentId}
								component={component}
								configuratorType={configuratorType}
								propertyValues={propertyValues}
								onSubmit={async (newValue) => {
									await onSubmitComponent(component, newValue, components);
								}}
								apiRef={(api) => {
									if (api != null) setComponentApiRef(component, api);
								}}
								disabled={disabled}
							/>
						)}
					/>
				</PartConfiguratorDocumentContextProvider>
			)}
		/>
	);

	async function onSubmitComponent(
		component: TransformedConfigurationComponent,
		value: ConfigurationPropertyValue,
		allComponents: TransformedConfigurationComponent[],
	) {
		try {
			const newConfiguratorInput = setConfiguratorInputSingleComponentValue({
				configuratorInput: configuratorInput,
				componentId: component.configurationComponentId,
				newComponentValue: value,
			});
			if (component.type === "field" && component.refreshOnChange) {
				await transformComponents(newConfiguratorInput, {
					type: "componentValueChanged",
					componentId: component.configurationComponentId,
				});
			} else {
				setConfiguratorInput(newConfiguratorInput);
				const errors = getConfiguratorFormErrors(allComponents, newConfiguratorInput);
				setConfiguratorInputErrors(errors);
				if (onConfiguratorInputChanged) {
					onConfiguratorInputChanged(newConfiguratorInput, errors);
				}
			}
			setConfiguratorInputVersion(current => current + 1)

			debounceSaveIncompleteConfiguration(500, async () => {
				try {
					await PartConfiguratorFormApi.saveIncompleteConfiguratorInput({
						configuratorType: configuratorType,
						configuratorInput: newConfiguratorInput,
					});
				} catch (e) {
					logErrorAndShowOnDialog(e);
				}
			});
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}

	async function transformComponents(
		configuratorInput: ConfiguratorInput,
		event: ConfiguratorEvent | undefined = undefined,
	): Promise<TransformComponentsResult> {
		const result = await setTransformComponentsResultAsync(() =>
			PartConfiguratorFormApi.transformComponents({
				configurationSessionId: configurationSessionId,
				configuratorType: configuratorType,
				configuratorInput: configuratorInput,
				event: event,
			}),
		);
		setConfiguratorInputVersion(current => current + 1)
		const newConfiguratorInput = constructConfiguratorInputFromComponents(result.components);
		const errors = getConfiguratorFormErrors(result.components, newConfiguratorInput);
		setConfiguratorInput(newConfiguratorInput);
		setConfiguratorInputErrors(errors);

		newConfiguratorInput.inputComponentValues.forEach(({ componentId, value }) => {
			const componentApi = componentApiRefByComponentIds.current[componentId];
			componentApi?.setValue(value);
		});

		if (onConfiguratorInputChanged) {
			onConfiguratorInputChanged(newConfiguratorInput, errors);
		}
		onPropertyValuesChanged?.(result.propertyValues);

		return result;
	}

	function setComponentApiRef(component: TransformedConfigurationComponent, api: ConfiguratorComponentApi) {
		componentApiRefByComponentIds.current[component.configurationComponentId] = api;
	}

	async function onSubmitForm(components: TransformedConfigurationComponent[]) {
		const errors = getConfiguratorFormErrors(components, configuratorInput);
		if (errors.length > 0) {
			showMessage({
				title: i18n.t("configurator_form_errors_title"),
				content: errors.join("\n"),
			});
			return;
		}

		const finalPropertyValues = await PartConfiguratorFormApi.resolvePropertyValues({
			configurationSessionId: configurationSessionId,
			configuratorType: configuratorType,
			configuratorInput: configuratorInput,
		});
		await onCompleted({
			type: "success",
			value: {
				configurationSessionId: configurationSessionId,
				configuratorInput: configuratorInput,
				propertyValues: finalPropertyValues,
			},
		});
	}

	async function onCancelForm() {
		await onCompleted({
			type: "cancel",
			isEdited: true,
		});
	}

	async function resetToDefaultValues() {
		await resetForm("DEFAULT_VALUES");
	}

	async function resetToExistingConfiguration() {
		await resetForm("EXISTING_CONFIGURATION");
	}

	async function resetForm(forceSource: ConfiguratorInitialInputSource) {
		const newInitData = await PartConfiguratorFormApi.getFormInitData({
			configurationSessionId: configurationSessionId,
			configuratorType: configuratorType,
			forceInitialInputSource: forceSource,
		});
		await transformComponents(newInitData.initialConfiguratorInput);
	}
};

interface ConfiguratorTabsLayoutProps extends Pick<ConfiguratorSectionComponentProps, "renderChildComponent"> {
	configurationSessionId: string;
	configuratorType: PartConfiguratorType;
	configuratorInput: VersionedConfiguratorInput;
	configuratorInputErrors: string[];
	allComponents: TransformedConfigurationComponent[];
	onSubmitForm?: () => Promise<unknown>;
	onCancelForm?: () => void;
	resetToExistingConfiguration?: () => Promise<unknown>;
	resetToDefaultValues?: () => Promise<unknown>;
	hideFooter?: boolean;
	footerExtraComponents?: React.ReactNode;
}

const ConfiguratorTabsLayout = ({
	configurationSessionId,
	configuratorType,
	configuratorInput,
	configuratorInputErrors,
	allComponents,
	renderChildComponent,
	onSubmitForm,
	onCancelForm,
	resetToExistingConfiguration,
	resetToDefaultValues,
	hideFooter,
	footerExtraComponents,
}: ConfiguratorTabsLayoutProps) => {
	interface TabWithChildren {
		tab: TransformedConfigurationComponent_Tab;
		sections: ConfiguratorSectionComponents[];
	}

	const tabsWithChildren = allComponents.reduce<TabWithChildren[]>((acc, component) => {
		if (component.type !== "tab") return acc;

		const childComponents = allComponents.reduce((acc, c) => {
			if (c.type === "tab") return acc;
			if (c.layout.parentTabComponentId !== component.configurationComponentId) return acc;
			return [...acc, c];
		}, Array<NonTabTransformedConfigurationComponent>());

		const sections = groupConfiguratorComponentsToSections(childComponents);
		return [
			...acc,
			{
				tab: component,
				sections: sections,
			},
		];
	}, []);

	return (
		<VerticalBox flex={1}>
			<AavoMosaic
				layout={{
					type: "tabs",
					items: tabsWithChildren.map(({ tab, sections }) => ({
						type: "panel",
						title: tab.label,
						content: (
							<ConfiguratorTabComponent tabComponent={tab}>
								{sections.map((section) => (
									<ConfiguratorSectionComponent
										key={section.sectionBreakComponent?.configurationComponentId ?? -1}
										components={section}
										renderChildComponent={renderChildComponent}
									/>
								))}
							</ConfiguratorTabComponent>
						),
					})),
				}}
			/>
			{!hideFooter && (
				<ConfiguratorFormFooter
					configurationSessionId={configurationSessionId}
					configuratorType={configuratorType}
					configuratorInput={configuratorInput}
					configuratorInputErrors={configuratorInputErrors}
					onCancel={onCancelForm}
					submitForm={onSubmitForm}
					resetToExistingConfiguration={resetToExistingConfiguration}
					resetToDefaultValues={resetToDefaultValues}
					extraComponents={footerExtraComponents}
				/>
			)}
		</VerticalBox>
	);
};
