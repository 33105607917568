import {StackedIcon, StackedIconProps} from "src/components/common/icons/StackedIcon.tsx";
import {faClockTwelve} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTheme} from "@mui/material";

export interface RunningClockIconProps extends StackedIconProps {}

export const RunningClockIcon = ({ ...other }: RunningClockIconProps) => {
	const { palette } = useTheme();
	return (
		<StackedIcon {...other}>
			<FontAwesomeIcon
				icon={faClockTwelve}
				transform={{
					size: 14,
					x: 0,
					y: 0,
				}}
				color={palette.primary.main}

			/>
			<FontAwesomeIcon
				icon={faClockTwelve}
				transform={{
					size: 14,
					x: 0,
					y: 0,
				}}
				color={palette.primary.main}
				spin
			/>
		</StackedIcon>
	);
};
