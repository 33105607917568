import { CustomerOrderContextMenuComponentsProps } from "src/tenantCustomizations/TenantCustomizations.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faBusinessTime, faCheck, faHandHoldingDollar, faHistory } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import dayjs from "dayjs";
import { SalvosCustomerOrderApi } from "src/api/generated/tenants/salvos/erp/sales/customerOrder/salvosCustomerOrderApi.ts";
import { dayJsToDateIsoStringNullable } from "src/utils/dayjsUtils.ts";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { useContext } from "react";
import { CustomerOrdersPageContext } from "src/components/views/erp/sales/customerOrder/CustomerOrdersPageContext.tsx";

export const SalvosCustomerOrderContextMenuComponents = ({
	refreshData,
	onlySingleRowSelected,
	row,
}: CustomerOrderContextMenuComponentsProps) => {
	const openLegacyView = useOpenLegacyView();
	const showInputDialog = useInputDialog();
	const customerOrderId = row.customerOrderId;

	const customerOrdersPageContext = useContext(CustomerOrdersPageContext);
	const attributeViewRefreshRef = customerOrdersPageContext?.attributeViewRefreshRef;

	return [
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"setBuildingLicenceDate"}
				icon={faHistory}
				label={i18n.t("set_building_licence_date")}
				onClick={async () => {
					const buildingLicenceDate = await showInputDialog({
						title: i18n.t("building_licence_got_date"),
						defaultValue: dayjs().local(),
						type: "date",
						fieldLabel: i18n.t("select_date"),
						required: false,
					});
					if (buildingLicenceDate === undefined) return;
					await SalvosCustomerOrderApi.setCustomerOrderBuildingLicenceDate({
						customerOrderId: customerOrderId,
						buildingLicenceDate: dayJsToDateIsoStringNullable(buildingLicenceDate),
					});
					await refreshData();
					attributeViewRefreshRef?.refresh();
				}}
			/>
		),
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"setDeliveryDateAgreedDateButton"}
				label={i18n.t("set_delivery_date_agreed_date")}
				icon={faBusinessTime}
				onClick={async () => {
					const deliveryDateAgreedDate = await showInputDialog({
						title: i18n.t("delivery_date_agreed_date"),
						defaultValue: dayjs().local(),
						type: "date",
						fieldLabel: i18n.t("select_date"),
						required: false,
					});
					if (deliveryDateAgreedDate === undefined) return;
					await SalvosCustomerOrderApi.setDeliveryDateAgreedDate({
						customerOrderId: customerOrderId,
						deliveryDateAgreedDate: dayJsToDateIsoStringNullable(deliveryDateAgreedDate),
					});
					await refreshData();
					attributeViewRefreshRef?.refresh();
				}}
			/>
		),
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"setRevenueRecognitionDate"}
				label={i18n.t("set_revenue_recognition_date")}
				icon={faHandHoldingDollar}
				onClick={async () => {
					const revenueRecognitionDate = await showInputDialog({
						title: i18n.t("revenue_recognition_date"),
						defaultValue: dayjs().local(),
						type: "date",
						fieldLabel: i18n.t("select_date"),
						required: false,
					});
					if (revenueRecognitionDate === undefined) return;
					await SalvosCustomerOrderApi.setRevenueRecognitionDate({
						customerOrderId: customerOrderId,
						revenueRecognitionDate: dayJsToDateIsoStringNullable(revenueRecognitionDate),
					});
					await refreshData();
					attributeViewRefreshRef?.refresh();
				}}
			/>
		),
		onlySingleRowSelected && row.tenantState === "delivered" && (
			<AsyncMenuButton
				key={"markCustomerOrderToCompletedButton"}
				label={i18n.t("mark_completed")}
				icon={faCheck}
				onClick={async () => {
					const sendSatisfactionSurvey = await showInputDialog({
						title: i18n.t("mark_completed"),
						defaultValue: true,
						type: "boolean",
						fieldLabel: i18n.t("send_satisfaction_survey"),
						required: false,
					});
					if (sendSatisfactionSurvey === undefined) return;
					await SalvosCustomerOrderApi.markCustomerOrderToCompleted({
						customerOrderId: customerOrderId,
						sendCustomerSatisfactionSurvey: sendSatisfactionSurvey,
					});
					await refreshData();
					attributeViewRefreshRef?.refresh();
				}}
			/>
		),
		onlySingleRowSelected && (
			<AsyncMenuButton
				icon={faHistory}
				label={i18n.t("state_history")}
				onClick={async () => {
					openLegacyView("76F390596DB5A117", { customerOrderId: row.customerOrderId });
				}}
			/>
		),
	];
};
