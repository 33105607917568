// This file is automatically generated. Do not edit manually.

import { WorkcenterView } from "src/api/generated/erp/db/types/tables/workcenterView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkCenterApi {
	export async function getWorkCenters(args: {
		showOnlyDefaultSiteWorkCenters: boolean;
	}): Promise<Array<WorkcenterView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenters/getWorkCenters",
			method: "POST",
			data: {
				showOnlyDefaultSiteWorkCenters: args.showOnlyDefaultSiteWorkCenters,
			},
		});
		return response as Array<WorkcenterView>;
	}
}
