// This file is automatically generated. Do not edit manually.

import { SalespersonView } from "src/api/generated/erp/db/types/tables/salespersonView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalespersonApi {
	export async function getSalespersons(args: {
		includeInactive: boolean;
		searchQuery?: string;
	}): Promise<Array<SalespersonView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/salespersons/getSalespersons",
			method: "POST",
			data: {
				includeInactive: args.includeInactive,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<SalespersonView>;
	}
}
