import {
	dateTimeColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faBoxOpen, faCheck, faCheckDouble, faStopwatch20, faTable, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { ProductionJobQueueOperationActionApi } from "src/api/generated/erp/production/jobQueues/api/productionJobQueueOperationActionApi.ts";
import {
	ServerSideDataGridModel,
	ServerSideDataGridModelRenderProps,
} from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel";
import { getShopOrderStateLabels } from "src/api/generated/erp/db/types/enums/shopOrderState.ts";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { CustomerOrderQueryApi } from "src/api/generated/erp/sales/customerOrder/api/customerOrderQueryApi.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { closeShopOrder } from "src/components/views/erp/production/shopOrder/utils/closeShopOrder.ts";
import { ShopOrderActionApi } from "src/api/generated/erp/production/api/shopOrder/shopOrderActionApi.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { PartConfigurationPropertiesDataGrid } from "src/components/views/erp/configurator/inspecting/PartConfigurationPropertiesDataGrid.tsx";
import { JobQueueShopOrderOperation } from "src/components/views/erp/production/jobQueue/base/JobQueueShopOrderOperation.ts";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult.ts";
import { Workcenter } from "src/api/generated/erp/db/types/tables/workcenter.ts";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest.ts";
import { useJobQueueViewContext } from "src/components/views/erp/production/jobQueue/base/JobQueueViewContext.ts";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { IncreaseShopOrderCompletedQuantityForm } from "src/components/views/erp/production/jobQueue/base/IncreaseShopOrderCompletedQuantityForm.tsx";
import { ShopOrderCompletionForm } from "src/components/views/erp/production/jobQueue/common/ShopOrderCompletionForm.tsx";
import { CustomerOrderPickingView } from "src/components/views/erp/sales/delivery/picking/CustomerOrderPickingView.tsx";

export interface JobQueueCompletedViewProps {
	gridId: string;
	fetchData: (params: JobQueueCompletedDataParams) => Promise<JobQueueCompletedData>;
	moveShopOrdersOnProductionLine: boolean;
}

export interface JobQueueCompletedDataParams {
	includeCompletedShopOrders: boolean;
	excludeOtherOperatorJobs: boolean;
	dataModelRequest: ServerSideDataModelRequest;
}

export interface JobQueueCompletedData {
	dataModelResult: ServerSideDataModelResult<JobQueueShopOrderOperation>;
	workCenter: Workcenter;
}

export const JobQueueCompletedView = (props: JobQueueCompletedViewProps) => {
	const { fetchData, gridId } = props
	const { completedViewRefreshRef } = useJobQueueViewContext();

	return (
		<ServerSideDataGridModel
			fetchData={fetchData}
			initialParams={{
				includeCompletedShopOrders: false,
				excludeOtherOperatorJobs: true,
			}}
			getDataModelResult={(data) => data.dataModelResult}
			getRowId={(row) => row.shopOrderOperationId}
			gridId={gridId + "_v2"}
			refreshRef={completedViewRefreshRef}
			render={(params) => <Content {...props} {...params} />}
		/>
	);
};

interface SearchParams {
	includeCompletedShopOrders: boolean;
	excludeOtherOperatorJobs: boolean;
}

interface ContentProps
	extends JobQueueCompletedViewProps,
		ServerSideDataGridModelRenderProps<JobQueueCompletedData, JobQueueShopOrderOperation, SearchParams> {}

const Content = ({
	dataGridProps,
	onlySelectedRow,
	selectedRows,
	currentParams,
	refreshData,
	moveShopOrdersOnProductionLine,
	data: { workCenter },
}: ContentProps) => {
	const { inProgressViewRefreshRef } = useJobQueueViewContext();
	const showConfirmDialog = useConfirmDialog();
	const { openDialog } = useGenericDialog();

	return (
		<AavoDataGrid<JobQueueShopOrderOperation>
			columns={[
				integerColumn({
					field: "shopOrderId",
					headerName: i18n.t("number_shortened"),
				}),
				dateTimeColumn({
					field: "shopOrderPlannedBeginDate",
					headerName: i18n.t("start_date"),
					width: 150,
				}),
				textColumn({
					field: "operationDescription",
					headerName: i18n.t("operation"),
					width: 150,
				}),
				textColumn({
					field: "sourceRefData",
					headerName: i18n.t("reference"),
					width: 200,
				}),
				textColumn({
					field: "configurationOrPartDescription",
					headerName: i18n.t("item"),
					width: 200,
				}),
				integerColumn({
					field: "partRevision",
					headerName: i18n.t("revision"),
					width: 80,
				}),
				textColumn({
					field: "quantity",
					headerName: i18n.t("amount"),
				}),
				textColumn({
					field: "partUnit",
					headerName: i18n.t("unit"),
				}),
				floatColumn({
					field: "completedQuantity",
					headerName: i18n.t("completed_quantity"),
				}),
				enumColumn({
					field: "shopOrderState",
					headerName: i18n.t("state"),
					enumLabels: getShopOrderStateLabels(),
				}),
				textColumn({
					field: "operator",
					headerName: i18n.t("operator"),
				}),
				textColumn({
					field: "shopOrderBatchName",
					headerName: i18n.t("production_batch"),
				}),
				textColumn({
					field: "packageInfo",
					headerName: i18n.t("packaging_info"),
					width: 120,
				}),
				textColumn({
					field: "capacityQuantity",
					headerName: i18n.t("capacity"),
				}),
				textColumn({
					field: "operationNo",
					headerName: i18n.t("operation_no"),
				}),
				textColumn({
					field: "shopOrderProductionLineName",
					headerName: i18n.t("production_line"),
				}),
				textColumn({
					field: "shopOrderNote",
					headerName: i18n.t("note"),
					width: 150,
				}),
				integerColumn({
					field: "partConfigurationId",
					headerName: i18n.t("configuration_id"),
				}),
			]}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"showClosed"}
					label={i18n.t("show_closed")}
					checked={currentParams.includeCompletedShopOrders}
					onChange={(checked) => refreshData({ includeCompletedShopOrders: checked })}
				/>,
				<MenuCheckbox
					key={"onlyOwnOperations"}
					label={i18n.t("only_own")}
					checked={currentParams.excludeOtherOperatorJobs}
					onChange={(checked) => refreshData({ excludeOtherOperatorJobs: checked })}
				/>,
			]}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef("SHOP_ORDER_OPERATION", onlySelectedRow?.shopOrderOperationId)}
					/>
					<AsyncButton icon={faUndo} label={i18n.t("cancel")} variant={"outlined"} onClick={onCancelClick} />
					{workCenter.completionEnabled && (
						<>
							<AsyncButton
								label={i18n.t("report_quantity")}
								icon={faStopwatch20}
								variant={"outlined"}
								disabled={!onlySelectedRow || onlySelectedRow.shopOrderState === "READY"}
								onClick={async () => {
									openFormOnDialog({
										openDialog,
										component: IncreaseShopOrderCompletedQuantityForm,
										title: i18n.t("increase_completed_quantity"),
										size: "sm",
										props: {
											shopOrderId: onlySelectedRow!.shopOrderId,
										},
										confirmCloseIfEdited: false,
										onSubmit: async () => {
											await refreshData();
										},
									});
								}}
							/>
							{onlySelectedRow?.customerOrderId != null && (
								<AsyncButton
									label={i18n.t("picking")}
									icon={faBoxOpen}
									variant={"outlined"}
									disabled={!onlySelectedRow}
									onClick={async () => {
										const customerOrder = await CustomerOrderQueryApi.getCustomerOrder({
											customerOrderId: onlySelectedRow!.customerOrderId!,
										});
										openDialog({
											title: i18n.t("picking"),
											content: <CustomerOrderPickingView customerOrder={customerOrder} />,
										});
									}}
								/>
							)}
							<AsyncButton
								label={i18n.t("close_shop_order")}
								icon={faCheck}
								variant={"outlined"}
								disabled={
									!onlySelectedRow ||
									onlySelectedRow.shopOrderState === "READY" ||
									onlySelectedRow.completedQuantity === 0
								}
								onClick={async () => {
									await closeShopOrder({
										shopOrderId: onlySelectedRow!.shopOrderId,
										showConfirmDialog: showConfirmDialog,
									});
									await refreshData();
								}}
							/>
							<AsyncButton
								label={i18n.t("set_as_completed_and_close")}
								icon={faCheckDouble}
								variant={"outlined"}
								disabled={!onlySelectedRow || onlySelectedRow.shopOrderState === "READY"}
								onClick={async () => {
									openFormOnDialog({
										openDialog,
										component: ShopOrderCompletionForm,
										title:
											onlySelectedRow!.customerOrderLineId == null ?
												i18n.t("complete")
											:	i18n.t("complete_and_package"),
										size: "md",
										confirmCloseIfEdited: false,
										props: {
											shopOrderId: onlySelectedRow!.shopOrderId,
										},
										onSubmit: refreshData,
									});
								}}
							/>
						</>
					)}
					{onlySelectedRow?.partConfigurationId != null && (
						<AsyncButton
							label={i18n.t("configuration")}
							icon={faTable}
							variant={"outlined"}
							onClick={() => {
								openDialog(() => ({
									title: i18n.t("configuration"),
									content: (
										<PartConfigurationPropertiesDataGrid
											partConfigurationId={onlySelectedRow.partConfigurationId!}
										/>
									),
								}));
							}}
						/>
					)}
				</>
			}
			rowContextMenuComponents={({ row, onlySingleRowSelected }) => [
				onlySingleRowSelected && row.shopOrderState === "READY" && (
					<AsyncMenuButton
						key={"revertClose"}
						icon={faUndo}
						label={i18n.t("revert_closing")}
						onClick={async () => {
							await ShopOrderActionApi.revertCloseShopOrder({
								shopOrderId: row.shopOrderId,
							});
							await refreshData();
						}}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);

	async function onCancelClick() {
		await ProductionJobQueueOperationActionApi.revertCompleteOperations({
			shopOrderOperationIds: selectedRows.map((row) => row.shopOrderOperationId),
			moveShopOrderOnProductionLine: moveShopOrdersOnProductionLine
		});
		await Promise.all([refreshData(), inProgressViewRefreshRef.refresh()]);
	}
};
