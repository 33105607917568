import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faLink} from "@fortawesome/pro-regular-svg-icons";
import {useGlobalInitData} from "src/contexts/GlobalInitDataContext.ts";
import {useGenericDialog} from "src/components/common/dialogs/GenericDialogContext.ts";
import {AsyncSelectField} from "src/components/common/inputFields/AsyncSelectField.tsx";
import {Box} from "@mui/system";
import {WorkcenterProductionLineView} from "src/api/generated/erp/db/types/tables/workcenterProductionLineView.ts";
import {
    WorkCenterProductionLinesApi
} from "src/api/generated/erp/production/basedata/workcenters/workCenterProductionLinesApi.ts";

export interface WorkCenterProductionLinesDataGridProps {
	workCenterId: number;
}

interface WorkCenterProductionLinesFetchParams {
	workCenterId: number;
}

export const WorkCenterProductionLinesDataGrid = ({ workCenterId }: WorkCenterProductionLinesDataGridProps) => {
	const { defaultSiteId } = useGlobalInitData();
	const { openDialog } = useGenericDialog();

	return (
		<ClientSideDataGridModel<
			WorkcenterProductionLineView[],
			WorkcenterProductionLineView,
			WorkCenterProductionLinesFetchParams
		>
			gridId={"E8322340D2216CD3"}
			fetchData={WorkCenterProductionLinesApi.getWorkCenterProductionLines}
			initialParams={{
				workCenterId: workCenterId,
			}}
			getRows={(data) => data}
			getRowId={(row) => row.workcenterProductionLineId}
			render={({ dataGridProps, refreshData }) => {
				return (
					<CrudDataGrid<WorkcenterProductionLineView>
						columns={[
							textColumn({
								field: "productionLineName",
								headerName: i18n.t("production_line"),
								width: 300,
							}),
						]}
						actionBarComponents={
							<>
								<AsyncButton
									icon={faLink}
									label={i18n.t("add_production_line")}
									variant={"outlined"}
									onClick={async () => {
										openProductionLineConnectionDialog(refreshData);
									}}
								/>
							</>
						}
						remove={({ row }) =>
							WorkCenterProductionLinesApi.delete_({
								workCenterProductionLineId: row.workcenterProductionLineId,
							})
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);

	function openProductionLineConnectionDialog(refreshData: () => void) {
		openDialog(({ closeDialog }) => ({
			title: i18n.t("add_production_line"),
			size: "sm",
			content: (
				<Box
					sx={{
						flex: 1,
						margin: "1rem",
					}}
				>
					<AsyncSelectField
						label={i18n.t("select_production_line")}
						fetchOptions={(params) =>
							WorkCenterProductionLinesApi.getAvailableProductionLineOptions({
								siteId: defaultSiteId,
								workCenterId: workCenterId,
								...params,
							})
						}
						getOptionKey={(o) => o.productionLineId}
						getOptionLabel={(o) => o.productionLineName}
						onChange={async (value) => {
							const productionLineId = value?.productionLineId;
							if (productionLineId == null) return;
							await WorkCenterProductionLinesApi.addProductionLineToWorkCenter({
								workCenterId: workCenterId,
								productionLineId: productionLineId,
							});
							await closeDialog();
							refreshData();
						}}
					/>
				</Box>
			),
		}));
	}
};
