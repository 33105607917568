import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations";
import {
	getInventoryLevelEventQuantitySpcSamplingMethod,
	getInventoryLevelEventTotalWeightSpcSamplingMethod
} from "src/components/views/erp/spcSamplingMethods/inventoryLevelEventSpcSamplingMethods.tsx";

export const hawoCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
			configuratorEnabled: true,
			surveysEnabled: true,
		},
		tasks: {
			enabled: true,
		},
		documentsEnabled: true,
	},
	spcSamplingMethods: () => [
		getInventoryLevelEventQuantitySpcSamplingMethod(),
		getInventoryLevelEventTotalWeightSpcSamplingMethod(),
	],
} as const;
