import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import i18n from "i18next";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faLink} from "@fortawesome/pro-regular-svg-icons";
import {useGlobalInitData} from "src/contexts/GlobalInitDataContext.ts";
import {useGenericDialog} from "src/components/common/dialogs/GenericDialogContext.ts";
import {
    WorkcenterPartWarehouseLocationView
} from "src/api/generated/erp/db/types/tables/workcenterPartWarehouseLocationView.ts";
import {
    WorkCenterPartWarehouseLocationsApi
} from "src/api/generated/erp/production/basedata/workcenters/workCenterPartWarehouseLocationsApi.ts";
import {AavoForm} from "src/components/common/forms/AavoForm.tsx";
import {SitePartQueryApi} from "src/api/generated/erp/parts/sitePart/api/sitePartQueryApi.ts";
import {FormAsyncSelectField} from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import {
    PartWarehouseLocationQueryApi
} from "src/api/generated/erp/parts/partWarehouseLocations/api/partWarehouseLocationQueryApi.ts";
import {FormLazySelectField} from "src/components/common/forms/fields/FormLazySelectField.tsx";
import {requireRule} from "src/components/common/forms/validation.ts";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {FetchAsyncOptionParams} from "src/components/common/inputFields/AsyncSelectField.tsx";
import {coloredBooleanColumn, enumColumn, integerColumn, textColumn,} from "src/components/common/dataGrid/columns.tsx";
import {
    getWarehouseReplenishmentMethodLabels
} from "src/api/generated/erp/db/types/enums/warehouseReplenishmentMethod.ts";

export interface WorkCenterPartWarehouseLocationsDataGridProps {
	workCenterId: number;
}

interface WorkCenterPartWarehouseLocationsFetchParams {
	workCenterId: number;
}

export const WorkCenterPartWarehouseLocationsDataGrid = ({
	workCenterId,
}: WorkCenterPartWarehouseLocationsDataGridProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<ClientSideDataGridModel<
			WorkcenterPartWarehouseLocationView[],
			WorkcenterPartWarehouseLocationView,
			WorkCenterPartWarehouseLocationsFetchParams
		>
			gridId={"E8322340D2216CD3"}
			fetchData={WorkCenterPartWarehouseLocationsApi.getWorkCenterPartWarehouseLocations}
			initialParams={{
				workCenterId: workCenterId,
			}}
			getRows={(data) => data}
			getRowId={(row) => row.workcenterPartWarehouseLocationId}
			render={({ dataGridProps, refreshData }) => {
				return (
					<CrudDataGrid<WorkcenterPartWarehouseLocationView>
						columns={[
							textColumn({
								field: "locationCode",
								headerName: i18n.t("warehouse_location"),
							}),
							textColumn({
								field: "partLongName",
								headerName: i18n.t("part"),
								width: 200,
							}),
							integerColumn({
								field: "locationQuantity",
								headerName: i18n.t("quantity"),
							}),
							enumColumn({
								field: "replenishmentMethod",
								headerName: i18n.t("control_code"),
								enumLabels: getWarehouseReplenishmentMethodLabels(),
							}),
							integerColumn({
								field: "givingSiteId",
								headerName: i18n.t("giving_site"),
							}),
							integerColumn({
								field: "givingPartWarehouseLocationId",
								headerName: i18n.t("giving_warehouse_location"),
							}),
							integerColumn({
								field: "defaultReplenishmentQuantity",
								headerName: i18n.t("default_replenishment_quantity"),
							}),
							coloredBooleanColumn({
								field: "isDefault",
								headerName: i18n.t("default"),
								falseColor: "grey",
							}),
							coloredBooleanColumn({
								field: "askTransferInfo",
								headerName: i18n.t("ask_transfer_info"),
								falseColor: "grey",
							}),
							coloredBooleanColumn({
								field: "isActive",
								headerName: i18n.t("active"),
								falseColor: "red",
							}),
						]}
						actionBarComponents={
							<>
								<AsyncButton
									icon={faLink}
									label={i18n.t("add_part")}
									variant={"outlined"}
									onClick={async () => {
										openDialog(({ closeDialog }) => ({
											title: i18n.t("add_part_warehouse_location"),
											size: "sm",
											content: (
												<PartWarehouseLocationConnectionForm
													onCompleted={() => closeDialog()}
													refreshData={refreshData}
													workCenterId={workCenterId}
												/>
											),
										}));
									}}
								/>
							</>
						}
						remove={({ row }) =>
							WorkCenterPartWarehouseLocationsApi.delete_({
								workCenterPartWarehouseLocationId: row.workcenterPartWarehouseLocationId,
							})
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};

interface PartWarehouseLocationConnectionFormProps extends FormCommonProps<void> {
	refreshData: () => Promise<any>;
	workCenterId: number;
}

interface FormValues {
	partId: number;
	partWarehouseLocationId: number;
}

const PartWarehouseLocationConnectionForm = ({
	onCompleted,
	refreshData,
	workCenterId,
}: PartWarehouseLocationConnectionFormProps) => {
	const { defaultSiteId } = useGlobalInitData();
	return (
		<AavoForm<FormValues>
			submit={async (values) => {
				if (values.partWarehouseLocationId == null) return;
				await WorkCenterPartWarehouseLocationsApi.addNewWorkCenterPartWarehouseLocation({
					partWarehouseLocationId: values.partWarehouseLocationId,
					workCenterId: workCenterId,
				});
				await refreshData();
			}}
			onCompleted={onCompleted}
			render={({ control, watch }) => {
				const currentPartId = watch("partId");

				return (
					<>
						<FormAsyncSelectField
							control={control}
							name={"partId"}
							label={i18n.t("select_part")}
							fetchOptions={({ searchQuery, currentSelection }: FetchAsyncOptionParams<number>) =>
								SitePartQueryApi.getPartSelectionOptions({
									currentPartId: currentSelection,
									searchQuery: searchQuery,
									siteId: defaultSiteId,
									withWarehouseLocations: true,
								})
							}
							getOptionKey={(o) => o.partId}
							getOptionLabel={(o) => o.partLongName}
							rules={requireRule()}
						/>
						<FormLazySelectField
							control={control}
							name={"partWarehouseLocationId"}
							label={i18n.t("warehouse_location")}
							disabled={currentPartId == null}
							rules={requireRule()}
							fetchOptions={async () => {
								if (currentPartId == null) return [];
								return await PartWarehouseLocationQueryApi.getPartWarehouseLocations({
									sitePartId: currentPartId,
								});
							}}
							getOptionKey={(option) => option.partWarehouseLocationId}
							getOptionLabel={(option) => option.locationCode}
						/>
					</>
				);
			}}
		/>
	);
};
