import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { SalesCommissionViewExtraActionsProps } from "src/tenantCustomizations/TenantCustomizations.ts";
import { faCalculator } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { SalvosSalesCommissionCalculationApi } from "src/api/generated/tenants/salvos/erp/sales/commission/api/salvosSalesCommissionCalculationApi.ts";

export const SalvosSalesCommissionViewExtraActions = ({
	sourceType,
	sourceId,
	salesCommission,
	editingEnabled,
	refreshData,
}: SalesCommissionViewExtraActionsProps) => {
	return (
		sourceType === "CUSTOMER_OFFER" &&
		editingEnabled && (
			<AsyncButton
				icon={faCalculator}
				label={i18n.t("calculate_commission")}
				onClick={async () => {
					await SalvosSalesCommissionCalculationApi.calculateSalesCommission({
						customerOfferId: sourceId,
						salesCommissionId: salesCommission.salesCommissionId,
					});
					await refreshData();
				}}
			/>
		)
	);
};
