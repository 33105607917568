// This file is automatically generated. Do not edit manually.

import { ControlChartParentGroup } from "src/api/generated/postgres/db/types/public_/tables/controlChartParentGroup";
import { ChartGroupView } from "src/api/generated/postgres/db/types/public_/tables/chartGroupView";
import { ControlChart } from "src/api/generated/postgres/db/types/public_/tables/controlChart";
import { Resource } from "src/api/generated/postgres/db/types/ext_resourcing/tables/resource";
import { ItemView } from "src/api/generated/postgres/db/types/ext_resourcing/tables/itemView";
import { ItemControlChartView } from "src/api/generated/postgres/db/types/ext_resourcing/tables/itemControlChartView";
import { makeAxiosRequest } from "src/api/utils/apiClient";
import { t } from "i18next";

export namespace ControlChartSelectionApi {
	export async function getControlChartParentGroups(args: {
		searchQuery?: string;
	}): Promise<Array<ControlChartParentGroup>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlCharts/selection/getControlChartParentGroups",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<ControlChartParentGroup>;
	}

	export async function getControlChartGroups(args: {
		parentGroupId: number | null | undefined;
		searchQuery?: string;
	}): Promise<Array<ChartGroupView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlCharts/selection/getControlChartGroups",
			method: "POST",
			data: {
				parentGroupId: args.parentGroupId,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<ChartGroupView>;
	}

	export async function getControlChartsInChartGroup(args: {
		chartGroupId: number;
		searchQuery?: string;
		onlyTimeBasedCharts?: boolean;
	}): Promise<Array<ControlChart>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlCharts/selection/getControlChartsInGroup",
			method: "POST",
			data: {
				chartGroupId: args.chartGroupId,
				searchQuery: args.searchQuery,
				onlyTimeBasedCharts: args.onlyTimeBasedCharts,
			},
		});
		return response as Array<ControlChart>;
	}

	export async function getResources(): Promise<Array<Resource>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlCharts/selection/getResources",
			method: "POST",
			data: {},
		});
		return response as Array<Resource>;
	}

	export async function getResourceItems(args: {
		resourceId: number | null | undefined;
		searchQuery?: string;
	}): Promise<Array<ItemView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlCharts/selection/getResourceItems",
			method: "POST",
			data: {
				resourceId: args.resourceId,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<ItemView>;
	}

	export async function getControlChartsForResourceItem(args: {
		resourceItemId: number;
	}): Promise<Array<ControlChart>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlCharts/selection/getControlChartsForResourceItem",
			method: "POST",
			data: {
				resourceItemId: args.resourceItemId,
			},
		});
		return response as Array<ControlChart>;
	}

	export async function getSelectionValuesForControlChart(args: {
		controlChartId: number;
	}): Promise<ControlChartSelectionApi_Selection> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlCharts/selection/getSelectionValuesForControlChart",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
			},
		});
		return response as ControlChartSelectionApi_Selection;
	}

	export async function getControlChartConnectedItems(args: {
		controlChartId: number;
	}): Promise<Array<ItemControlChartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/controlCharts/selection/getControlChartConnectedItems",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
			},
		});
		return response as Array<ItemControlChartView>;
	}
}

export const ControlChartSelectionApi_ControlChartGroupOrResourceValues = ["CONTROL_CHART_GROUP", "RESOURCE"] as const;

export type ControlChartSelectionApi_ControlChartGroupOrResource =
	(typeof ControlChartSelectionApi_ControlChartGroupOrResourceValues)[number];

export const getControlChartSelectionApi_ControlChartGroupOrResourceLabels = () => ({
	CONTROL_CHART_GROUP: t("control_chart_group"),
	RESOURCE: t("resource"),
});

export const getControlChartSelectionApi_ControlChartGroupOrResourceLabel = (
	value: ControlChartSelectionApi_ControlChartGroupOrResource,
): string => {
	return getControlChartSelectionApi_ControlChartGroupOrResourceLabels()[value];
};

export interface ControlChartSelectionApi_Selection {
	chartGroupId: number | null | undefined;
	chartGroupOrResource: ControlChartSelectionApi_ControlChartGroupOrResource;
	chartParentGroupId: number | null | undefined;
	controlChartId: number | null | undefined;
	resourceId: number | null | undefined;
	resourceItemId: number | null | undefined;
}
