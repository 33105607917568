import { FrontendViewConfig } from "src/components/views/frontendViews/frontendViewConfig.ts";
import { defineStaticView } from "src/components/views/frontendViews/frontendViews.ts";
import i18n from "i18next";
import { faMoneyBillWave } from "@fortawesome/pro-regular-svg-icons";
import { SalvosSalesCommissionLimitManagementView } from "src/tenantCustomizations/tenants/salvos/sales/salesCommissions/SalvosSalesCommissionLimitManagementView.tsx";

export const SALVOS_VIEWS_BY_KEYS: Record<string, FrontendViewConfig> = {
	salvosSalesCommissionLimitManagementPage: defineStaticView({
		component: SalvosSalesCommissionLimitManagementView,
		title: i18n.t("commission_limits"),
		icon: faMoneyBillWave,
		url: "/salvos/salesCommissionLimits",
	}),
};
