// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SalvosCustomerOfferApi {
	export async function printOfferContract(args: { customerOfferId: number }): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOffer/printOfferContract",
			method: "POST",
			data: {
				customerOfferId: args.customerOfferId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
