import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import i18n from "i18next";
import {
	coloredBooleanColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import {WorkcenterView} from "src/api/generated/erp/db/types/tables/workcenterView.ts";
import {WorkCenterApi} from "src/api/generated/erp/production/basedata/workcenters/workCenterApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {WorkCenterForm} from "src/components/views/erp/production/baseData/workcenter/WorkCenterForm.tsx";
import {MenuCheckbox} from "src/components/common/contextMenu/MenuCheckbox.tsx";
import {DocumentsOfObjectButton} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {WorkCenterEditApi} from "src/api/generated/erp/production/basedata/workcenters/workCenterEditApi.ts";

export interface WorkCentersDataGridProps {
	setSelectedWorkCenterId: (selectedWorkCenterId: number | null) => void;
}

export const WorkCentersDataGrid = ({ setSelectedWorkCenterId }: WorkCentersDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"76C1CB15F12D8B54"}
			fetchData={WorkCenterApi.getWorkCenters}
			initialParams={{
				showOnlyDefaultSiteWorkCenters: true,
				searchQuery: "",
			}}
			getRows={(data) => data}
			getRowId={(row) => row.workcenterId}
			onSelectionChanged={(rows) => setSelectedWorkCenterId(rows[0]?.workcenterId ?? null)}
			render={({ dataGridProps, currentParams, refreshData, onlySelectedRow }) => {
				return (
					<CrudDataGrid<WorkcenterView>
						disableMultipleRowSelection
						columns={[
							textColumn({
								field: "siteName",
								headerName: i18n.t("site"),
							}),
							textColumn({
								field: "workcenterNo",
								headerName: i18n.t("work_center"),
							}),
							textColumn({
								field: "workcenterDescription",
								headerName: i18n.t("description"),
								width: 200,
							}),
							floatColumn({
								field: "hourlyCost",
								headerName: i18n.t("hourly_cost"),
								width: 130,
							}),
							coloredBooleanColumn({
								field: "completionEnabled",
								headerName: i18n.t("reporting_enabled"),
								falseColor: "grey",
								width: 120,
							}),
							coloredBooleanColumn({
								field: "partialCompletionEnabled",
								headerName: i18n.t("partial_reporting_enabled"),
								falseColor: "grey",
								width: 120,
							}),
							integerColumn({
								field: "workcenterId",
								headerName: i18n.t("id"),
							}),
						]}
						form={{
							editEnabled: true,
							addRowEnabled: true,
							dialogTitle: i18n.t("work_center"),
							dialogSize: "md",
							component: ({ row, onCompleted, onFormEdited }) => (
								<WorkCenterForm
									workCenter={row}
									onCompleted={onCompleted}
									onFormEdited={onFormEdited}
								/>
							),
						}}
						actionBarComponents={
							<>
								<DocumentsOfObjectButton
									objectRef={nullableAavoObjectRef("WORKCENTER", onlySelectedRow?.workcenterId)}
								/>
								<AavoTextField
									label={i18n.t("search")}
									onSubmit={(searchQuery) => refreshData({ searchQuery })}
								/>
							</>
						}
						actionBarMenuComponents={[
							<MenuCheckbox
								key={"onlyDefaultSite"}
								label={i18n.t("only_default_site")}
								checked={currentParams.showOnlyDefaultSiteWorkCenters}
								onChange={async (value) => {
									await refreshData({ showOnlyDefaultSiteWorkCenters: value });
								}}
							/>,
						]}
						remove={({ row }) => WorkCenterEditApi.delete_({ workCenterId: row.workcenterId })}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
