import { useStoredState } from "src/utils/useStoredState.ts";
import i18n from "i18next";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faPlayCircle, faStopCircle } from "@fortawesome/pro-regular-svg-icons";
import { SpcDataCollectionApi } from "src/api/generated/spc/dataCollection/spcDataCollectionApi.ts";
import Box from "@mui/material/Box";
import { useAavoToast } from "src/components/common/toast/AavoToastContext.tsx";
import { useState } from "react";
import { SpcTimeMeasurementApi } from "src/api/generated/spc/dataCollection/spcTimeMeasurementApi.ts";
import { TimeMeasuringViewTopComponents } from "src/components/views/spc/controlChart/dataInput/TimeMeasuringViewTopComponents.tsx";
import { useParameterizedAsyncData } from "src/utils/async/parameterizedAsyncData.ts";
import { AsyncRender } from "src/components/common/async/AsyncRender.tsx";

export const EffectiveTimeMeasuringView = () => {
	const [storedControlChartId, setStoredControlChartId] = useStoredState<number | null>({
		defaultValue: null,
		key: "CD1091D2674962C32",
	});
	const { dataAsync: chartInfoAsync, refresh } = useParameterizedAsyncData({
		fetchData: ({ controlChartId }) => SpcTimeMeasurementApi.getChartInfo({ controlChartId: controlChartId }),
		initialParams: { controlChartId: storedControlChartId },
	});
	return (
		<AsyncRender
			asyncData={chartInfoAsync}
			content={({ isRunning, chartName }) => {
				return (
					<AsyncContent
						isRunningDefaultValue={isRunning}
						chartName={chartName}
						selectedControlChartId={storedControlChartId}
						setSelectedControlChartId={async (controlChartId) => {
							setStoredControlChartId(controlChartId);
							await refresh({ controlChartId });
						}}
					/>
				);
			}}
		/>
	);
};

interface AsyncContentProps {
	isRunningDefaultValue: boolean;
	chartName: string;
	selectedControlChartId: number | null;
	setSelectedControlChartId: (controlChartId: number | null) => Promise<unknown>;
}

const AsyncContent = ({
	isRunningDefaultValue,
	chartName,
	selectedControlChartId,
	setSelectedControlChartId,
}: AsyncContentProps) => {
	const [isRunning, setIsRunning] = useState<boolean>(isRunningDefaultValue);
	const { showToast } = useAavoToast();
	return (
		<Box
			sx={{
				flex: 1,
			}}
		>
			<TimeMeasuringViewTopComponents
				chartName={chartName}
				isRunning={isRunning}
				selectedControlChartId={selectedControlChartId}
				setSelectedControlChartId={setSelectedControlChartId}
			/>
			<Box
				sx={{
					padding: "1rem",
				}}
			>
				{!isRunning && (
					<AsyncButton
						sx={{
							fontSize: "7vw",
							width: "fit-content",
						}}
						icon={faPlayCircle}
						label={i18n.t("start")}
						disabled={selectedControlChartId == null}
						onClick={async () => {
							if (selectedControlChartId == null) return;

							await SpcDataCollectionApi.beginTimeBasedRecord({
								controlChartId: selectedControlChartId,
							});
							setIsRunning(true);
						}}
						tooltip={selectedControlChartId == null ? i18n.t("select_control_chart") : undefined}
					/>
				)}
				{isRunning && (
					<AsyncButton
						sx={{
							fontSize: "7vw",
						}}
						icon={faStopCircle}
						label={i18n.t("finish")}
						disabled={selectedControlChartId == null}
						onClick={async () => {
							if (selectedControlChartId == null) return;
							await SpcDataCollectionApi.finishTimeBasedRecord({
								controlChartId: selectedControlChartId,
							});
							setIsRunning(false);
							showToast({ message: i18n.t("time_saved_to_control_chart") });
						}}
					/>
				)}
			</Box>
		</Box>
	);
};
