import { SalesCommissionView } from "src/api/generated/erp/db/types/tables/salesCommissionView.ts";
import { LabelValueView } from "src/components/common/misc/LabelValueView.tsx";
import i18n from "i18next";
import { SalesCommissionLineView } from "src/api/generated/erp/db/types/tables/salesCommissionLineView.ts";
import Typography from "@mui/material/Typography";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { formatMoney } from "src/utils/numberUtils.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { SalesCommissionForm } from "src/components/views/erp/sales/salesCommissions/orderAndOfferViews/SalesCommissionForm.tsx";
import { getSalespersonPaymentMethodLabel } from "src/api/generated/erp/db/types/enums/salespersonPaymentMethod.ts";
import { OpenObjectChangeLogButton } from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import Box from "@mui/material/Box";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import { SalesCommissionSourceType } from "src/components/views/erp/sales/salesCommissions/orderAndOfferViews/SalesCommissionSourceType.ts";

export interface SalesCommissionViewProps {
	sourceType: SalesCommissionSourceType;
	sourceId: number;
	salesCommission: SalesCommissionView;
	lines: SalesCommissionLineView[];
	refreshData: () => Promise<unknown>;
	editingEnabled: boolean;
	hasManageCommissionPermission: boolean;
}

export const SalesCommissionInspectView = ({
	sourceType,
	sourceId,
	salesCommission,
	lines,
	refreshData,
	editingEnabled,
	hasManageCommissionPermission,
}: SalesCommissionViewProps) => {
	const { openDialog } = useGenericDialog();
	const tenantCustomizations = useTenantCustomizations();

	const sumMismatch = Math.abs(salesCommission.totalSum - getLinesTotalSum());
	const totalSumDiffers = sumMismatch > 0.001;

	return (
		<HorizontalBox
			sx={{
				flex: 1,
				overflow: "auto",
			}}
		>
			<VerticalBox flex={1} margin={1} gap={1}>
				<LabelValueView
					sx={{
						padding: 0,
					}}
					items={[
						{
							label: i18n.t("total_sum"),
							value: formatMoney(salesCommission.totalSum),
						},
						{
							label: i18n.t("payment_method"),
							value: getSalespersonPaymentMethodLabel(salesCommission.salespersonPaymentMethod),
						},
						{
							label: null,
							value: salesCommission.note,
							multiline: true,
						},
					]}
				/>
				{totalSumDiffers && (
					<Typography
						color="error"
						children={i18n.t("provision_view.total_sum_mismatch_error", {
							mismatch: formatMoney(sumMismatch),
						})}
					/>
				)}
				<Box flex={1} />
				{tenantCustomizations.erp?.sales?.salesCommissionViewExtraActions?.({
					sourceType,
					sourceId,
					salesCommission,
					editingEnabled,
					refreshData,
				})}
				<OpenObjectChangeLogButton
					key={"changeLog"}
					variant={"text"}
					objectRef={{
						objectType: "SALES_COMMISSION",
						objectId: salesCommission.salesCommissionId,
					}}
				/>
			</VerticalBox>
			<VerticalBox
				sx={{
					padding: 1,
				}}
			>
				{editingEnabled && hasManageCommissionPermission && (
					<AsyncButton
						icon={faPen}
						onClick={() => {
							openFormOnDialog({
								openDialog,
								component: SalesCommissionForm,
								props: {
									salesCommission: salesCommission,
								},
								title: i18n.t("sales_commission"),
								size: "sm",
								onSubmit: () => refreshData(),
							});
						}}
					/>
				)}
			</VerticalBox>
		</HorizontalBox>
	);

	function getLinesTotalSum() {
		return lines.reduce((acc, line) => acc + line.sum, 0);
	}
};
