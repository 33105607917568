import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import i18n from "i18next";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { useState } from "react";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import Typography from "@mui/material/Typography";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import {
	SelectedOperationActionsView,
	SelectedOperationActionsViewProps,
} from "src/components/views/erp/production/jobQueue/base/inProgressView/SelectedOperationActionsView.tsx";
import { Box } from "@mui/material";
import { JobQueueShopOrderOperation } from "src/components/views/erp/production/jobQueue/base/JobQueueShopOrderOperation.ts";
import { OperationList } from "src/components/views/erp/production/jobQueue/base/inProgressView/OperationList.tsx";
import { SelectedOperationInfoView } from "src/components/views/erp/production/jobQueue/base/inProgressView/SelectedOperationInfoView.tsx";
import { SelectedOperationTabsView } from "src/components/views/erp/production/jobQueue/base/inProgressView/SelectedOperationTabsView.tsx";
import { useJobQueueViewContext } from "src/components/views/erp/production/jobQueue/base/JobQueueViewContext.ts";
import { Workcenter } from "src/api/generated/erp/db/types/tables/workcenter.ts";
import { getOnlyMember } from "src/utils/arrayUtils.ts";

export interface JobQueueInProgressViewProps
	extends Pick<
		SelectedOperationActionsViewProps,
		| "extraActionBarComponents"
		| "alwaysAllowOperationCompletion"
		| "confirmIfWorkCenterHasIncompleteOperations"
		| "moveShopOrdersOnProductionLine"
	> {
	fetchData: () => Promise<JobQueueInProgressData>;
}

export interface JobQueueInProgressData {
	shopOrderOperations: JobQueueShopOrderOperation[];
	workCenter: Workcenter;
	revertStartOperationEnabled?: boolean;
}

export const JobQueueInProgressView = ({
	fetchData,
	extraActionBarComponents,
	alwaysAllowOperationCompletion,
	confirmIfWorkCenterHasIncompleteOperations,
	moveShopOrdersOnProductionLine,
}: JobQueueInProgressViewProps) => {
	const { inProgressViewRefreshRef } = useJobQueueViewContext();
	const [selectedOperations, setSelectedOperations] = useState<JobQueueShopOrderOperation[]>([]);

	const onlySelectedOperation = getOnlyMember(selectedOperations);

	return (
		<AsyncFetchRender
			fetch={() => fetchOperations(fetchData)}
			refreshRef={inProgressViewRefreshRef}
			content={({ shopOrderOperations, workCenter, revertStartOperationEnabled }, refreshData) => {
				if (shopOrderOperations.length === 0)
					return (
						<VerticalBox flex={1} alignItems={"center"} margin={"auto"}>
							<Typography children={i18n.t("no_jobs_in_progress")} />
							<AavoButton icon={faRefresh} onClick={() => refreshData()} />
						</VerticalBox>
					);

				return (
					<HorizontalBox flex={1}>
						<Box
							sx={{
								flex: 2,
								display: "grid",
								gridTemplateRows: "auto 1fr 1fr",
								"& > :not(:last-child)": {
									borderBottom: (theme) => `1px solid ${theme.palette.grey[600]}`,
								},
							}}
						>
							<SelectedOperationActionsView
								selectedOperations={selectedOperations}
								workCenter={workCenter}
								extraActionBarComponents={extraActionBarComponents}
								alwaysAllowOperationCompletion={alwaysAllowOperationCompletion}
								confirmIfWorkCenterHasIncompleteOperations={confirmIfWorkCenterHasIncompleteOperations}
								moveShopOrdersOnProductionLine={moveShopOrdersOnProductionLine}
								revertStartOperationEnabled={revertStartOperationEnabled ?? true}
							/>
							<OperationList
								rows={shopOrderOperations}
								selectedOperations={selectedOperations}
								setSelectedOperations={setSelectedOperations}
							/>
							<SelectedOperationInfoView selectedOperation={onlySelectedOperation} />
						</Box>
						<SelectedOperationTabsView selectedOperation={onlySelectedOperation} />
					</HorizontalBox>
				);
			}}
		/>
	);

	async function fetchOperations(fetchData: () => Promise<JobQueueInProgressData>) {
		const result = await fetchData();
		const { shopOrderOperations } = result;
		if (
			onlySelectedOperation == null ||
			!shopOrderOperations.some((job) => job.shopOrderOperationId === onlySelectedOperation.shopOrderOperationId)
		) {
			setSelectedOperations(shopOrderOperations.slice(0, 1));
		}
		return result;
	}
};
