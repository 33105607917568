import i18n from "i18next";
import { getShopOrderBatchStateLabel } from "src/api/generated/erp/db/types/enums/shopOrderBatchState.ts";
import { faListDots, faLocation, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { List, ListItem, ListItemText } from "@mui/material";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { ShopOrderBatchView } from "src/api/generated/erp/db/types/tables/shopOrderBatchView.ts";
import type { CloseDialogFunc } from "src/components/common/dialogs/GenericDialogContext.ts";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import { AavoIconButton } from "src/components/common/buttons/AavoIconButton.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { ReactNode } from "react";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { ShopOrderBatchShopOrdersDataGrid } from "src/components/views/erp/production/shopOrderBatch/ShopOrderBatchShopOrdersDataGrid.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import Typography from "@mui/material/Typography";
import { concatWithPipe } from "src/utils/strings.tsx";

export interface ShopOrderBatchJobQueueMobileListViewBaseProps {
	fetchData: () => Promise<ShopOrderBatchView[]>;
	refreshRef?: RefreshableElementRef;
	singleBatchDialogExtra: (params: {
		shopOrderBatch: ShopOrderBatchView;
		shopOrderBatches: ShopOrderBatchView[];
		closeDialog: CloseDialogFunc;
		reload: () => Promise<ShopOrderBatchView[]>;
	}) => ReactNode;
}

export const ShopOrderBatchJobQueueMobileListViewBase = ({
	fetchData,
	singleBatchDialogExtra,
	refreshRef,
}: ShopOrderBatchJobQueueMobileListViewBaseProps) => {
	return (
		<AsyncFetchRender<ShopOrderBatchView[]>
			fetch={() => fetchData()}
			refreshRef={refreshRef}
			content={(shopOrderBatches, reload) => {
				if (shopOrderBatches.length === 0) {
					return (
						<VerticalBox
							sx={{
								flex: 1,
								alignItems: "center",
								justifyContent: "center",
								gap: 1,
							}}
						>
							<Typography variant="h5">{i18n.t("no_shop_order_batches")}</Typography>
							<AsyncButton variant="contained" icon={faRefresh} onClick={() => reload()} />
						</VerticalBox>
					);
				}

				return (
					<VerticalBox
						sx={{
							flex: 1,
						}}
					>
						<AsyncButton
							icon={faRefresh}
							onClick={() => reload()}
							sx={{
								marginTop: 1,
							}}
						/>
						<List
							sx={{
								overflow: "auto",
								flex: 1,
								padding: 1,
							}}
						>
							{shopOrderBatches.map((shopOrderBatch) => {
								return (
									<ShopOrderBatchListItem
										key={shopOrderBatch.shopOrderBatchId}
										shopOrderBatch={shopOrderBatch}
										shopOrderBatches={shopOrderBatches}
										reload={reload}
										singleBatchDialogExtra={singleBatchDialogExtra}
									/>
								);
							})}
						</List>
					</VerticalBox>
				);
			}}
		/>
	);
};

interface ShopOrderBatchListItemProps
	extends Pick<ShopOrderBatchJobQueueMobileListViewBaseProps, "singleBatchDialogExtra"> {
	shopOrderBatch: ShopOrderBatchView;
	shopOrderBatches: ShopOrderBatchView[];
	reload: () => Promise<ShopOrderBatchView[]>;
}

const ShopOrderBatchListItem = ({
	shopOrderBatch,
	singleBatchDialogExtra,
	shopOrderBatches,
	reload,
}: ShopOrderBatchListItemProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<ListItem
			key={shopOrderBatch.shopOrderBatchId}
			disablePadding
			secondaryAction={
				<AavoIconButton
					edge={"end"}
					sx={{
						fontSize: "2rem",
					}}
					icon={faLocation}
					tooltip={i18n.t("actions")}
					onClick={openActionsDialog}
				/>
			}
		>
			<ListItemText
				primary={concatWithPipe(shopOrderBatch.shopOrderBatchId, shopOrderBatch.batchName)}
				secondary={
					<>
						{`${i18n.t("planned_begin_date_short")}: 
                        ${formatIsoString(shopOrderBatch.plannedBeginDate, "L LT") ?? "-"}`}
						<br />
						{`${i18n.t("state")}: `}
						{shopOrderBatch.batchState != null ?
							getShopOrderBatchStateLabel(shopOrderBatch.batchState)
						:	"-"}
					</>
				}
			/>
		</ListItem>
	);

	function openActionsDialog() {
		openDialog(({ closeDialog }) => ({
			title: shopOrderBatch.batchName,
			size: "sm",
			content: (
				<SingleShopOrderBatchDialog
					shopOrderBatch={shopOrderBatch}
					closeDialog={closeDialog}
					extraContent={singleBatchDialogExtra?.({
						shopOrderBatch,
						shopOrderBatches,
						closeDialog,
						reload,
					})}
				/>
			),
		}));
	}
};

interface SingleShopOrderBatchDialogProps {
	shopOrderBatch: ShopOrderBatchView;
	extraContent: ReactNode;
	closeDialog: CloseDialogFunc;
}

const SingleShopOrderBatchDialog = ({ shopOrderBatch, extraContent, closeDialog }: SingleShopOrderBatchDialogProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<VerticalBox
			sx={{
				flex: 1,
				padding: 1,
				gap: 2,
				alignItems: "stretch",
				button: {
					height: "5rem",
				},
			}}
		>
			<AsyncButton
				icon={faListDots}
				label={i18n.t("show_shop_orders")}
				variant="contained"
				onClick={async () => {
					openDialog(() => ({
						title: i18n.t("shop_orders"),
						size: "xl",
						content: <ShopOrderBatchShopOrdersDataGrid shopOrderBatch={shopOrderBatch} editable={false} />,
					}));
					await closeDialog();
				}}
			/>
			{extraContent}
		</VerticalBox>
	);
};
