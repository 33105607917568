import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import { AavoForm, AavoFormContentParams } from "src/components/common/forms/AavoForm.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormResult } from "src/components/common/forms/types.ts";
import { getConfigurationComponentFieldTypeLabels } from "src/api/generated/erp/db/types/enums/configurationComponentFieldType.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import {
	CONFIGURATION_PROPERTY_NULL_VALUE,
	configurationPropertyValueToString,
} from "src/components/views/erp/configurator/configuratorUtils.ts";
import { DeepPartial, useController } from "react-hook-form";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { faPen, faArrowUpRightFromSquare, faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { ConfiguratorPropertyForm } from "src/components/views/erp/configurator/managing/productFamilyVersions/properties/ConfiguratorPropertyForm.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import { CatalogPartApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartApi.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { CatalogPart } from "src/api/generated/erp/db/types/tables/catalogPart.ts";
import Typography from "@mui/material/Typography";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { BoxWithTitle } from "src/components/common/box/BoxWithTitle.tsx";
import { SelectionFieldComponentOptionsForm } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/componentForm/SelectionFieldComponentOptionsForm.tsx";
import { ConfigurationFieldSelectionOptions_Option } from "src/api/generated/io/aavo/applications/db/erp/types/configurationFieldSelectionOptions.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { DocumentQueryApi } from "src/api/generated/documents/api/documentQueryApi.ts";
import { FormConfiguratorLuaEditor } from "src/components/views/erp/configurator/scripting/FormConfiguratorLuaEditor.tsx";
import { FormRichTextEditor } from "src/components/common/forms/fields/FormRichTextEditor.tsx";
import { getConfigurationComponentFieldValueInfoTextVisibilityLabels } from "src/api/generated/erp/db/types/enums/configurationComponentFieldValueInfoTextVisibility.ts";
import { LabelValueView } from "src/components/common/misc/LabelValueView.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { ConfiguratorLibraryComponentsApi } from "src/api/generated/erp/configurator/management/libraries/api/configuratorLibraryComponentsApi.ts";
import { FormSingleFileInputField } from "src/components/common/forms/fields/FormSingleFileInputField";
import { UnmanagedFilesApi } from "src/api/generated/documents/unmanagedFiles/unmanagedFilesApi.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { uploadFile } from "src/utils/fileUploading.ts";

export interface ConfigurationComponentFormProps {
	component: ComponentInitialValues;
	backingPropertyId?: number;
	setIsDirty?: (isDirty: boolean) => void;
	saveComponent?: (component: ConfigurationComponent) => Promise<ConfigurationComponent>;
	onCompleted?: (result: FormResult<ConfigurationComponent>) => Promise<unknown>;
	limitedEditing?: boolean;
}

export interface ComponentInitialValues
	extends Pick<
		ConfigurationComponent,
		| "productFamilyVersionId"
		| "configuratorLibraryVersionId"
		| "libraryComponentSelfUuid"
		| "name"
		| "componentType"
		| "parentTabComponentId"
		| "orderNum"
		| "tabColumnCount"
		| "componentImageUuid"
		| "componentImageName"
	> {
	configurationComponentId: number | undefined;
	fieldType?: ConfigurationComponent["fieldType"];
}

interface FormValues extends ConfigurationComponent {
	componentImageRawFile: File | undefined;
}

export const ConfigurationComponentForm = (props: ConfigurationComponentFormProps) => {
	const { component, setIsDirty, onCompleted: onCompletedProp, saveComponent } = props;
	return (
		<AavoForm
			defaultValues={getDefaultValues()}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={() => setIsDirty?.(true)}
			useFormProps={{}}
			render={(renderParams) => {
				const contentProps = { ...props, ...renderParams };
				return (
					<>
						{component.componentType === "FIELD" ?
							<FieldForm {...contentProps} />
						: component.componentType === "TEXT" ?
							<TextComponentForm {...contentProps} />
						: component.componentType === "SUB_CONFIGURATOR" ?
							<SubConfiguratorForm {...contentProps} />
						: component.componentType === "SUB_CONFIGURATOR_LIST" ?
							<SubConfiguratorForm {...contentProps} />
						: component.componentType === "SECTION_BREAK" ?
							<SectionBreakForm {...contentProps} />
						: component.componentType === "TAB" ?
							<TabForm {...contentProps} />
						: component.componentType === "LIBRARY_COMPONENT_REFERENCE" ?
							<LibraryComponentReferenceForm {...contentProps} />
						:	null}
						<ComponentDocumentsButton {...contentProps} />
					</>
				);
			}}
		/>
	);

	function getDefaultValues(): DeepPartial<FormValues> {
		return {
			fieldEnabled: true,
			fieldSelectionOptions: [],
			componentImageRawFile:
				component.componentImageUuid == undefined ? undefined : new File([], component.componentImageName),
			...component,
		};
	}

	async function submit(values: FormValues, { reset }: AavoFormContentParams<FormValues>) {
		const mappedValues = await mapImageValuesOnSubmit(values);
		if (!saveComponent) return mappedValues;
		const saved = await saveComponent(mappedValues);
		reset(saved);
		setIsDirty?.(false);
		return saved;
	}

	async function mapImageValuesOnSubmit(values: FormValues): Promise<FormValues> {
		if (values.componentImageRawFile?.size === 0) {
			// Keep the existing image. Size of current value is zero. Kind of hack.
			return values;
		}

		if (values.componentImageRawFile == null) {
			return {
				...values,
				componentImageUuid: null,
				componentImageName: "",
			};
		}

		const fileHandle = await uploadFile(values.componentImageRawFile);
		const fileUuid = await UnmanagedFilesApi.createUnmanagedFile({ fileHandle });
		return {
			...values,
			componentImageRawFile: undefined,
			componentImageUuid: fileUuid,
			componentImageName: values.componentImageRawFile.name,
		};
	}

	async function onCompleted(
		result: FormResult<ConfigurationComponent>,
		{ reset }: AavoFormContentParams<FormValues>,
	) {
		if (result.type === "cancel") {
			reset();
		}
		await onCompletedProp?.(result);
	}
};

interface FormContentProps extends AavoFormContentParams<FormValues>, ConfigurationComponentFormProps {}

const FieldForm = (props: FormContentProps) => {
	const { control, setValue, watch, component, limitedEditing } = props;

	const fieldType = watch("fieldType") ?? component.fieldType;

	const isLibraryComponent = component.configuratorLibraryVersionId != null;
	const isExistingLibraryComponent = component.configurationComponentId != null && isLibraryComponent;

	return (
		<>
			<NameField {...props} disabled={limitedEditing || isExistingLibraryComponent} />
			<FormTextField
				control={control}
				name={"label"}
				label={i18n.t("label.configurator_component")}
				rules={requireRule()}
				disabled={isExistingLibraryComponent}
			/>
			<FormEnumSelectField
				control={control}
				name={"fieldType"}
				label={i18n.t("type")}
				options={getConfigurationComponentFieldTypeLabels()}
				rules={requireRule()}
				onChange={() => {
					setValue("fieldDefaultValue", null);
				}}
				disabled={limitedEditing || isExistingLibraryComponent}
			/>
			{fieldType === "SELECTION" && <SelectionFieldFormParts {...props} />}
			{fieldType === "DOCUMENT" && <DocumentFieldFormParts {...props} />}
			<FieldDefaultValueField {...props} />
			<FormCheckbox
				control={control}
				name={"fieldRefreshOnChange"}
				label={i18n.t("refresh_view_on_value_change")}
			/>
			<FormCheckbox
				control={control}
				name={"fieldRequired"}
				label={i18n.t("required")}
				disabled={limitedEditing}
			/>
			<FormCheckbox control={control} name={"fieldEnabled"} label={i18n.t("enabled")} />
			<CommonLayoutFields {...props} />
			{!isLibraryComponent && (
				<FormNumberField control={control} name={"indent"} label={i18n.t("indent")} type={"integer"} />
			)}
			<FormConfiguratorLuaEditor
				control={control}
				name={"transformationScript"}
				label={i18n.t("transformation")}
				productFamilyVersionId={component.productFamilyVersionId}
				catalogPartRevisionId={null}
				disabled={limitedEditing}
			/>
			<FormConfiguratorLuaEditor
				control={control}
				name={"fieldDefaultValueScript"}
				label={i18n.t("default_value_function")}
				productFamilyVersionId={component.productFamilyVersionId}
				catalogPartRevisionId={null}
				disabled={limitedEditing}
			/>
			<FormConfiguratorLuaEditor
				control={control}
				name={"onChangeScript"}
				label={i18n.t("value_change_function")}
				productFamilyVersionId={component.productFamilyVersionId}
				catalogPartRevisionId={null}
				disabled={limitedEditing}
			/>
			<FormRichTextEditor
				control={control}
				name={`fieldValueInfoText`}
				label={i18n.t("value_info_text")}
				jasperReportsCompatibility
				sx={{
					marginTop: 2,
				}}
			/>
			<FormEnumSelectField
				control={control}
				name={`fieldValueInfoTextVisibility`}
				label={i18n.t("info_text_visibility")}
				options={getConfigurationComponentFieldValueInfoTextVisibilityLabels()}
			/>
			<ComponentImageField {...props} />
			<OpenBackingPropertyFormButton {...props} />
		</>
	);
};

const FieldDefaultValueField = ({ control, watch, component, limitedEditing }: FormContentProps) => {
	const fieldType = watch("fieldType") ?? component.fieldType;

	const commonProps = {
		label: i18n.t("default_value"),
	} as const;

	const { field } = useController({
		control: control,
		name: "fieldDefaultValue",
	});

	switch (fieldType) {
		case "TEXT":
		case "TEXTAREA":
			return (
				<AavoTextField
					{...commonProps}
					multiline={fieldType === "TEXTAREA"}
					value={configurationPropertyValueToString(field.value)}
					disabled={limitedEditing}
					onChange={(value) =>
						field.onChange({
							type: "string",
							value: value,
						})
					}
				/>
			);
		case "INTEGER":
		case "DECIMAL":
			return (
				<AavoTextField
					{...commonProps}
					type={"number"}
					value={configurationPropertyValueToString(field.value)}
					disabled={limitedEditing}
					onChange={(value) =>
						field.onChange(
							value == null ?
								CONFIGURATION_PROPERTY_NULL_VALUE
							:	{
									type: {
										DECIMAL: "decimal",
										INTEGER: "int",
									}[fieldType],
									value: value,
								},
						)
					}
				/>
			);
		case "CHECKBOX":
			return (
				<AavoCheckbox
					{...commonProps}
					checked={field.value?.type === "bool" ? field.value.value : false}
					disabled={limitedEditing}
					onChange={(value) =>
						field.onChange({
							type: "bool",
							value: value,
						})
					}
				/>
			);
		case "SELECTION":
			return (
				<SelectField
					{...commonProps}
					options={watch("fieldSelectionOptions")}
					getOptionKey={(option) => option.key}
					getOptionLabel={(option) => option.label}
					value={configurationPropertyValueToString(field.value)}
					disabled={limitedEditing}
					onChange={(_, option) =>
						field.onChange(
							option == null ?
								CONFIGURATION_PROPERTY_NULL_VALUE
							:	{
									type: "string",
									value: option.key,
									label: option.label,
									infoText: option.infoText,
									fileUuid: option.file?.fileUuid,
									hiddenOnPrintouts: option.hiddenOnPrintouts,
								},
						)
					}
				/>
			);
	}
};

const SelectionFieldFormParts = ({ watch, setValue, setIsDirty, limitedEditing }: FormContentProps) => {
	const options = watch("fieldSelectionOptions");

	const { openDialog } = useGenericDialog();

	return (
		<BoxWithTitle
			title={i18n.t("options")}
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 1,
				padding: 1,
			}}
		>
			{options.map((option) => (
				<HorizontalBox key={option.key}>
					<Typography>
						{option.key}: {option.label}
					</Typography>
				</HorizontalBox>
			))}
			<AavoButton
				icon={limitedEditing ? faArrowUpRightFromSquare : faPen}
				label={limitedEditing ? i18n.t("open") : i18n.t("edit")}
				onClick={() => {
					openFormOnDialog({
						openDialog,
						component: SelectionFieldComponentOptionsForm,
						title: i18n.t("options"),
						size: "xl",
						props: {
							options: options,
							limitedEditing: limitedEditing,
						},
						onSubmit: (newOptions: ConfigurationFieldSelectionOptions_Option[]) => {
							setValue("fieldSelectionOptions", newOptions);
							setIsDirty?.(true);
						},
					});
				}}
			/>
		</BoxWithTitle>
	);
};

const DocumentFieldFormParts = ({ control }: FormContentProps) => {
	return (
		<>
			<FormAsyncSelectField
				control={control}
				name={"fieldDocumentCategoryId"}
				label={i18n.t("document_category")}
				fetchOptions={DocumentQueryApi.getDocumentCategoryOptions}
				getOptionKey={(option) => option.documentCategoryId}
				getOptionLabel={(option) => option.name}
			/>
			<FormTextField control={control} name={"fieldDocumentDescription"} label={i18n.t("document_description")} />
		</>
	);
};

const TextComponentForm = (props: FormContentProps) => {
	const { control, limitedEditing } = props;
	return (
		<>
			<NameField {...props} disabled={limitedEditing} />
			<FormTextField
				control={control}
				name={"label"}
				label={i18n.t("label.configurator_component")}
				rules={requireRule()}
			/>
			<FormRichTextEditor
				control={control}
				name={`textComponentContent`}
				label={i18n.t("content")}
				jasperReportsCompatibility
				sx={{ marginTop: 2 }}
			/>
			<FormCheckbox control={control} name={`textComponentVisibleOnForm`} label={i18n.t("visible_on_form")} />
			<FormCheckbox
				control={control}
				name={`textComponentVisibleOnPrintout`}
				label={i18n.t("visible_on_printout")}
			/>
			<CommonLayoutFields {...props} hideVisibilityField />
			<ComponentImageField {...props} />
			<OpenBackingPropertyFormButton {...props} />
		</>
	);
};

const SubConfiguratorForm = (props: FormContentProps) => {
	const { control, limitedEditing } = props;
	return (
		<>
			<NameField {...props} disabled={limitedEditing} />
			<FormTextField control={control} name={"label"} label={i18n.t("title")} rules={requireRule()} />
			<FormAsyncSelectField
				control={control}
				name={"subConfiguratorCatalogPartId"}
				label={i18n.t("part")}
				disabled={limitedEditing}
				fetchOptions={({ searchQuery, currentSelection }) =>
					CatalogPartApi.getCatalogPartOptions({
						searchQuery,
						currentSelection,
						onlyIfConfigurable: true,
					})
				}
				getOptionKey={(option: CatalogPart) => option.catalogPartId}
				getOptionLabel={(option) => concatWithPipe(option.partNo, option.description_1, option.description_2)}
			/>
			<FormCheckbox
				control={control}
				name={"subConfiguratorRequired"}
				label={i18n.t("required")}
				disabled={limitedEditing}
			/>
			<CommonLayoutFields {...props} />
			<OpenBackingPropertyFormButton {...props} />
		</>
	);
};

const SectionBreakForm = ({ control, component, limitedEditing }: FormContentProps) => {
	return (
		<>
			<FormTextField control={control} name={"label"} label={i18n.t("title")} rules={requireRule()} />
			<FormCheckbox control={control} name={"visible"} label={i18n.t("visible")} disabled={limitedEditing} />
			<FormConfiguratorLuaEditor
				control={control}
				name={"transformationScript"}
				label={i18n.t("transformation")}
				productFamilyVersionId={component.productFamilyVersionId}
				catalogPartRevisionId={null}
				disabled={limitedEditing}
			/>
		</>
	);
};

const TabForm = ({ control }: FormContentProps) => {
	return (
		<>
			<FormTextField control={control} name={"label"} label={i18n.t("title")} rules={requireRule()} />
			<FormNumberField
				control={control}
				name={"tabColumnCount"}
				label={i18n.t("column_count")}
				rules={requireRule()}
			/>
			<FormRichTextEditor
				control={control}
				name={"tabPrintoutText"}
				label={i18n.t("printout_text")}
				jasperReportsCompatibility
				sx={{
					marginTop: 2,
				}}
			/>
		</>
	);
};

const LibraryComponentReferenceForm = (props: FormContentProps) => {
	const { control, watch, limitedEditing } = props;
	const { openDialog } = useGenericDialog();
	return (
		<>
			<HorizontalBox alignItems={"center"}>
				<LabelValueView
					items={[
						{
							label: i18n.t("library_component"),
							value: watch("referencedLibraryComponentUuid"),
						},
					]}
				/>
				<AsyncButton
					icon={faArrowUpRightFromSquare}
					tooltip={i18n.t("open")}
					sx={{
						fontSize: "1.2rem",
					}}
					onClick={async () => {
						const referencedComponent =
							await ConfiguratorLibraryComponentsApi.getLatestVersionOfLibraryComponent({
								componentUuid: watch("referencedLibraryComponentUuid")!,
							});
						openDialog(() => ({
							title: referencedComponent.label,
							size: "xl",
							content: <ConfigurationComponentForm component={referencedComponent} limitedEditing />,
						}));
					}}
				/>
			</HorizontalBox>
			<NameField {...props} disabled={limitedEditing} />
			<FormTextField control={control} name={"label"} label={i18n.t("title")} rules={requireRule()} />
			<CommonLayoutFields {...props} />
			<OpenBackingPropertyFormButton {...props} />
		</>
	);
};

const NameField = ({ control, disabled }: FormContentProps & { disabled?: boolean }) => {
	return (
		<FormTextField
			control={control}
			name={"name"}
			label={i18n.t("name")}
			rules={requireRule()}
			disabled={disabled}
		/>
	);
};

const ComponentImageField = ({ control, watch, formState: { dirtyFields } }: FormContentProps) => {
	const { openDialog } = useGenericDialog();
	const imageUuid = watch("componentImageUuid");
	return (
		<HorizontalBox gap={1}>
			<FormSingleFileInputField
				control={control}
				name={"componentImageRawFile"}
				label={i18n.t("image")}
				accept={"image/*"}
				placeholder={i18n.t("select_an_image")}
				sx={{
					flex: 1,
				}}
			/>
			{imageUuid != null && !dirtyFields?.componentImageRawFile && (
				<AsyncButton icon={faExternalLink} label={i18n.t("show")} onClick={openImage} />
			)}
		</HorizontalBox>
	);

	async function openImage() {
		if (imageUuid == null) return;

		const fileUrl = await UnmanagedFilesApi.getUnmanagedFilePresignedDownloadUrl({
			uuid: imageUuid,
		});
		openDialog({
			title: i18n.t("image"),
			size: "sm",
			content: (
				<VerticalBox p={1}>
					<img src={fileUrl} alt={i18n.t("image")} />
				</VerticalBox>
			),
		});
	}
};

interface CommonLayoutFieldsProps extends FormContentProps {
	hideVisibilityField?: boolean;
}

const CommonLayoutFields = ({
	control,
	watch,
	component,
	limitedEditing,
	hideVisibilityField = false,
}: CommonLayoutFieldsProps) => {
	if (component.configuratorLibraryVersionId != null) return;

	return (
		<>
			<FormCheckbox control={control} name={"spanAllColumns"} label={i18n.t("fill_whole_row")} />
			{!watch("spanAllColumns") && (
				<>
					<FormNumberField
						control={control}
						name={"columnSpan"}
						label={i18n.t("column_span")}
						type={"integer"}
						rules={requireRule()}
					/>
					<FormCheckbox control={control} name={"placeOnNewRow"} label={i18n.t("place_on_new_row")} />
				</>
			)}
			{!hideVisibilityField && (
				<FormCheckbox control={control} name={"visible"} label={i18n.t("visible")} disabled={limitedEditing} />
			)}
		</>
	);
};

const OpenBackingPropertyFormButton = ({ component, backingPropertyId, limitedEditing }: FormContentProps) => {
	const { openDialog } = useGenericDialog();

	const productFamilyVersionId = component.productFamilyVersionId;
	if (backingPropertyId == null || productFamilyVersionId == null) return;
	return (
		<AavoButton
			icon={faPen}
			label={i18n.t("property")}
			onClick={() => {
				openFormOnDialog({
					openDialog,
					component: ConfiguratorPropertyForm,
					title: i18n.t("property"),
					size: "xl",
					props: {
						productFamilyVersionId: productFamilyVersionId,
						configuratorPropertyId: backingPropertyId,
						disabled: limitedEditing,
					},
				});
			}}
		/>
	);
};

const ComponentDocumentsButton = ({ component, limitedEditing }: FormContentProps) => {
	if (component.componentType === "TAB") return;
	if (component.configurationComponentId == null) return;

	return (
		<DocumentsOfObjectButton
			label={i18n.t("documents")}
			objectRef={{
				objectType: "CONFIGURATION_COMPONENT",
				objectId: component.configurationComponentId,
			}}
			editable={!limitedEditing}
		/>
	);
};
