import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { ProductionLineStatusViewApi } from "src/api/generated/erp/production/productionLineStatus/api/productionLineStatusViewApi.ts";
import {
	ProductionLineStatus,
	ProductionLineStatus_ShopOrderDto,
	ProductionLineStatus_WorkCenterDto,
} from "src/api/generated/erp/production/productionLineStatus/model/productionLineStatus";
import { AsyncFetchRefreshFunc, AsyncFetchRender } from "src/components/common/async/AsyncFetchRender";
import { HorizontalBox } from "src/components/common/box/HorizontalBox";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { concatWithPipe } from "src/utils/strings.tsx";
import { mergeSx } from "src/utils/styles";
import { faBackward, faForward, faSync } from "@fortawesome/pro-regular-svg-icons";
import { AavoActionBar } from "src/components/common/actionBar/AavoActionBar";
import i18n from "i18next";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { ShopOrderWorkCenterStatusView } from "src/components/views/erp/production/productionLineStatus/ShopOrderWorkCenterStatusView.tsx";
import { useInterval } from "src/utils/useInterval.ts";
import { OpenShopOrderButton } from "src/components/views/erp/utilComponents/OpenShopOrderButton.tsx";
import { useContextMenu } from "src/components/common/contextMenu/useContextMenu.ts";
import { AavoContextMenu } from "src/components/common/contextMenu/AavoContextMenu.tsx";
import { ProductionLineStatusTaktTimeView } from "src/components/views/erp/production/productionLineStatus/ProductionLineStatusTaktTimeView.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";

export interface ProductionLineStatusViewProps {
	productionLineId: number;
}

export const ProductionLineStatusView = (props: ProductionLineStatusViewProps) => {
	const { productionLineId } = props;
	return (
		<AsyncFetchRender
			fetch={() => ProductionLineStatusViewApi.getProductionLineStatus({ productionLineId })}
			content={(status, refresh) => <AsyncContent status={status} refresh={refresh} {...props} />}
		/>
	);
};

interface AsyncContentProps extends ProductionLineStatusViewProps {
	status: ProductionLineStatus;
	refresh: AsyncFetchRefreshFunc<unknown>;
}

const AsyncContent = ({ productionLineId, status, refresh }: AsyncContentProps) => {
	useInterval(() => refresh({ silent: true }), 30 * 1000);

	return (
		<VerticalBox flex={1}>
			<ActionBar refresh={refresh} productionLineId={productionLineId} />
			<HorizontalBox
				sx={{
					flex: 2,
					margin: 1,
					marginTop: 0,
					gap: 1,
				}}
			>
				{status.workCenters.map((workCenter, idx) => (
					<WorkCenterView
						key={workCenter.workCenterId}
						productionLineId={productionLineId}
						workCenter={workCenter}
						refresh={refresh}
						isFirstPosition={idx === 0}
						isLastPosition={idx === status.workCenters.length - 1}
					/>
				))}
			</HorizontalBox>
			<ProductionLineStatusTaktTimeView
				sx={{
					flex: 1,
				}}
				taktTime={status.taktTime}
			/>
		</VerticalBox>
	);
};

interface ActionBarProps {
	productionLineId: number;
	refresh: () => Promise<unknown>;
}

const ActionBar = ({ productionLineId, refresh }: ActionBarProps) => {
	const showConfirmDialog = useConfirmDialog();

	return (
		<AavoActionBar>
			<AsyncButton icon={faSync} onClick={() => refresh()} />
			<AsyncButton
				icon={faForward}
				label={i18n.t("move_line")}
				variant={"outlined"}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("move_production_line_confirm"),
					});
					if (!confirmed) return;
					await ProductionLineStatusViewApi.moveLineForward({ productionLineId });
					await refresh();
				}}
			/>
		</AavoActionBar>
	);
};

interface WorkCenterViewProps {
	refresh: () => Promise<unknown>;
	productionLineId: number;
	workCenter: ProductionLineStatus_WorkCenterDto;
	isFirstPosition: boolean;
	isLastPosition: boolean;
}

const WorkCenterView = ({
	workCenter,
	refresh,
	productionLineId,
	isLastPosition,
	isFirstPosition,
}: WorkCenterViewProps) => {
	return (
		<VerticalBox
			sx={{
				flex: 1,
				gap: 1,
				border: "1px solid",
				borderColor: "grey.400",
				borderRadius: 2,
				padding: 1,
			}}
		>
			<Typography variant="h6" textAlign={"center"}>
				{workCenter.workCenterDescription}
			</Typography>
			{workCenter.shopOrders.map((shopOrder) => (
				<WorkCenterShopOrderView
					key={shopOrder.shopOrderId}
					productionLineId={productionLineId}
					workCenter={workCenter}
					shopOrder={shopOrder}
					refresh={refresh}
					isFirstPosition={isFirstPosition}
					isLastPosition={isLastPosition}
				/>
			))}
		</VerticalBox>
	);
};

interface WorkCenterShopOrderViewProps {
	refresh: () => Promise<unknown>;
	productionLineId: number;
	workCenter: ProductionLineStatus_WorkCenterDto;
	shopOrder: ProductionLineStatus_ShopOrderDto;
	isFirstPosition: boolean;
	isLastPosition: boolean;
}

const WorkCenterShopOrderView = ({
	productionLineId,
	shopOrder,
	workCenter,
	refresh,
	isFirstPosition,
	isLastPosition,
}: WorkCenterShopOrderViewProps) => {
	const { palette } = useTheme();
	const { openDialog } = useGenericDialog();
	const [openContextMenu, contextMenuState] = useContextMenu();

	const totalOperationsCount = shopOrder.workCenterOperations.length;
	const completedOperationsCount = shopOrder.workCenterOperations.filter(
		(operation) => operation.shopOrderOperationState === "READY",
	).length;
	const isCompleted = completedOperationsCount === totalOperationsCount;
	const isStarted = shopOrder.workCenterOperations.some(
		(operation) => operation.shopOrderOperationState === "STARTED" || operation.shopOrderOperationState === "READY",
	);

	const partLongName = concatWithPipe(shopOrder.partNo, shopOrder.partDescription1, shopOrder.partDescription2);

	const backgroundColor =
		isCompleted ? palette.state.completed
		: isStarted ? palette.state.inProgress
		: palette.state.initial;
	const textColor = palette.augmentColor({ color: { main: backgroundColor } }).contrastText;

	const textContent = (
		<>
			<Typography fontSize={"inherit"}>
				{i18n.t("shop_order")} {shopOrder.shopOrderId}
			</Typography>
			<Typography fontSize={"inherit"}>{shopOrder.sourceRef}</Typography>
			<Typography fontSize={"inherit"}>{partLongName}</Typography>
			<Typography fontSize={"inherit"}>{`${completedOperationsCount} / ${totalOperationsCount}`}</Typography>
		</>
	);

	return (
		<Box
			sx={mergeSx(
				{
					flex: 1,
					display: "flex",
					flexDirection: "column",
					backgroundColor: backgroundColor,
					color: textColor,
					padding: 1,
					borderRadius: 1,
				},
				workCenter.andonActive && {
					border: "10px solid",
					borderColor: palette.error.main,
				},
			)}
		>
			<AavoContextMenu state={contextMenuState} />
			<Tooltip title={textContent}>
				<VerticalBox
					sx={{
						flex: 1,
						alignItems: "center",
						justifyContent: "center",
						fontSize: "1.5rem",
						cursor: "pointer",
						overflow: "hidden",
					}}
					onClick={() => {
						openShopOrderOnWorkCenterView();
					}}
					onContextMenu={(e) => {
						openContextMenu({
							mouseEvent: e,
							content: <ShopOrderViewContextMenu shopOrder={shopOrder} />,
						});
					}}
				>
					{textContent}
				</VerticalBox>
			</Tooltip>
			<HorizontalBox
				sx={{
					justifySelf: "flex-end",
				}}
			>
				{!isFirstPosition && (
					<AsyncButton
						icon={faBackward}
						color={"black"}
						onClick={async () => {
							await ProductionLineStatusViewApi.moveShopOrderToPreviousWorkCenter({
								shopOrderId: shopOrder.shopOrderId,
								productionLineId: productionLineId,
							});
							await refresh();
						}}
					/>
				)}
				<Box flex={1} />
				{!isLastPosition && (
					<AsyncButton
						icon={faForward}
						color={"black"}
						onClick={async () => {
							await ProductionLineStatusViewApi.moveShopOrderToNextWorkCenter({
								shopOrderId: shopOrder.shopOrderId,
								productionLineId: productionLineId,
							});
							await refresh();
						}}
					/>
				)}
			</HorizontalBox>
		</Box>
	);

	function openShopOrderOnWorkCenterView() {
		openDialog({
			title: concatWithPipe(shopOrder.shopOrderId, shopOrder.sourceRef),
			content: (
				<ShopOrderWorkCenterStatusView
					shopOrderId={shopOrder.shopOrderId}
					workCenterId={workCenter.workCenterId}
					productionLineId={productionLineId}
				/>
			),
		});
	}
};

interface ShopOrderViewContextMenuProps {
	shopOrder: ProductionLineStatus_ShopOrderDto;
}

const ShopOrderViewContextMenu = ({ shopOrder }: ShopOrderViewContextMenuProps) => {
	return [<OpenShopOrderButton key={shopOrder.shopOrderId} shopOrderId={shopOrder.shopOrderId} />];
};
