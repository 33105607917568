import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import {
	CustomerOrderOfferDataGridApi,
	CustomerOrderOfferDataGridApi_CustomerOrderOfferDto,
} from "src/api/generated/erp/sales/customerOrderOffer/api/customerOrderOfferDataGridApi.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import {
	booleanColumn,
	dateColumn,
	enumColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getCustomerOrderOfferStateLabels } from "src/api/generated/erp/db/types/enums/customerOrderOfferState.ts";
import { getVatHandlingLabels } from "src/api/generated/erp/db/types/enums/vatHandling.ts";
import { CustomerOrderOfferDataGridRowContextMenu } from "src/components/views/erp/sales/customerOrderOffer/CustomerOrderOfferDataGridRowContextMenu.tsx";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { CustomerOrderOfferView } from "src/api/generated/erp/db/types/tables/customerOrderOfferView.ts";
import { CustomerOrderOfferForm } from "src/components/views/erp/sales/customerOrderOffer/CustomerOrderOfferForm.tsx";
import { CustomerOrderOfferEditApi } from "src/api/generated/erp/sales/customerOrderOffer/api/customerOrderOfferEditApi.ts";
import { dataGridRowClassNames } from "src/components/common/dataGrid/styles/dataGridClassNames.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { useTheme } from "@mui/material";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { formatIsoString } from "src/utils/dayjsUtils.ts";

export interface CustomerOrderOfferDataGridProps {
	onlyCustomerOrderOfferToShowId?: number;
	onSelectionChanged: (selectedOffer: CustomerOrderOfferView | undefined) => void;
	refreshRef: RefreshableElementRef;
}

export const CustomerOrderOfferDataGrid = ({
	onlyCustomerOrderOfferToShowId,
	onSelectionChanged,
	refreshRef,
}: CustomerOrderOfferDataGridProps) => {
	const { palette } = useTheme();
	const { dataGridProps, currentParams, refreshData, onlySelectedRow } = useServerSideDataGridModel({
		fetchData: CustomerOrderOfferDataGridApi.getCustomerOrderOffers,
		initialParams: {
			customerOrderOfferId: onlyCustomerOrderOfferToShowId,
			searchQuery: "",
			showOnlyDefaultSiteOffers: true,
			showCancelledOffers: false,
			showClosedOffers: false,
		},
		getRowId: (row) => row.customerOrderOfferId,
		gridId: "C93FDACC6BFE098",
		selectFirstRowOnLoad: onlyCustomerOrderOfferToShowId != null,
		onSelectionChanged: (rows) => onSelectionChanged(rows[0]),
		refreshRef: refreshRef,
	});

	return (
		<ControlledAsyncCrudDataGrid<CustomerOrderOfferDataGridApi_CustomerOrderOfferDto>
			getRowClassNames={({ row }) => {
				if (!row.billingPlanIsComplete) {
					return dataGridRowClassNames.invalid;
				}
			}}
			columns={[
				integerColumn({
					field: "customerOrderOfferId",
					headerName: i18n.t("number_shortened"),
					width: 70,
				}),
				textColumn({
					field: "siteName",
					headerName: i18n.t("site"),
					width: 100,
				}),
				textColumn({
					field: "customerName",
					headerName: i18n.t("customer"),
					width: 150,
				}),
				textColumn({
					field: "orderReference",
					headerName: i18n.t("reference"),
					width: 150,
				}),
				textColumn({
					field: "customerOfferRequestNo",
					headerName: i18n.t("customer_offer_request_no"),
					width: 150,
				}),
				enumColumn({
					field: "customerOrderOfferState",
					headerName: i18n.t("state"),
					enumLabels: getCustomerOrderOfferStateLabels(),
				}),
				booleanColumn({
					field: "frozen",
					headerName: i18n.t("frozen"),
				}),
				dateColumn({
					field: "plannedDeliveryDate",
					headerName: i18n.t("planned_delivery_date"),
					width: 150,
				}),
				{
					field: "validUntilOrValidForDays",
					headerName: i18n.t("valid_until"),
					filterable: false,
					sortable: false,
					valueGetter: (_, row) =>
						row.validUntil != null ?
							i18n.t("valid_until_to", { date: formatIsoString(row.validUntil) })
						:	i18n.t("valid_for_days_param_days", { days: row.validForDays }),
				},
				textColumn({
					field: "responsiblePersonName",
					headerName: i18n.t("responsible_person"),
				}),
				textColumn({
					field: "salespersonName",
					headerName: i18n.t("salesperson"),
				}),
				textColumn({
					field: "contactName",
					headerName: i18n.t("contact_person"),
				}),
				textColumn({
					field: "externalOrderId",
					headerName: i18n.t("external_order_id"),
					width: 150,
				}),
				textColumn({
					field: "deliveryTermsCode",
					headerName: i18n.t("delivery_terms"),
				}),
				textColumn({
					field: "deliveryMethodCode",
					headerName: i18n.t("delivery_method"),
				}),
				textColumn({
					field: "deliveryTermsDestination",
					headerName: i18n.t("delivery_terms_destination"),
				}),
				textColumn({
					field: "deliveryDurationDays",
					headerName: i18n.t("delivery_duration"),
				}),
				textColumn({
					field: "paymentTermDesc",
					headerName: i18n.t("payment_term"),
				}),
				textColumn({
					field: "contractualTermCode",
					headerName: i18n.t("contractual_term"),
				}),
				enumColumn({
					field: "vatHandling",
					headerName: i18n.t("vat_handling"),
					enumLabels: getVatHandlingLabels(),
				}),
				textColumn({
					field: "createdBy",
					headerName: i18n.t("created_by"),
				}),
				dateColumn({
					field: "createdAt",
					headerName: i18n.t("created_at"),
				}),
				dateColumn({
					field: "offeredAt",
					headerName: i18n.t("offered_at"),
				}),
				dateColumn({
					field: "printedAt",
					headerName: i18n.t("printed_at"),
				}),
				dateColumn({
					field: "closedAt",
					headerName: i18n.t("closed_at"),
				}),
			]}
			rowContextMenuComponents={(params) => (
				<CustomerOrderOfferDataGridRowContextMenu refreshData={refreshData} {...params} />
			)}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef("CUSTOMER_ORDER_OFFER", onlySelectedRow?.customerOrderOfferId)}
					/>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={async (value) => {
							await refreshData({ searchQuery: value });
						}}
					/>
					{onlySelectedRow?.billingPlanIsComplete === false && (
						<CenteredTypography color={palette.error.main}>
							{`${i18n.t("billing_plan_invalid")}`}
						</CenteredTypography>
					)}
				</>
			}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"onlyDefaultSite"}
					label={i18n.t("only_default_site")}
					checked={currentParams.showOnlyDefaultSiteOffers}
					onChange={async (value) => {
						await refreshData({ showOnlyDefaultSiteOffers: value });
					}}
				/>,
				<MenuCheckbox
					key={"showClosed"}
					label={i18n.t("show_closed")}
					checked={currentParams.showClosedOffers}
					onChange={async (value) => {
						await refreshData({ showClosedOffers: value });
					}}
				/>,
				<MenuCheckbox
					key={"showCancelled"}
					label={i18n.t("show_cancelled")}
					checked={currentParams.showCancelledOffers}
					onChange={async (value) => {
						await refreshData({ showCancelledOffers: value });
					}}
				/>,
			]}
			form={{
				addRowEnabled: onlyCustomerOrderOfferToShowId == null,
				editEnabled: true,
				dialogTitle: i18n.t("offer"),
				dialogSize: "lg",
				component: ({ row, ...other }) => (
					<CustomerOrderOfferForm customerOrderOfferId={row?.customerOrderOfferId} {...other} />
				),
			}}
			remove={{
				type: "enabled",
				action: ({ items }) =>
					CustomerOrderOfferEditApi.delete_({
						customerOrderOfferIds: items.map((o) => o.customerOrderOfferId),
					}),
			}}
			{...dataGridProps}
		/>
	);
};
