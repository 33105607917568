import dayjs from "dayjs";
import { Typography, useTheme } from "@mui/material";
import { ProductionLineStatus_TaktTimeDto } from "src/api/generated/erp/production/productionLineStatus/model/productionLineStatus";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { mergeSx } from "src/utils/styles.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox";

export interface ProductionLineTaktTimeViewProps {
	taktTime: ProductionLineStatus_TaktTimeDto;
	sx: SxProps<Theme>;
}

export const ProductionLineStatusTaktTimeView = ({ taktTime, sx }: ProductionLineTaktTimeViewProps) => {
	const { palette } = useTheme();

	if (taktTime.remainingTimeSeconds == null) return;

	const remainingDuration = dayjs.duration(taktTime.remainingTimeSeconds, "seconds");
	const color =
		taktTime.remainingTimeSeconds <= taktTime.redLimitSeconds ? palette.error.main
		: taktTime.remainingTimeSeconds <= taktTime.yellowLimitSeconds ? palette.warning.light
		: palette.success.main;

	return (
		<VerticalBox
			sx={mergeSx(
				{
					justifyContent: "center",
					alignItems: "center",
				},
				sx,
			)}
		>
			<Typography
				sx={{
					color: color,
					fontSize: "12rem",
				}}
			>
				{remainingDuration.format("HH:mm")}
			</Typography>
		</VerticalBox>
	);
};
