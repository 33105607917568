import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { FutureInventoryEventsApi } from "src/api/generated/erp/warehouse/inventory/inventoryEvent/api/futureInventoryEventsApi.ts";
import { AavoECharts } from "src/components/common/echarts/AavoECharts.tsx";
import i18n from "i18next";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import { useTheme } from "@mui/material";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { AavoActionBar } from "src/components/common/actionBar/AavoActionBar.tsx";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { PartCumulativeInventoryLevel } from "../../../../../api/generated/erp/warehouse/inventory/inventoryEvent/partCumulativeInventoryLevel.ts";

export interface PartFutureInventoryLevelChartProps {
	partId: number;
}

export const PartFutureInventoryLevelChart = ({ partId }: PartFutureInventoryLevelChartProps) => {
	const theme = useTheme();
	return (
		<AsyncFetchRender<PartCumulativeInventoryLevel[]>
			fetch={async () => await FutureInventoryEventsApi.getFutureCumulativeInventoryEventData({ partId: partId })}
			content={(data, reload) => {
				return (
					<VerticalBox
						sx={{
							flex: 1,
						}}
					>
						<AavoActionBar>
							<AsyncButton icon={faRefresh} tooltip={i18n.t("refresh")} onClick={() => reload()} />
						</AavoActionBar>
						<AavoECharts
							sx={{
								flex: 1,
							}}
							option={{
								grid: {
									top: 5,
									left: 50,
									right: 20,
								},
								animationDuration: 500,
								xAxis: {
									name: i18n.t("date"),
									nameLocation: "center",
									nameGap: 35,
									axisLabel: {
										formatter: (value: string) => formatIsoString(value, "D.M."),
										rotate: 45,
									},
									type: "time",
								},
								yAxis: {
									name: i18n.t("stock_quantity"),
									nameLocation: "center",
									nameGap: 27,
								},
								series: {
									data: data.map((d) => ({
										name: formatIsoString(d.date),
										value: [d.date, d.cumulativeInventoryLevel],
										itemStyle: {
											color:
												d.cumulativeInventoryLevel < 0 ?
													theme.palette.error.main
												:	theme.palette.primary.main,
										},
									})),
									type: "line",
									symbolSize: 10,
								},
								tooltip: {},
								color: [theme.palette.primary.main],
							}}
						/>
					</VerticalBox>
				);
			}}
		/>
	);
};
