// This file is automatically generated. Do not edit manually.

import { ProductionLineView } from "src/api/generated/erp/db/types/tables/productionLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineDataGridApi {
	export async function getDataGridData(args: {
		productionLineGroupId: number;
		showOnlyActiveProductionLines: boolean;
	}): Promise<Array<ProductionLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLines/getDataGridData",
			method: "POST",
			data: {
				productionLineGroupId: args.productionLineGroupId,
				showOnlyActiveProductionLines: args.showOnlyActiveProductionLines,
			},
		});
		return response as Array<ProductionLineView>;
	}
}
