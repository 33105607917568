import {FormCommonProps} from "src/components/common/forms/types.ts";
import i18n from "i18next";
import {PurchaseRequestApi} from "src/api/generated/erp/purchase/purchaseRequest/purchaseRequestApi.ts";
import {FormCheckbox} from "src/components/common/forms/fields/FormCheckbox.tsx";
import {FormDateField} from "src/components/common/forms/fields/FormDateField.tsx";
import {requireRule} from "src/components/common/forms/validation.ts";
import {IsoDateString} from "src/types/dateTime.ts";
import {AsyncForm} from "src/components/common/forms/AsyncForm.tsx";

export interface PurchaseOrderFromPurchaseRequestFormProps extends FormCommonProps<void> {
	purchaseRequestIds: number[];
}

interface FieldValues {
	purchaseRequestIds: Array<number>;
	plannedArrivalDate: IsoDateString;
	sumSamePartRequests: boolean;
}

export const PurchaseOrderFromPurchaseRequestForm = ({
	purchaseRequestIds,
	onFormEdited,
	onCompleted,
}: PurchaseOrderFromPurchaseRequestFormProps) => {
	return (
		<AsyncForm<IsoDateString, FieldValues, void>
			fetch={() =>
				PurchaseRequestApi.getPlannedArrivalDateForPurchaseRequests({
					purchaseRequestIds: purchaseRequestIds,
				})
			}
			getDefaultValues={(plannedArrivalDate) => ({
				purchaseRequestIds: purchaseRequestIds,
				plannedArrivalDate: plannedArrivalDate,
				sumSamePartRequests: false,
			})}
			columns={1}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submit={PurchaseRequestApi.createPurchaseOrderFromSelectedPurchaseRequests}
			render={({ control }) => (
				<>
					<FormDateField
						control={control}
						name={"plannedArrivalDate"}
						label={i18n.t("planned_arrival_date")}
						rules={requireRule()}
						disablePast
					/>
					<FormCheckbox
						control={control}
						name={"sumSamePartRequests"}
						label={i18n.t("sum_the_requests_of_the_equal_part")}
					/>
				</>
			)}
		/>
	);
};
