import { ComponentSelectionView } from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/ComponentSelectionView.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { CatalogPartRevisionUnrestrictedEditingWarning } from "src/components/views/erp/parts/catalogPart/CatalogPartRevisionUnrestrictedEditingWarning.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { CatalogPartRevisionConfiguratorSelectionsViewFooter } from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/CatalogPartRevisionConfiguratorSelectionsViewFooter.tsx";
import i18n from "i18next";
import { PropertySelectionView } from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/PropertySelectionView.tsx";
import { useCatalogPartRevisionConfiguratorManagingViewContext } from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/catalogPartRevisionConfiguratorSelectionsViewContext.ts";
import { Typography } from "@mui/material";

export const CatalogPartRevisionConfiguratorSelectionsStatelessView = () => {
	const { catalogPartRevision } = useCatalogPartRevisionConfiguratorManagingViewContext();

	return (
		<VerticalBox flex={1}>
			{catalogPartRevision.state === "DESIGNED" && !catalogPartRevision.unrestrictedEditingCascaded && (
				<Typography marginY={0.5} marginX={1.5} color={"warning.main"}>
					{i18n.t("part_revision_is_in_designed_state_and_cannot_be_edited")}
				</Typography>
			)}
			<CatalogPartRevisionUnrestrictedEditingWarning revision={catalogPartRevision} />
			<AavoMosaic
				layout={{
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("connected_components"),
							content: <ComponentSelectionView />,
						},
						{
							type: "panel",
							title: i18n.t("connected_properties"),
							content: <PropertySelectionView />,
						},
					],
				}}
			/>
			<CatalogPartRevisionConfiguratorSelectionsViewFooter />
		</VerticalBox>
	);
};
