import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faFileContract } from "@fortawesome/pro-regular-svg-icons";
import { SalvosCustomerOfferApi } from "src/api/generated/tenants/salvos/erp/sales/customerOffer/salvosCustomerOfferApi.ts";
import type { CustomerOrderOfferDataGridRowContextMenuProps } from "src/components/views/erp/sales/customerOrderOffer/CustomerOrderOfferDataGridRowContextMenu.tsx";
import { downloadFile } from "src/utils/fileDownloading.ts";
import i18n from "i18next";

export const SalvosCustomerOfferContextMenuComponents = ({
	row,
	onlySingleRowSelected,
}: CustomerOrderOfferDataGridRowContextMenuProps) => {
	return [
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"printContract"}
				label={i18n.t("print_contract")}
				icon={faFileContract}
				onClick={async () => {
					const fileHandle = await SalvosCustomerOfferApi.printOfferContract({
						customerOfferId: row.customerOrderOfferId,
					});
					downloadFile(fileHandle);
				}}
			/>
		),
	];
};
