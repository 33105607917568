import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import { faBell, faBellSlash } from "@fortawesome/pro-regular-svg-icons";
import { WorkCenterJobQueueInProgressApi } from "src/api/generated/erp/production/jobQueues/api/workCenter/workCenterJobQueueInProgressApi.ts";
import { Workcenter } from "src/api/generated/erp/db/types/tables/workcenter.ts";

export interface JobQueueAndonButtonProps {
	workCenter: Workcenter;
	refresh?: () => Promise<unknown>;
}

export const JobQueueAndonButton = ({ workCenter, refresh }: JobQueueAndonButtonProps) => {
	return (
		<>
			{workCenter.andonEnabled && (
				<AsyncButton
					label={workCenter.andonActive ? i18n.t("clear_andon") : i18n.t("andon")}
					icon={workCenter.andonActive ? faBellSlash : faBell}
					variant={"outlined"}
					onClick={async () => {
						await WorkCenterJobQueueInProgressApi.setWorkCenterHasAndon({
							workcenterId: workCenter.workcenterId,
							hasAndon: !workCenter.andonActive,
						});
						await refresh?.();
					}}
				/>
			)}
		</>
	);
};
