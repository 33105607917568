import {PartialTenantCustomizations} from "src/tenantCustomizations/TenantCustomizations";
import {
    OmegakeittiotPurchaseRequestDataGridActionBarComponents
} from "./OmegakeittiotPurchaseRequestDataGridActionBarComponents";
import {
    OmegakeittiotShopOrderBatchContextMenuComponents
} from "src/tenantCustomizations/tenants/omegakeittiot/OmegakeittiotShopOrderBatchContextMenuComponents.tsx";
import {
	getInventoryLevelEventQuantitySpcSamplingMethod, getInventoryLevelEventTotalWeightSpcSamplingMethod
} from "src/components/views/erp/spcSamplingMethods/inventoryLevelEventSpcSamplingMethods.tsx";
import {
    getOmegakeittiotShopOrderBatchDataGridCustomColumns
} from "src/tenantCustomizations/tenants/omegakeittiot/omegakeittiotShopOrderBatchDataGridCustomColumns.tsx";

export const omegakeittiotCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
			configuratorEnabled: true,
		},
		tasks: {
			enabled: false,
		},
		documentsEnabled: false,
	},
	purchaseRequestsDataGridActionBarComponents: OmegakeittiotPurchaseRequestDataGridActionBarComponents,
	spcSamplingMethods: () => [
		getInventoryLevelEventQuantitySpcSamplingMethod(),
		getInventoryLevelEventTotalWeightSpcSamplingMethod(),
	],
	erp: {
		production: {
			shopOrderBatchContextMenuComponents: OmegakeittiotShopOrderBatchContextMenuComponents,
			addShopOrdersToBatchConfiguratorFilterComponents: [
				{ componentUuid: "c376b1bb-04d6-441e-af99-241eb52169cb" },
			],
			shopOrderBatchDataGridCustomColumns: getOmegakeittiotShopOrderBatchDataGridCustomColumns,
		},
	},
} as const;
