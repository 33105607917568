// This file is automatically generated. Do not edit manually.

import { PartWarehouseLocationView } from "src/api/generated/erp/db/types/tables/partWarehouseLocationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PartWarehouseLocationQueryApi {
	export async function getPartWarehouseLocations(args: {
		sitePartId: number;
		showDisabledLocations?: boolean;
	}): Promise<Array<PartWarehouseLocationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/partWarehouseLocations/getPartWarehouseLocations",
			method: "POST",
			data: {
				sitePartId: args.sitePartId,
				showDisabledLocations: args.showDisabledLocations,
			},
		});
		return response as Array<PartWarehouseLocationView>;
	}

	export async function getPartWarehouseLocation(args: {
		partNo: string;
		locationCode: string;
		siteId: number;
	}): Promise<PartWarehouseLocationView> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sitePart/partWarehouseLocations/getPartWarehouseLocation",
			method: "POST",
			data: {
				partNo: args.partNo,
				locationCode: args.locationCode,
				siteId: args.siteId,
			},
		});
		return response as PartWarehouseLocationView;
	}
}
