// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderLineActionApi {
	export async function cancelCustomerOrderLine(args: { customerOrderLineId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderLine/cancelCustomerOrderLine",
			method: "POST",
			data: {
				customerOrderLineId: args.customerOrderLineId,
			},
		});
		return response as void;
	}

	export async function rescheduleSelectedCustomerOrderLines(args: {
		customerOrderLineIds: Array<number>;
		newPlannedDeliveryDate: IsoDateString;
		updateAcquisitionObjects: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderLine/rescheduleSelectedCustomerOrderLines",
			method: "POST",
			data: {
				customerOrderLineIds: args.customerOrderLineIds,
				newPlannedDeliveryDate: args.newPlannedDeliveryDate,
				updateAcquisitionObjects: args.updateAcquisitionObjects,
			},
		});
		return response as void;
	}

	export async function changeCustomerOrderServiceLineDeliveredQuantity(args: {
		customerOrderLineDtos: Array<CustomerOrderLineActionApi_CustomerOrderServiceLineDeliveryDto>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderLine/changeCustomerOrderServiceLineDeliveredQuantity",
			method: "POST",
			data: {
				customerOrderLineDtos: args.customerOrderLineDtos,
			},
		});
		return response as void;
	}

	export async function updateCustomerOrderLineQuantity(args: {
		customerOrderLineId: number;
		newQuantity: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderLine/updateCustomerOrderLineQuantity",
			method: "POST",
			data: {
				customerOrderLineId: args.customerOrderLineId,
				newQuantity: args.newQuantity,
			},
		});
		return response as void;
	}

	export async function releaseCustomerOrderLine(args: { customerOrderLineId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderLine/releaseCustomerOrderLine",
			method: "POST",
			data: {
				customerOrderLineId: args.customerOrderLineId,
			},
		});
		return response as void;
	}
}

export interface CustomerOrderLineActionApi_CustomerOrderServiceLineDeliveryDto {
	amount: number;
	customerOrderLineId: number;
}
