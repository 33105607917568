import {useState} from "react";
import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic.tsx";
import {WorkCentersDataGrid} from "src/components/views/erp/production/baseData/workcenter/WorkCentersDataGrid.tsx";
import {CenteredTypography} from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import {
    WorkCenterOperationsDataGrid
} from "src/components/views/erp/production/baseData/workcenter/WorkCenterOperationsDataGrid.tsx";
import {
    WorkCenterProductionLinesDataGrid
} from "src/components/views/erp/production/baseData/workcenter/WorkCenterProductionLinesDataGrid.tsx";
import {
    WorkCenterPartWarehouseLocationsDataGrid
} from "src/components/views/erp/production/baseData/workcenter/WorkCenterPartWarehouseLocationsDataGrid.tsx";
import {
    UserNotificationsOfSourceDataGrid
} from "src/components/views/notifications/UserNotificationsOfSourceDataGrid.tsx";
import {
    WorkCenterWarehouseAreaDataGrid
} from "src/components/views/erp/production/baseData/workcenter/WorkCenterWarehouseAreaDataGrid.tsx";

export const WorkCentersView = () => {
	const [selectedWorkCenterId, setSelectedWorkCenterId] = useState<number | null>(null);
	return (
		<AavoMosaic
			layout={{
				type: "row",
				first: {
					type: "panel",
					content: <WorkCentersDataGrid setSelectedWorkCenterId={setSelectedWorkCenterId} />,
				},
				second: {
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("operations"),
							content:
								selectedWorkCenterId == null ?
									<CenteredTypography>{i18n.t("select_work_center")}</CenteredTypography>
								:	<WorkCenterOperationsDataGrid
										key={selectedWorkCenterId}
										workCenterId={selectedWorkCenterId}
									/>,
						},
						{
							type: "panel",
							title: i18n.t("production_lines"),
							content:
								selectedWorkCenterId == null ?
									<CenteredTypography>{i18n.t("select_work_center")}</CenteredTypography>
								:	<WorkCenterProductionLinesDataGrid
										key={selectedWorkCenterId}
										workCenterId={selectedWorkCenterId}
									/>,
						},
						{
							type: "panel",
							title: i18n.t("parts"),
							content:
								selectedWorkCenterId == null ?
									<CenteredTypography>{i18n.t("select_work_center")}</CenteredTypography>
								:	<WorkCenterPartWarehouseLocationsDataGrid
										key={selectedWorkCenterId}
										workCenterId={selectedWorkCenterId}
									/>,
						},
						{
							type: "panel",
							title: i18n.t("notifications"),
							content:
								selectedWorkCenterId == null ?
									<CenteredTypography>{i18n.t("select_work_center")}</CenteredTypography>
								:	<UserNotificationsOfSourceDataGrid
										key={selectedWorkCenterId}
										sourceType={"WORKCENTER"}
										sourceId={selectedWorkCenterId}
									/>,
						},
						{
							type: "panel",
							title: i18n.t("warehouse_area"),
							content:
								selectedWorkCenterId == null ?
									<CenteredTypography>{i18n.t("select_work_center")}</CenteredTypography>
								:	<WorkCenterWarehouseAreaDataGrid
										key={selectedWorkCenterId}
										selectedWorkCenterId={selectedWorkCenterId}
									/>,
						},
					],
				},
			}}
		/>
	);
};
