// This file is automatically generated. Do not edit manually.

import { WorkcenterPartWarehouseLocationView } from "src/api/generated/erp/db/types/tables/workcenterPartWarehouseLocationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkCenterPartWarehouseLocationsApi {
	export async function getWorkCenterPartWarehouseLocations(args: {
		workCenterId: number;
	}): Promise<Array<WorkcenterPartWarehouseLocationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterPartWarehouseLocations/getWorkCenterPartWarehouseLocations",
			method: "POST",
			data: {
				workCenterId: args.workCenterId,
			},
		});
		return response as Array<WorkcenterPartWarehouseLocationView>;
	}

	export async function delete_(args: { workCenterPartWarehouseLocationId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterPartWarehouseLocations/delete",
			method: "POST",
			data: {
				workCenterPartWarehouseLocationId: args.workCenterPartWarehouseLocationId,
			},
		});
		return response as void;
	}

	export async function addNewWorkCenterPartWarehouseLocation(args: {
		partWarehouseLocationId: number;
		workCenterId: number;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterPartWarehouseLocations/addNewWorkCenterPartWarehouseLocation",
			method: "POST",
			data: {
				partWarehouseLocationId: args.partWarehouseLocationId,
				workCenterId: args.workCenterId,
			},
		});
		return response as number;
	}
}
