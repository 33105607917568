import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import {
	ProductionLineWorkCenterOperationActionViewApi,
	ProductionLineWorkCenterOperationActionViewApi_InitData,
} from "src/api/generated/erp/production/jobQueues/api/productionLineWorkCenterOperationActionViewApi.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { ShopOrderOperationView } from "src/api/generated/erp/db/types/tables/shopOrderOperationView.ts";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import i18n from "i18next";
import Box from "@mui/material/Box";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { SurveySubmissionForm } from "src/components/views/erp/surveys/submissions/SurveySubmissionForm.tsx";
import { showAsyncDialog } from "src/components/common/dialogs/asyncDialog.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { JobQueueAndonButton } from "src/components/views/erp/production/jobQueue/common/JobQueueAndonButton.tsx";
import { AavoActionBar } from "src/components/common/actionBar/AavoActionBar.tsx";

export interface ProductionLineWorkCenterOperationActionViewProps {
	shopOrderId: number;
}

export const ProductionLineWorkCenterOperationActionView = (
	props: ProductionLineWorkCenterOperationActionViewProps,
) => {
	const { shopOrderId } = props;
	return (
		<AsyncFetchRender
			fetch={() =>
				ProductionLineWorkCenterOperationActionViewApi.getInitData({
					shopOrderId,
				})
			}
			content={(data, refresh) => <AsyncContent refresh={refresh} {...props} {...data} />}
		/>
	);
};

interface AsyncContentProps
	extends ProductionLineWorkCenterOperationActionViewProps,
		ProductionLineWorkCenterOperationActionViewApi_InitData {
	refresh: () => Promise<unknown>;
}

const AsyncContent = ({ shopOrderOperations, workCenter, refresh }: AsyncContentProps) => {
	const [revertLatestChecked, setRevertLatestChecked] = useState(false);

	return (
		<VerticalBox
			sx={{
				flex: 1,
				padding: 1,
				paddingTop: 0,
			}}
		>
			<AavoActionBar>
				<JobQueueAndonButton workCenter={workCenter} refresh={refresh} />
				<AavoCheckbox
					label={i18n.t("revert_latest_action")}
					checked={revertLatestChecked}
					onChange={setRevertLatestChecked}
				/>
			</AavoActionBar>
			<Box
				sx={{
					flex: 1,
					alignSelf: "stretch",
					display: "grid",
					gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
					alignItems: "stretch",
					gap: 1,
				}}
			>
				{shopOrderOperations.map((shopOrderOperation) => (
					<SingleShopOrderOperation
						key={shopOrderOperation.shopOrderOperationId}
						shopOrderOperation={shopOrderOperation}
						revertLatestChecked={revertLatestChecked}
						refresh={refresh}
					/>
				))}
			</Box>
		</VerticalBox>
	);
};

interface SingleShopOrderOperationProps {
	shopOrderOperation: ShopOrderOperationView;
	revertLatestChecked: boolean;
	refresh: () => Promise<unknown>;
}

const SingleShopOrderOperation = ({
	shopOrderOperation,
	revertLatestChecked,
	refresh,
}: SingleShopOrderOperationProps) => {
	const { palette } = useTheme();
	const { openDialog } = useGenericDialog();

	const shopOrderOperationId = shopOrderOperation.shopOrderOperationId;

	const color =
		shopOrderOperation.shopOrderOperationState === "READY" ? palette.success
		: shopOrderOperation.shopOrderOperationState === "STARTED" ? palette.yellow
		: palette.blue;

	return (
		<AsyncButton
			variant={"outlined"}
			onClick={onClick}
			reserveSpaceForLoadingSpinner
			sx={{
				borderWidth: 2,
				borderRadius: 3,
				backgroundColor: color.main,
			}}
		>
			<Typography
				variant={"button"}
				sx={{
					color: "black.main",
					fontSize: "1rem",
					overflow: "hidden",
					textAlign: "center",
					overflowWrap: "break-word",
				}}
			>
				{shopOrderOperation.operationNo} {shopOrderOperation.operationDescription}
			</Typography>
		</AsyncButton>
	);

	async function onClick() {
		switch (shopOrderOperation.shopOrderOperationState) {
			case "RELEASED":
				if (revertLatestChecked) {
					return;
				} else {
					await ProductionLineWorkCenterOperationActionViewApi.startOperation({
						shopOrderOperationId: shopOrderOperationId,
					});
				}
				break;
			case "STARTED":
				if (revertLatestChecked) {
					await ProductionLineWorkCenterOperationActionViewApi.revertStartOperation({
						shopOrderOperationId: shopOrderOperationId,
					});
				} else {
					await completeOperation();
				}
				break;
			case "READY":
				if (revertLatestChecked) {
					await ProductionLineWorkCenterOperationActionViewApi.revertCompleteOperation({
						shopOrderOperationId: shopOrderOperationId,
					});
				} else {
					return;
				}
				break;
		}

		await refresh();
	}

	async function completeOperation() {
		const surveyFormId = shopOrderOperation.surveyFormId;
		if (shopOrderOperation.surveyRequired && surveyFormId != null) {
			const surveySubmissionId = await showAsyncDialog(openDialog, ({ onCompleted, onFormEdited }) => ({
				title: shopOrderOperation.surveyFormName,
				size: "lg",
				content: (
					<SurveySubmissionForm
						surveyFormId={surveyFormId}
						surveySubmissionId={shopOrderOperation.surveySubmissionId ?? undefined}
						objectRef={{
							objectType: "SHOP_ORDER_OPERATION",
							objectId: shopOrderOperationId,
							objectDescription: shopOrderOperation.sourceRefData ?? "",
						}}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}));
			if (surveySubmissionId == undefined) {
				return;
			}
		}

		await ProductionLineWorkCenterOperationActionViewApi.completeOperation({
			shopOrderOperationId: shopOrderOperationId,
		});
		await refresh();
	}
};
