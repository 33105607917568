// This file is automatically generated. Do not edit manually.

import { ShopOrderOperationView } from "src/api/generated/erp/db/types/tables/shopOrderOperationView";
import { Workcenter } from "src/api/generated/erp/db/types/tables/workcenter";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineWorkCenterOperationActionViewApi {
	export async function getInitData(args: {
		shopOrderId: number;
	}): Promise<ProductionLineWorkCenterOperationActionViewApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineWorkCenterOperationActionView/getInitData",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as ProductionLineWorkCenterOperationActionViewApi_InitData;
	}

	export async function startOperation(args: { shopOrderOperationId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineWorkCenterOperationActionView/startOperation",
			method: "POST",
			data: {
				shopOrderOperationId: args.shopOrderOperationId,
			},
		});
		return response as void;
	}

	export async function revertStartOperation(args: { shopOrderOperationId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineWorkCenterOperationActionView/revertStartOperation",
			method: "POST",
			data: {
				shopOrderOperationId: args.shopOrderOperationId,
			},
		});
		return response as void;
	}

	export async function completeOperation(args: { shopOrderOperationId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineWorkCenterOperationActionView/completeOperation",
			method: "POST",
			data: {
				shopOrderOperationId: args.shopOrderOperationId,
			},
		});
		return response as void;
	}

	export async function revertCompleteOperation(args: { shopOrderOperationId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineWorkCenterOperationActionView/revertCompleteOperation",
			method: "POST",
			data: {
				shopOrderOperationId: args.shopOrderOperationId,
			},
		});
		return response as void;
	}
}

export interface ProductionLineWorkCenterOperationActionViewApi_InitData {
	shopOrderOperations: Array<ShopOrderOperationView>;
	workCenter: Workcenter;
}
