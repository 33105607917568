// This file is automatically generated. Do not edit manually.

import { ProductionLineGroupShopOrderOperationView } from "src/api/generated/erp/db/types/tables/productionLineGroupShopOrderOperationView";
import { Workcenter } from "src/api/generated/erp/db/types/tables/workcenter";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineGroupJobQueueInProgressApi {
	export async function getInProgressOperations(): Promise<ProductionLineGroupJobQueueInProgressApi_GetOperationsResponse> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineGroupJobQueue/getInProgressOperations",
			method: "POST",
			data: {},
		});
		return response as ProductionLineGroupJobQueueInProgressApi_GetOperationsResponse;
	}
}

export interface ProductionLineGroupJobQueueInProgressApi_GetOperationsResponse {
	revertStartOperationEnabled: boolean;
	shopOrderOperations: Array<ProductionLineGroupShopOrderOperationView>;
	workCenter: Workcenter;
}
