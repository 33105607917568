import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { ProductFamilyVersionManagingViewContext } from "src/components/views/erp/configurator/managing/productFamilyVersions/ProductFamilyVersionManagingViewContext.tsx";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { ConfigurationComponentForm } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/componentForm/ConfigurationComponentForm.tsx";
import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";

export const NewTabComponentButton = () => {
	const { openDialog } = useGenericDialog();
	const { productFamilyVersion, getOrderNumForNewTab, addComponent, limitedEditing } = useContextOrThrow(
		ProductFamilyVersionManagingViewContext,
	);

	if (limitedEditing) return null;

	return (
		<AavoButton
			icon={faPlus}
			iconProps={{ size: "sm" }}
			sx={{
				marginLeft: 0.5,
				alignSelf: "stretch",
			}}
			disableTooltipEl
			onClick={() => {
				const newTabOrderNum = getOrderNumForNewTab();
				openDialog(({ closeDialog, onDataDirtyStateChanged }) => ({
					title: i18n.t("new_tab"),
					size: "sm",
					content: (
						<ConfigurationComponentForm
							component={{
								configurationComponentId: undefined,
								configuratorLibraryVersionId: undefined,
								libraryComponentSelfUuid: undefined,
								name: `__tab-${newTabOrderNum}`,
								productFamilyVersionId: productFamilyVersion.configurationProductFamilyVersionId,
								componentType: "TAB",
								parentTabComponentId: null,
								orderNum: newTabOrderNum,
								tabColumnCount: 1,
								componentImageName: "",
								componentImageUuid: undefined
							}}
							saveComponent={async (newComponent: ConfigurationComponent) => addComponent(newComponent)}
							setIsDirty={(isDirty) => onDataDirtyStateChanged({ isDirty })}
							onCompleted={async () => {
								await closeDialog();
							}}
						/>
					),
				}));
			}}
		/>
	);
};
