// This file is automatically generated. Do not edit manually.

import { WorkcenterProductionLineView } from "src/api/generated/erp/db/types/tables/workcenterProductionLineView";
import { WorkcenterProductionLine } from "src/api/generated/erp/db/types/tables/workcenterProductionLine";
import { ProductionLine } from "src/api/generated/erp/db/types/tables/productionLine";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkCenterProductionLinesApi {
	export async function getWorkCenterProductionLines(args: {
		workCenterId: number;
	}): Promise<Array<WorkcenterProductionLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterProductionLines/getWorkCenterProductionLines",
			method: "POST",
			data: {
				workCenterId: args.workCenterId,
			},
		});
		return response as Array<WorkcenterProductionLineView>;
	}

	export async function delete_(args: { workCenterProductionLineId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterProductionLines/delete",
			method: "POST",
			data: {
				workCenterProductionLineId: args.workCenterProductionLineId,
			},
		});
		return response as void;
	}

	export async function addProductionLineToWorkCenter(args: {
		workCenterId: number;
		productionLineId: number;
	}): Promise<WorkcenterProductionLine> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterProductionLines/addProductionLineToWorkCenter",
			method: "POST",
			data: {
				workCenterId: args.workCenterId,
				productionLineId: args.productionLineId,
			},
		});
		return response as WorkcenterProductionLine;
	}

	export async function getAvailableProductionLineOptions(args: {
		siteId: number;
		workCenterId: number;
		searchQuery: string;
	}): Promise<Array<ProductionLine>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterProductionLines/getAvailableProductionLineOptions",
			method: "POST",
			data: {
				siteId: args.siteId,
				workCenterId: args.workCenterId,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<ProductionLine>;
	}
}
