import { SalvosSalesCommissionLimitManagementApi } from "src/api/generated/tenants/salvos/erp/sales/commission/api/salvosSalesCommissionLimitManagementApi.ts";
import { SalvosSalesCommissionLimit } from "src/api/generated/postgres/db/types/salvos/tables/salvosSalesCommissionLimit.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AavoForm, AavoFormContentParams } from "src/components/common/forms/AavoForm.tsx";
import i18n from "i18next";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import { DeepPartial } from "react-hook-form";

export interface SalvosSalesCommissionLimitFormProps extends FormCommonProps<number> {
	commissionLimit: SalvosSalesCommissionLimit | undefined;
}

interface FormValues extends SalvosSalesCommissionLimit {}

export const SalvosSalesCommissionLimitForm = (props: SalvosSalesCommissionLimitFormProps) => {
	const { commissionLimit, onCompleted, onFormEdited } = props;
	return (
		<AavoForm
			defaultValues={getDefaultValues()}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues(): DeepPartial<FormValues> {
		return (
			commissionLimit ?? {
				additionalRelativeCommissionPercent: 0,
			}
		);
	}

	async function submit(values: FormValues) {
		if (commissionLimit == null) {
			return await SalvosSalesCommissionLimitManagementApi.insert({ limit: values });
		} else {
			await SalvosSalesCommissionLimitManagementApi.update({ limit: values });
			return commissionLimit.salvosSalesCommissionLimitId;
		}
	}
};

interface FormContentProps extends SalvosSalesCommissionLimitFormProps, AavoFormContentParams<FormValues> {}

const FormContent = ({ control }: FormContentProps) => {
	return (
		<>
			<FormNumberField
				control={control}
				name="minSalesPriceWithVat"
				label={i18n.t("sales_price_with_vat")}
				rules={requireRule()}
			/>
			<FormNumberField
				control={control}
				name="mainCommission"
				label={i18n.t("main_commission")}
				rules={requireRule()}
			/>
			<FormNumberField
				control={control}
				name="additionalCommission"
				label={i18n.t("additional_commission")}
				rules={requireRule()}
			/>
			<FormNumberField
				control={control}
				name="additionalRelativeCommissionPercent"
				label={i18n.t("additional_percent")}
				rules={requireRule()}
			/>
		</>
	);
};
