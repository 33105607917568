import { ControlChartSelectionForm } from "src/components/views/spc/common/ControlChartSelectionForm.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { CloseDialogFunc } from "src/components/common/dialogs/GenericDialogContext.ts";
import { AavoFormLayout } from "src/components/common/forms/AavoFormLayout.tsx";
import { AavoFormFooter } from "src/components/common/forms/AavoFormFooter.tsx";
import { useState } from "react";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import i18n from "i18next";

export interface TimeBasedControlChartSelectionFormProps {
	currentControlChartId: number | undefined;
	onSubmit: (controlChartId: number | undefined) => Promise<unknown>;
	closeDialog: CloseDialogFunc;
}

export const TimeBasedControlChartSelectionForm = ({
	currentControlChartId,
	onSubmit,
	closeDialog,
}: TimeBasedControlChartSelectionFormProps) => {
	const [selectedChartId, setSelectedChartId] = useState<number | undefined>(undefined);
	return (
		<VerticalBox
			sx={{
				flex: 1,
			}}
		>
			<AavoFormLayout>
				<ControlChartSelectionForm
					currentControlChartId={currentControlChartId}
					onChange={(controlChartId) => setSelectedChartId(controlChartId)}
					onlyTimeBasedControlCharts={true}
				/>
			</AavoFormLayout>
			<AavoFormFooter>
				<AsyncButton
					icon={faCheck}
					label={i18n.t("save")}
					onClick={async () => {
						await onSubmit(selectedChartId);
						await closeDialog();
					}}
				/>
			</AavoFormFooter>
		</VerticalBox>
	);
};
