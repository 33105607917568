import { AavoTextField, AavoTextFieldProps } from "src/components/common/inputFields/AavoTextField.tsx";
import { ConfigurationPropertyValue } from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import { toIntOrNull } from "src/utils/strings.tsx";
import {
	CONFIGURATION_PROPERTY_NULL_VALUE,
	configurationPropertyValueToString,
} from "src/components/views/erp/configurator/configuratorUtils.ts";
import { ConfiguratorFieldComponentProps } from "src/components/views/erp/configurator/configuratorForm/components/field/ConfiguratorFieldComponent.tsx";
import {
	getConfiguratorFieldComponentErrorMessage,
	getConfiguratorFieldComponentLabelWithRequiredMark,
} from "src/components/views/erp/configurator/configuratorForm/components/field/configuratorFieldComponentUtils.ts";

interface ConfiguratorTextFieldProps
	extends ConfiguratorFieldComponentProps,
		Pick<AavoTextFieldProps, "multiline" | "minRows" | "type"> {}

export const ConfiguratorTextField = ({
	field,
	onChange,
	onSubmit,
	multiline,
	minRows,
	type,
	disabled,
}: ConfiguratorTextFieldProps) => {
	const convertValue = (newValue: string): ConfigurationPropertyValue => {
		switch (field.fieldType) {
			case "INTEGER": {
				const parsedValue = toIntOrNull(newValue);
				return parsedValue == null ?
						CONFIGURATION_PROPERTY_NULL_VALUE
					:	{
							type: "int",
							value: parsedValue,
							label: newValue,
							fileUuid: field.imageUuid,
						};
			}
			case "DECIMAL": {
				const parsedValue = parseFloat(newValue);
				return isNaN(parsedValue) ?
						CONFIGURATION_PROPERTY_NULL_VALUE
					:	{
							type: "decimal",
							value: parsedValue,
							label: newValue,
							fileUuid: field.imageUuid,
						};
			}
			default:
				return {
					type: "string",
					value: newValue,
					label: newValue,
					fileUuid: field.imageUuid,
					isFreeInput: true,
					hiddenOnPrintouts: false,
				};
		}
	};

	return (
		<AavoTextField
			label={getConfiguratorFieldComponentLabelWithRequiredMark(field)}
			disabled={!field.enabled || disabled}
			value={configurationPropertyValueToString(field.currentValue)}
			onChange={(newValue) => {
				onChange(convertValue(newValue));
			}}
			onSubmit={async (newValue) => {
				await onSubmit(convertValue(newValue));
			}}
			error={getConfiguratorFieldComponentErrorMessage(field, field.currentValue)}
			sx={{
				flex: 1,
			}}
			multiline={multiline}
			minRows={minRows}
			type={type}
		/>
	);
};
