import {AsyncForm, AsyncFormContentParams} from "src/components/common/forms/AsyncForm.tsx";
import {
    CustomerOrderLineFormApi,
    CustomerOrderLineFormApi_InitData,
} from "src/api/generated/erp/sales/customerOrder/api/customerOrderLineFormApi.ts";
import {
    OpenCustomerOrderChildLinesButton
} from "src/components/views/erp/sales/customerOrderLine/OpenCustomerOrderChildLinesButton.tsx";
import {useErrorDialog} from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import {CustomerOrderLine} from "src/api/generated/erp/db/types/tables/customerOrderLine.ts";
import {FormCommonProps, FormSubmitResult} from "src/components/common/forms/types.ts";
import {DefaultValues} from "react-hook-form";
import {FormAsyncSelectField} from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import {requireRule} from "src/components/common/forms/validation.ts";
import {SalesPartView} from "src/api/generated/erp/db/types/tables/salesPartView.ts";
import i18n from "i18next";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import {FormDateField} from "src/components/common/forms/fields/FormDateField.tsx";
import {FormCheckbox} from "src/components/common/forms/fields/FormCheckbox.tsx";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField.tsx";
import {FormSelectField} from "src/components/common/forms/fields/FormSelectField.tsx";
import {
    AcquisitionMethod,
    AcquisitionMethodValues,
    getAcquisitionMethodLabel,
} from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";
import {useGenericDialog} from "src/components/common/dialogs/GenericDialogContext.ts";
import {showAsyncDialog} from "src/components/common/dialogs/asyncDialog.ts";
import {
    CustomerOrderLineBeforeCreateConfiguratorView
} from "src/components/views/erp/configurator/configuratorForm/impl/CustomerOrderLineBeforeCreateConfiguratorView.tsx";
import {round} from "src/utils/numberUtils.ts";
import {getDefaultVatCode} from "src/components/views/erp/sales/salesUtils.ts";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {dayJsToDateIsoStringNullable} from "src/utils/dayjsUtils.ts";
import {IsoDateString} from "src/types/dateTime.ts";

export interface CustomerOrderLineFormProps extends FormCommonProps<number> {
	customerOrderId: number;
	customerOrderLineId: number | undefined;
	parentLineId: number | undefined;
	copySourceCustomerOrderLine?: CustomerOrderLine;
}

interface FormValues extends Omit<CustomerOrderLine, "salesPartId"> {
	salesPart: SalesPartView;
	updateAcquisitionObjectDate: boolean;
}

export const CustomerOrderLineForm = (props: CustomerOrderLineFormProps) => {
	const {
		customerOrderId,
		customerOrderLineId,
		parentLineId,
		copySourceCustomerOrderLine,
		onCompleted,
		onFormEdited,
	} = props;
	const { openDialog } = useGenericDialog();

	return (
		<AsyncForm<CustomerOrderLineFormApi_InitData, FormValues, number>
			fetch={() =>
				CustomerOrderLineFormApi.getFormInitData({
					customerOrderId: customerOrderId,
					customerOrderLineId: customerOrderLineId,
					parentLineId: parentLineId,
					copySourceCustomerOrderLineId: copySourceCustomerOrderLine?.customerOrderLineId,
				})
			}
			getDefaultValues={getDefaultValues}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={2}
			render={(params) => <FormContent {...params} {...props} />}
			submit={submitForm}
			footerExtraComponents={({ handleSubmit, contentProps: { watch } }) => (
				<>
					{customerOrderLineId == null && watch("salesPart")?.partIsConfigurable && (
						<AsyncButton label={i18n.t("configure")} onClick={() => handleSubmit(configureAndInsert)} />
					)}
				</>
			)}
		/>
	);

	function getDefaultValues({
		customerOrder,
		customerOrderLine,
		salesPart,
		parentLine,
		defaultLineNumber,
	}: CustomerOrderLineFormApi_InitData): DefaultValues<FormValues> {
		if (customerOrderLine != null) {
			return {
				...customerOrderLine,
				salesPart: salesPart ?? undefined,
				unitPrice:
					customerOrder.inputPricesWithVat ? customerOrderLine.unitPriceWithVat : customerOrderLine.unitPrice,
				updateAcquisitionObjectDate: true,
			};
		} else if (copySourceCustomerOrderLine != null) {
			return {
				...copySourceCustomerOrderLine,
				customerOrderLineId: undefined,
				salesPart: salesPart ?? undefined,
				updateAcquisitionObjectDate: true,
				lineNumber: defaultLineNumber,
			};
		} else {
			return {
				customerOrderId: customerOrderId,
				parentLineId: parentLineId,
				lineNumber: defaultLineNumber,
				plannedDeliveryDate: customerOrder.plannedDeliveryDate,
				updateAcquisitionObjectDate: true,
				salesQuantityAsSalesUnits: 1,
				discountPercentage: 0,
				vatCodeId: customerOrder.billingPlanEnabled ? customerOrder.vatCodeId : parentLine?.vatCodeId,
			};
		}
	}

	async function submitForm(values: FormValues): Promise<FormSubmitResult<number>> {
		const customerOrderLine = mapFormValuesToCustomerOrderLine(values);
		if (customerOrderLineId != null) {
			await CustomerOrderLineFormApi.update({
				customerOrderLine: customerOrderLine,
				updateAcquisitionObjectDates: values.updateAcquisitionObjectDate,
			});
			return customerOrderLineId;
		} else {
			const configureBeforeInsert =
				copySourceCustomerOrderLine?.partConfigurationId != null ||
				(await CustomerOrderLineFormApi.getShouldConfigureBeforeInsert({
					customerOrderId: customerOrderLine.customerOrderId,
					salesPartId: customerOrderLine.salesPartId,
				}));
			return await insert({
				customerOrderLine,
				configureBeforeInsert,
			});
		}
	}

	async function configureAndInsert(values: FormValues): Promise<FormSubmitResult<number>> {
		const customerOrderLine = mapFormValuesToCustomerOrderLine(values);
		return await insert({
			customerOrderLine: customerOrderLine,
			configureBeforeInsert: true,
		});
	}

	async function insert({
		customerOrderLine,
		configureBeforeInsert,
	}: {
		customerOrderLine: CustomerOrderLine;
		configureBeforeInsert: boolean;
	}): Promise<FormSubmitResult<number>> {
		if (configureBeforeInsert) {
			const partConfigurationId = await showAsyncDialog<number>(openDialog, ({ onCompleted }) => ({
				title: i18n.t("configure"),
				content: (
					<CustomerOrderLineBeforeCreateConfiguratorView
						customerOrderLine={customerOrderLine}
						onCompleted={onCompleted}
						initialValuesFromConfigurationId={copySourceCustomerOrderLine?.partConfigurationId ?? undefined}
					/>
				),
			}));
			if (partConfigurationId === undefined) {
				return "interrupted";
			}

			customerOrderLine.partConfigurationId = partConfigurationId;
		}
		return await CustomerOrderLineFormApi.insert({
			customerOrderLine: customerOrderLine,
		});
	}

	function mapFormValuesToCustomerOrderLine(values: FormValues): CustomerOrderLine {
		return {
			...values,
			salesPartId: values.salesPart.salesPartId,
		};
	}
};

interface FormContentProps
	extends CustomerOrderLineFormProps,
		AsyncFormContentParams<CustomerOrderLineFormApi_InitData, FormValues> {}

const FormContent = ({
	parentLineId,
	copySourceCustomerOrderLine,
	data: { customerOrder, customerOrderLine, acquisitionObjectState, vatCodeOptions },
	control,
	formState,
	watch,
	setValue,
	getValues,
}: FormContentProps) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const isPackageChildLine = parentLineId != null;
	const isExistingCustomerOrderLine = customerOrderLine != null;

	const salesPart = watch("salesPart");
	const acquisitionMethod = watch("acquisitionMethod");
	const salesQuantityAsSalesUnits = watch("salesQuantityAsSalesUnits");

	const plannedDeliveryDateChanged = formState.dirtyFields.plannedDeliveryDate;
	const mayHaveAcquisitionObject =
		customerOrderLine != null &&
		["MANUFACTURE", "PURCHASE_DIRECT", "PURCHASE_TRANSIT"].includes(customerOrderLine.acquisitionMethod);

	return (
		<>
			<FormAsyncSelectField
				control={control}
				name={"salesPart"}
				formValueType="option"
				label={i18n.t("sales_part")}
				rules={requireRule()}
				fetchOptions={({ searchQuery, currentSelection }) =>
					CustomerOrderLineFormApi.getSalesPartOptions({
						customerOrderId: customerOrder.customerOrderId,
						searchQuery: searchQuery,
						currentSelection: currentSelection,
					})
				}
				getOptionKey={(option: SalesPartView) => option.salesPartId}
				getOptionLabel={(option) => `${option.salesPartNo} | ${option.salesPartDescription}`}
				onChange={onSalesPartChanged}
				disabled={isExistingCustomerOrderLine || copySourceCustomerOrderLine != null}
				spanGridColumns
			/>
			<FormTextField control={control} name={"mark"} label={i18n.t("mark")} spanGridColumns />
			{getSalesQuantityField()}
			{!isPackageChildLine && (
				<>
					<FormDateField
						control={control}
						name={"plannedDeliveryDate"}
						label={i18n.t("planned_delivery_date")}
						onChange={async (newPlannedDeliveryDate) => {
							await resetUnitPrice({
								plannedDeliveryDate: dayJsToDateIsoStringNullable(newPlannedDeliveryDate),
							});
						}}
					/>
					{plannedDeliveryDateChanged && mayHaveAcquisitionObject && (
						<FormCheckbox
							control={control}
							name={"updateAcquisitionObjectDate"}
							label={i18n.t("customer_order_line_form_update_acquisition_object_date_field")}
							spanGridColumns
						/>
					)}
				</>
			)}
			{!isPackageChildLine && (
				<>
					{getUnitPriceField()}
					<FormCheckbox
						control={control}
						name={"priceLocked"}
						label={i18n.t("price_locked")}
						disabled={disabledIfInvoiced()}
					/>
					<FormNumberField
						control={control}
						name={"discountPercentage"}
						label={i18n.t("discount_percentage")}
						disabled={disabledIfInvoiced()}
						rules={requireRule()}
					/>
				</>
			)}
			{!isPackageChildLine && customerOrder.vatHandling !== "REVERSE_CHARGE_VAT" && (
				<FormSelectField
					control={control}
					name={"vatCodeId"}
					label={i18n.t("vat_code")}
					options={vatCodeOptions}
					getOptionKey={(vatCode) => vatCode.vatCodeId}
					getOptionLabel={(vatCode) => vatCode.vatCodeName}
					rules={requireRule()}
					disabled={disabledIfInvoiced()}
					startNewGridRow
					onChange={(vatCodeId) => resetUnitPrice({ vatCodeId })}
				/>
			)}
			{salesPart?.salesPartType !== "PACKAGE_" && (
				<>
					<FormNumberField control={control} name={"unitCost"} label={i18n.t("cost")} startNewGridRow />
					<FormCheckbox control={control} name={"costLocked"} label={i18n.t("cost_locked")} />
				</>
			)}
			<FormSelectField
				control={control}
				name={"acquisitionMethod"}
				label={i18n.t("acquisition_method")}
				options={getAvailableAcquisitionMethods()}
				getOptionKey={(method) => method}
				getOptionLabel={(method) => {
					if (method === "MANUFACTURE") return i18n.t("shop_order");
					return getAcquisitionMethodLabel(method);
				}}
				disabled={salesPart?.salesPartType !== "WAREHOUSE" || disabledIfReleased()}
				startNewGridRow
			/>
			<FormTextField
				control={control}
				name={"shopOrderBatchCode"}
				label={i18n.t("shop_order_batch")}
				disabled={disabledIfReleased()}
				sx={{
					visibility: acquisitionMethod === "MANUFACTURE" ? "visible" : "hidden",
				}}
			/>
			{watch("acquisitionMethod") === "MANUFACTURE" && (
				<FormNumberField
					control={control}
					name={"capacityQuantity"}
					label={i18n.t("capacity_quantity")}
					disabled={disabledIfReleased()}
				/>
			)}
			<FormNumberField
				control={control}
				name={"lineNumber"}
				label={i18n.t("line_number")}
				type={"integer"}
				rules={requireRule()}
			/>
			{customerOrderLine && <OpenCustomerOrderChildLinesButton customerOrderLine={customerOrderLine} />}
		</>
	);

	function getSalesQuantityField() {
		const labelSalesUnitPart = salesPart?.salesUnit != null ? ` (${salesPart.salesUnit})` : "";
		const label = `${i18n.t("quantity")}${labelSalesUnitPart}`;

		const disabled =
			acquisitionObjectState === "SHOP_ORDER_PLANNED" ? i18n.t("acquisition_shop_order_is_already_planned")
			: acquisitionObjectState === "PURCHASE_ORDER_RELEASED" ?
				i18n.t("acquisition_purchase_order_is_already_released")
			:	undefined;

		return (
			<FormNumberField
				control={control}
				name={"salesQuantityAsSalesUnits"}
				label={label}
				rules={requireRule()}
				onSubmit={(value) => {
					setValue("capacityQuantity", getDefaultCapacityQuantity(salesPart, value));
				}}
				disabled={disabled}
			/>
		);
	}

	function getUnitPriceField() {
		const labelVatPart = customerOrder.inputPricesWithVat ? i18n.t("with_vat") : i18n.t("without_vat");
		const labelSalesUnitPart = salesPart?.salesUnit != null ? ` (${salesPart.salesUnit})` : "";
		const label = `${i18n.t("unit_price")}${labelSalesUnitPart}, ${labelVatPart}`;
		return (
			<FormNumberField
				control={control}
				name={"unitPrice"}
				label={label}
				rules={requireRule()}
				startNewGridRow
				disabled={
					customerOrderLine?.customerOrderLineState === "INVOICED" ?
						i18n.t("customer_order_line_is_already_invoiced")
					:	undefined
				}
			/>
		);
	}

	async function onSalesPartChanged(salesPart: SalesPartView | null) {
		try {
			const vatCodeId = getDefaultVatCodeId(salesPart);
			await resetUnitPrice({ salesPart, vatCodeId });
			setValue("vatCodeId", vatCodeId);
			setValue("acquisitionMethod", salesPart?.acquisitionMethod ?? "NO_ACQUISITION");
			setValue("capacityQuantity", getDefaultCapacityQuantity(salesPart, salesQuantityAsSalesUnits));
			setValue(
				"unitCost",
				salesPart == null || salesPart.standardCost == null ?
					0
				:	round(salesPart.standardCost * salesPart.salesFactor, 2),
			);
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}

	function getDefaultVatCodeId(salesPart: SalesPartView | null): number {
		if (customerOrder.billingPlanEnabled) return customerOrder.vatCodeId;
		if (salesPart != null) return salesPart.vatCodeId;

		return getDefaultVatCode(vatCodeOptions).vatCodeId;
	}

	async function resetUnitPrice({
		salesPart = getValues("salesPart"),
		vatCodeId = getValues("vatCodeId"),
		plannedDeliveryDate = getValues("plannedDeliveryDate"),
	}: {
		salesPart?: SalesPartView | null;
		vatCodeId?: number | null;
		plannedDeliveryDate?: IsoDateString | null;
	}) {
		if (getValues("priceLocked") === true) return;

		let defaultUnitPrice = 0;
		if (salesPart != null && vatCodeId != null) {
			defaultUnitPrice = await CustomerOrderLineFormApi.getSalesPartDefaultPrice({
				salesPartId: salesPart.salesPartId,
				customerOrderId: customerOrder.customerOrderId,
				withVat: customerOrder.inputPricesWithVat,
				vatCodeId: vatCodeId,
				plannedDeliveryDate: plannedDeliveryDate ?? customerOrder.plannedDeliveryDate,
			});
		}
		setValue("unitPrice", defaultUnitPrice);
	}

	function getDefaultCapacityQuantity(salesPart: SalesPartView | null, salesQuantityAsSalesUnits: number | null) {
		if (salesPart == null || salesPart.capacityQuantity == null || salesQuantityAsSalesUnits == null) return 0;

		return salesPart.capacityQuantity * salesPart.salesFactor * salesQuantityAsSalesUnits;
	}

	function disabledIfInvoiced() {
		return customerOrderLine?.customerOrderLineState === "INVOICED" ?
				i18n.t("customer_order_line_is_already_invoiced")
			:	undefined;
	}

	function disabledIfReleased() {
		return customerOrderLine?.releaseDate != null ? i18n.t("customer_order_line_is_already_released") : undefined;
	}

	function getAvailableAcquisitionMethods(): readonly AcquisitionMethod[] {
		return salesPart == null || salesPart.salesPartType === "WAREHOUSE" ?
				AcquisitionMethodValues.filter((method) => method !== "NO_ACQUISITION")
			:	["NO_ACQUISITION"];
	}
};
