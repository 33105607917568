// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SpcDataCollectionApi {
	export async function beginTimeBasedRecord(args: { controlChartId: number }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/dataCollection/beginTimeBasedRecord",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
			},
		});
		return response as number;
	}

	export async function finishTimeBasedRecord(args: { controlChartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/dataCollection/finishTimeBasedRecord",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
			},
		});
		return response as void;
	}

	export async function revertLastRecordToIncomplete(args: { controlChartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/dataCollection/revertLastRecordToIncomplete",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
			},
		});
		return response as void;
	}

	export async function finishTimeBasedRecordAndStartNew(args: { controlChartId: number }): Promise<number | null> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/dataCollection/finishTimeBasedRecordAndStartNew",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
			},
		});
		return response === "" ? null : (response as number | null);
	}

	export async function disableLatestRecordOfChart(args: { controlChartId: number }): Promise<number | null> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/dataCollection/disableLatestRecordOfChart",
			method: "POST",
			data: {
				controlChartId: args.controlChartId,
			},
		});
		return response === "" ? null : (response as number | null);
	}
}
