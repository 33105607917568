import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { Typography } from "@mui/material";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { RunningClockIcon } from "src/components/common/icons/RunningClockIcon.tsx";
import { TimeBasedControlChartSelectionForm } from "src/components/views/spc/controlChart/dataInput/TimeBasedControlChartSelectionForm.tsx";
import Box from "@mui/material/Box";

export interface TimeMeasuringViewTopComponentsProps {
	chartName: string | null;
	isRunning: boolean;
	selectedControlChartId: number | null;
	setSelectedControlChartId: (controlChartId: number | null) => Promise<unknown>;
}

export const TimeMeasuringViewTopComponents = ({
	chartName,
	isRunning,
	selectedControlChartId,
	setSelectedControlChartId,
}: TimeMeasuringViewTopComponentsProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<Box
			sx={{
				fontSize: "1.5rem",
				margin: "0.5rem",
				gap: "1rem",
				display: "flex",
				flexDirection: {
					xs: "column",
					sm: "row",
				},
			}}
		>
			<Typography fontSize={"inherit"} fontWeight={"bold"} children={`${i18n.t("control_chart")}: `} />
			<Typography fontSize={"inherit"} children={chartName} />
			<AsyncButton
				label={selectedControlChartId == null ? i18n.t("select_control_chart") : undefined}
				variant={"outlined"}
				icon={faPen}
				disabled={isRunning ? i18n.t("timing_in_progress") : undefined}
				onClick={async () => {
					openDialog(({ closeDialog }) => ({
						title: i18n.t("select_control_chart"),
						content: (
							<TimeBasedControlChartSelectionForm
								currentControlChartId={selectedControlChartId ?? undefined}
								onSubmit={(controlChartId) => setSelectedControlChartId(controlChartId ?? null)}
								closeDialog={closeDialog}
							/>
						),
						size: "sm",
					}));
				}}
			/>
			{isRunning && <RunningClockIcon sx={{ fontSize: "3rem" }} />}
		</Box>
	);
};
