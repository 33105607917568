import { TestPage } from "src/components/views/TestPage.tsx";
import i18n from "i18next";
import { PartCategoriesPage } from "src/components/views/erp/parts/partCategories/PartCategoriesPage.tsx";
import { SystemDiagramsManagementPage } from "src/components/views/erp/common/systemDiagrams/SystemDiagramsManagementPage.tsx";
import { DocumentSearchPage } from "src/components/views/documents/search/DocumentSearchPage.tsx";
import { DocumentsOfSingleObjectView } from "src/components/views/documents/objectDocuments/DocumentsOfSingleObjectView.tsx";
import { resolveHandlebarsTemplate } from "src/utils/handlebars.ts";
import { AavoObjectType } from "src/api/generated/common/sourceType/aavoObjectType.ts";
import {
	FrontendViewConfig,
	GeneratorFrontendViewConfig,
	ModalFrontendViewConfig,
	StaticFrontendViewConfig,
} from "src/components/views/frontendViews/frontendViewConfig.ts";
import { PickableCustomersOrdersDataGrid } from "src/components/views/erp/sales/delivery/picking/PickableCustomersOrdersDataGrid.tsx";
import { WarehousePickerGroupsDataGrid } from "../erp/warehouse/basedata/WarehousePickerGroupsDataGrid.tsx";
import {
	faAngleDoubleRight,
	faArchive,
	faBacon,
	faBezierCurve,
	faBookmark,
	faBooks,
	faBorderAll,
	faBorderStyle,
	faBox,
	faBoxesPacking,
	faBoxOpen,
	faBoxTissue,
	faBriefcase,
	faCalculator,
	faChartLine,
	faCheckDouble,
	faClipboardCheck,
	faClipboardList,
	faCogs,
	faCommentsDollar,
	faConciergeBell,
	faConveyorBeltBoxes,
	faDiagramSubtask,
	faFileCode,
	faFileImport,
	faFileInvoiceDollar,
	faFileSignature,
	faFlag,
	faFolder,
	faFolderOpen,
	faHandshakeAngle,
	faIconsAlt,
	faIndustry,
	faList12,
	faListAlt,
	faListDots,
	faMagnifyingGlass,
	faMapPin,
	faMoneyBillAlt,
	faMoneyBillWave,
	faObjectGroup,
	faPallet,
	faPen,
	faPercent,
	faPlus,
	faProjectDiagram,
	faReceipt,
	faRedo,
	faScroll,
	faShapes,
	faShoppingCart,
	faSignature,
	faSitemap,
	faSortNumericDown,
	faStopwatch,
	faStore,
	faSuperscript,
	faSwatchbook,
	faTable,
	faTachometerAlt,
	faThList,
	faThumbtack,
	faTrailer,
	faTruckLoading,
	faTruckMoving,
	faUniversity,
	faUp,
	faUserFriends,
	faUserMd,
	faUsers,
	faUserTie,
	faVial,
	faViruses,
	faWallet,
	faWarehouse,
} from "@fortawesome/pro-regular-svg-icons";
import { AllInvoicesDataGrid } from "../erp/sales/invoicing/AllInvoicesDataGrid.tsx";
import { MELLANO_VIEWS_BY_KEYS } from "src/tenantCustomizations/tenants/mellano/viewList.ts";
import { BillableCustomerOrdersDataGrid } from "src/components/views/erp/sales/invoicing/customerOrderInvoicing/BillableCustomerOrdersDataGrid.tsx";
import { CargoDriversDataGrid } from "../erp/warehouse/basedata/CargoDriversDataGrid.tsx";
import { DeliveryTermsDataGrid } from "../erp/warehouse/basedata/DeliveryTermsDataGrid.tsx";
import { DeliveryMethodDataGrid } from "../erp/warehouse/basedata/DeliveryMethodDataGrid.tsx";
import { ReclamationCausesDataGrid } from "src/components/views/erp/reclamation/baseData/ReclamationCausesDataGrid.tsx";
import { ReclamationCategoriesDataGrid } from "src/components/views/erp/reclamation/baseData/ReclamationCategoriesDataGrid.tsx";
import { CompaniesDataGrid } from "src/components/views/erp/common/companies/CompaniesDataGrid.tsx";
import { CustomerOrderImportPage } from "src/components/views/erp/sales/customerOrder/CustomerOrderImportPage.tsx";
import { AllTasksPage } from "src/components/views/tasks/pages/AllTasksPage.tsx";
import { AllReclamationsPage } from "../erp/reclamation/AllReclamationsPage.tsx";
import { SingleReclamationView } from "../erp/reclamation/SingleReclamationView.tsx";
import { TasksAssignedToMePage } from "src/components/views/tasks/pages/TasksAssignedToMePage.tsx";
import { TasksOnMyResponsibilityPage } from "src/components/views/tasks/pages/TasksOnMyResponsibilityPage.tsx";
import { CChartEventCollectionView } from "src/components/views/spc/cChartEvents/CChartEventCollectionView.tsx";
import { CChartEventCollectionApi } from "src/api/generated/spc/cChartEvents/cChartEventCollectionApi.ts";
import { CostEventPriceListPage } from "src/components/views/erp/common/costEvents/CostEventPriceListPage.tsx";
import { ErpUnitsDataGrid } from "../erp/warehouse/basedata/ErpUnitsDataGrid.tsx";
import { SitesDataGrid } from "../erp/common/sites/SitesDataGrid.tsx";
import { NumberSequencesDataGrid } from "../erp/common/numberSequences/NumberSequencesDataGrid.tsx";
import { PurchaseRequestsDataGrid } from "src/components/views/erp/purchase/purchaseRequests/PurchaseRequestsDataGrid.tsx";
import { WarehouseLocationsDataGrid } from "src/components/views/erp/warehouse/basedata/WarehouseLocationsDataGrid.tsx";
import { CChartEventSearchPage } from "src/components/views/spc/cChartEvents/searchView/CChartEventSearchPage.tsx";
import { CostEventItemsDataGrid } from "src/components/views/erp/common/costEvents/CostEventItemsDataGrid.tsx";
import { ManualInventoryActionsView } from "src/components/views/erp/warehouse/inventory/manualEventViews/ManualInventoryActionsView.tsx";
import { CustomerOrderContactInfoView } from "../erp/sales/customerOrder/CustomerOrderContactInfoView.tsx";
import { CostEventObjectTypeSettingsPage } from "../erp/common/costEvents/CostEventObjectTypeSettingsPage.tsx";
import {
	SitePartTestConfiguratorView,
	SitePartTestConfiguratorViewProps,
} from "src/components/views/erp/configurator/configuratorTesting/sitePart/SitePartTestConfiguratorView.tsx";
import { CustomerOrdersPage } from "../erp/sales/customerOrder/CustomerOrdersPage.tsx";
import {
	CustomerOrderLineConfiguratorView,
	CustomerOrderLineConfiguratorViewProps,
} from "src/components/views/erp/configurator/configuratorForm/impl/CustomerOrderLineConfiguratorView.tsx";
import { CustomerOrderOfferLineConfiguratorViewProps } from "../erp/configurator/configuratorForm/impl/CustomerOrderOfferLineConfiguratorView.tsx";
import { CustomerOrderOfferLineConfiguratorView } from "src/components/views/erp/configurator/configuratorForm/impl/CustomerOrderOfferLineConfiguratorView.tsx";
import { ShopOrderLineConfiguratorViewProps } from "../erp/configurator/configuratorForm/impl/ShopOrderLineConfiguratorView.tsx";
import { ShopOrderLineConfiguratorView } from "src/components/views/erp/configurator/configuratorForm/impl/ShopOrderLineConfiguratorView.tsx";
import {
	PurchaseOrderLineConfiguratorView,
	PurchaseOrderLineConfiguratorViewProps,
} from "src/components/views/erp/configurator/configuratorForm/impl/PurchaseOrderLineConfiguratorView.tsx";
import { ProjectActivityMaterialConfiguratorView } from "../erp/configurator/configuratorForm/impl/ProjectActivityMaterialConfiguratorView.tsx";
import { ProjectActivityMaterialConfiguratorViewProps } from "src/components/views/erp/configurator/configuratorForm/impl/ProjectActivityMaterialConfiguratorView.tsx";
import {
	PartConfigurationInspectingView,
	PartConfigurationInspectingViewProps,
} from "src/components/views/erp/configurator/inspecting/PartConfigurationInspectingView.tsx";
import { CostEventObjectType } from "src/api/generated/erp/db/types/enums/costEventObjectType.ts";
import { AllInventoryLevelEventsDataGrid } from "src/components/views/erp/warehouse/inventory/AllInventoryLevelEventsDataGrid.tsx";
import { AllIncompleteCustomerOrdersSalesConditionsPage } from "src/components/views/erp/sales/customerOrder/CustomerOrdersSalesConditionManagementPage.tsx";
import { SitePartsPage } from "src/components/views/erp/parts/siteParts/SitePartsPage.tsx";
import { AllSalesPartsView } from "src/components/views/erp/sales/basedata/salesParts/AllSalesPartsView.tsx";
import { CustomerOrderOffersPage } from "src/components/views/erp/sales/customerOrderOffer/CustomerOrderOffersPage.tsx";
import { DeliverableCustomerOrdersDataGrid } from "src/components/views/erp/sales/delivery/DeliverableCustomerOrdersDataGrid.tsx";
import { PurchaseOrdersView } from "src/components/views/erp/purchase/purchaseOrders/PurchaseOrdersView.tsx";
import { AllPurchaseOrderLinesView } from "src/components/views/erp/purchase/purchaseOrders/AllPurchaseOrderLinesView.tsx";
import { PurchaseOrdersToReceiveDataGrid } from "src/components/views/erp/purchase/reception/PurchaseOrdersToReceiveDataGrid.tsx";
import { CatalogPartsPage } from "src/components/views/erp/parts/catalogPart/CatalogPartsPage.tsx";
import { ControlChartParentGroupInspectingView } from "src/components/views/spc/controlChart/parentGroupInspectingView/ControlChartParentGroupInspectingView.tsx";
import { UserControlChartParentGroupsApi } from "src/api/generated/spc/controlChart/api/userControlChartParentGroupsApi.ts";
import { ControlChartDataInputForm } from "src/components/views/spc/controlChart/dataInput/ControlChartDataInputForm.tsx";
import { CostEventForm } from "src/components/views/erp/common/costEvents/CostEventForm.tsx";
import { ProjectActivityJobQueueView } from "src/components/views/erp/project/activityJobQueue/ProjectActivityJobQueueView.tsx";
import { VatCodesDataGrid } from "src/components/views/erp/sales/basedata/vatCodes/VatCodesDataGrid.tsx";
import { ControlChartsManagementView } from "src/components/views/spc/basedata/controlCharts/ControlChartsManagementView.tsx";
import { ResourcesManagementView } from "src/components/views/spc/basedata/resources/ResourcesManagementView.tsx";
import { ProjectTreeViewContainer } from "src/components/views/erp/project/treeView/ProjectTreeViewContainer.tsx";
import { ProjectsContainerView } from "src/components/views/erp/project/ProjectsContainerView.tsx";
import { ShopOrdersExtendedDocumentsView } from "src/components/views/erp/production/shopOrder/ShopOrderExtendedDocumentsView.tsx";
import { AllCustomerOrderDeliveriesDataGrid } from "src/components/views/erp/sales/delivery/AllCustomerOrderDeliveriesDataGrid.tsx";
import { EInvoiceOperatorsDataGrid } from "src/components/views/erp/sales/basedata/eInvoiceOperators/EInvoiceOperatorsDataGrid.tsx";
import { AccountingManagingView } from "src/components/views/erp/sales/accounting/managing/AccountingManagingView.tsx";
import { CustomerGroupsDataGrid } from "src/components/views/erp/sales/basedata/customerGroups/CustomerGroupsDataGrid.tsx";
import { SalesPartCategoriesDataGrid } from "src/components/views/erp/sales/basedata/salesPartCategories/SalesPartCategoriesDataGrid.tsx";
import { BillingPlanModelsView } from "src/components/views/erp/sales/billingPlan/model/BillingPlanModelsView.tsx";
import { CustomerOrderTypesDataGrid } from "src/components/views/erp/sales/basedata/customerOrderTypes/CustomerOrderTypesDataGrid.tsx";
import { ShopOrdersView } from "src/components/views/erp/production/shopOrder/ShopOrdersView.tsx";
import { WorkcenterJobQueueView } from "src/components/views/erp/production/jobQueue/WorkcenterJobQueueView.tsx";
import { ProductionLineJobQueueView } from "src/components/views/erp/production/jobQueue/ProductionLineJobQueueView.tsx";
import { ShopOrderBomReservationForm } from "src/components/views/erp/production/shopOrderPicking/ShopOrderBomReservationForm.tsx";
import { ShopOrderBatchBomReservationForm } from "src/components/views/erp/production/shopOrderPicking/ShopOrderBatchBomReservationForm.tsx";
import { SalesPriceListsView } from "src/components/views/erp/sales/pricing/salesPriceLists/SalesPriceListsView.tsx";
import { MultipleProjectsCostEventsDataGrid } from "src/components/views/erp/project/project/MultipleProjectsCostEventsDataGrid.tsx";
import { SalespersonsDataGrid } from "src/components/views/erp/sales/basedata/salespersons/SalespersonsDataGrid.tsx";
import { ShopOrderBomReserveAndPickView } from "src/components/views/erp/production/shopOrderPicking/ShopOrderBomReserveAndPickView.tsx";
import { SalesCommissionUnapprovedLinesDataGrid } from "src/components/views/erp/sales/salesCommissions/SalesCommissionUnapprovedLinesDataGrid.tsx";
import { SalesCommissionPaymentCreationView } from "src/components/views/erp/sales/salesCommissions/SalesCommissionPaymentCreationView.tsx";
import { SalesCommissionPaymentsView } from "src/components/views/erp/sales/salesCommissions/paymentsView/SalesCommissionPaymentsView.tsx";
import { WarehouseTransferDataGrid } from "src/components/views/erp/warehouse/transfer/WarehouseTransferDataGrid.tsx";
import { ConfiguratorLibrariesView } from "src/components/views/erp/configurator/managing/libraries/ConfiguratorLibrariesView.tsx";
import { ConfigurationProductFamiliesView } from "src/components/views/erp/configurator/managing/productFamilies/ConfigurationProductFamiliesView.tsx";
import { WarehouseTransferJobQueueView } from "src/components/views/erp/warehouse/transfer/WarehouseTransferJobQueueView.tsx";
import { SalesCommissionLineNamesDataGrid } from "src/components/views/erp/sales/basedata/salesCommissions/SalesCommissionLineNamesDataGrid.tsx";
import { CustomerOrderBillingPlanLinesToApproveDataGrid } from "src/components/views/erp/sales/billingPlan/customerOrder/CustomerOrderBillingPlanLinesToApproveDataGrid.tsx";
import { ProjectActivityCostEventForm } from "src/components/views/erp/project/projectActivity/ProjectActivityCostEventForm.tsx";
import { faCubes } from "@fortawesome/pro-solid-svg-icons";
import { ShopOrderBatchesView } from "src/components/views/erp/production/shopOrderBatch/ShopOrderBatchesView.tsx";
import { ShopOrderBatchJobQueueMobileView } from "src/components/views/erp/production/shopOrderBatch/shopOrderBatchJobQueue/mobile/ShopOrderBatchJobQueueMobileView.tsx";
import { ProductionLinesView } from "src/components/views/erp/production/baseData/productionLines/ProductionLinesView.tsx";
import { ProductionLineGroupJobQueueView } from "src/components/views/erp/production/jobQueue/ProductionLineGroupJobQueueView.tsx";
import { CustomersView } from "src/components/views/erp/sales/customer/CustomersView.tsx";
import { SurveyFormsView } from "src/components/views/erp/surveys/management/SurveyFormsView.tsx";
import { OperationsDataGrid } from "src/components/views/erp/production/baseData/operations/OperationsDataGrid.tsx";
import { SurveyCategoriesDataGrid } from "src/components/views/erp/surveys/management/categories/SurveyCategoriesDataGrid.tsx";
import { SurveySubmissionsToAuditDataGrid } from "src/components/views/erp/surveys/auditing/SurveySubmissionsToAuditDataGrid.tsx";
import { ProjectActivityTypesDataGrid } from "src/components/views/erp/project/projectActivity/activityTypes/ProjectActivityTypesDataGrid.tsx";
import { InventoryLevelProfileView } from "src/components/views/erp/warehouse/inventory/InventoryLevelProfileView.tsx";
import { WorkspaceCategoriesDataGrid } from "src/components/views/workspaces/WorkspaceCategoriesDataGrid.tsx";
import { DeliveryPackageTypesDataGrid } from "src/components/views/erp/warehouse/basedata/packageTypes/CustomerOrderPackageTypesDataGrid.tsx";
import { PickableProjectsDataGrid } from "src/components/views/erp/project/delivery/picking/PickableProjectsDataGrid.tsx";
import { DeliverableProjectsDataGrid } from "src/components/views/erp/project/delivery/DeliverableProjectsDataGrid.tsx";
import { AllProjectDeliveryPackagesPage } from "src/components/views/erp/project/delivery/AllProjectDeliveryPackagesPage.tsx";
import { AllCustomerOrderDeliveryPackagesView } from "src/components/views/erp/sales/delivery/AllCustomerOrderDeliveryPackagesView.tsx";
import { AllUndeliveredCustomerOrderPackagesView } from "src/components/views/erp/sales/delivery/AllUndeliveredCustomerOrderPackagesView.tsx";
import { AllUndeliveredProjectPackagesView } from "src/components/views/erp/project/delivery/AllUndeliveredProjectPackagesView.tsx";
import { AllProjectDeliveriesDataGrid } from "src/components/views/erp/project/delivery/AllProjectDeliveriesDataGrid.tsx";
import { AllCustomerOrderLinesDataGrid } from "src/components/views/erp/sales/customerOrderLine/AllCustomerOrderLinesDataGrid.tsx";
import { ConfigurationProductFamilyCategoriesDataGrid } from "src/components/views/erp/configurator/managing/productFamilyCategories/ConfigurationProductFamilyCategoriesDataGrid.tsx";
import { ConfiguratorTablesView } from "src/components/views/erp/configurator/tables/ConfiguratorTablesView.tsx";
import { PaymentTermsDataGrid } from "src/components/views/erp/sales/basedata/paymentTerms/PaymentTermsDataGrid.tsx";
import { WorkspacesView } from "src/components/views/workspaces/WorkspacesView.tsx";
import { EffectiveTimeMeasuringView } from "src/components/views/spc/controlChart/dataInput/EffectiveTimeMeasuringView.tsx";
import { CycleTimeMeasuringView } from "src/components/views/spc/controlChart/dataInput/CycleTimeMeasuringView.tsx";
import { SALVOS_VIEWS_BY_KEYS } from "src/tenantCustomizations/tenants/salvos/salvosViews.ts";
import { ProductionLineStatusView } from "src/components/views/erp/production/productionLineStatus/ProductionLineStatusView.tsx";
import {
	ProductionLineStatusViewApi
} from "src/api/generated/erp/production/productionLineStatus/api/productionLineStatusViewApi.ts";

export function defineStaticView(details: Omit<StaticFrontendViewConfig, "type">): StaticFrontendViewConfig {
	return {
		type: "static",
		...details,
	};
}

export function defineModalView<Props>(
	details: Omit<ModalFrontendViewConfig<Props>, "type">,
): ModalFrontendViewConfig<Props> {
	return {
		type: "modal",
		...details,
	};
}

export function defineGeneratorView<Props>(
	details: Omit<GeneratorFrontendViewConfig<Props>, "type">,
): GeneratorFrontendViewConfig<Props> {
	return {
		type: "generator",
		...details,
	};
}

export const FRONTEND_VIEWS_BY_KEYS: Record<string, FrontendViewConfig<any>> = {
	...{
		devTestView: defineStaticView({
			component: TestPage,
			icon: faVial,
			title: i18n.t("test"),
			url: "/devTest",
		}),
		erpUnitsDataTableView: defineStaticView({
			component: ErpUnitsDataGrid,
			title: i18n.t("units"),
			icon: faShapes,
			url: "/productManagement/units",
		}),
		warehouseLocationsPage: defineStaticView({
			component: WarehouseLocationsDataGrid,
			title: i18n.t("warehouse_locations"),
			icon: faWarehouse,
			url: "/warehouse/warehouseLocations",
		}),
		companiesDataTableView: defineStaticView({
			component: CompaniesDataGrid,
			title: i18n.t("companies"),
			icon: faIndustry,
			url: "/common/companies",
		}),
		sitesPage: defineStaticView({
			component: SitesDataGrid,
			title: i18n.t("sites"),
			icon: faMapPin,
			url: "/common/sites",
		}),
		partCategoryManagementView: defineStaticView({
			component: PartCategoriesPage,
			title: i18n.t("part_categories"),
			icon: faBookmark,
			url: "/productManagement/partCategories",
		}),
		numberSequencesPage: defineStaticView({
			component: NumberSequencesDataGrid,
			title: i18n.t("number_sequences"),
			icon: faSortNumericDown,
			url: "/common/numberSequences",
		}),
		purchaseRequestsPage: defineStaticView({
			component: PurchaseRequestsDataGrid,
			title: i18n.t("purchase_requests"),
			icon: faShoppingCart,
			url: "/purchase/purchaseRequests",
		}),
		purchaseRequestsModalView: defineModalView({
			component: PurchaseRequestsDataGrid,
			title: i18n.t("purchase_requests"),
			mapViewParamsToProps: (params) => ({
				onlyPurchaseRequestId: Number(
					resolveHandlebarsTemplate(params["onlyPurchaseRequestId"]!.toString(), params),
				),
			}),
		}),
		systemDiagramsManagement: defineStaticView({
			component: SystemDiagramsManagementPage,
			title: i18n.t("system_diagrams"),
			icon: faDiagramSubtask,
			url: "/common/systemDiagrams",
		}),
		documentSearchPage: defineStaticView({
			component: DocumentSearchPage,
			title: i18n.t("documents"),
			icon: faFolderOpen,
			url: "/documents/search",
		}),
		documentsOfObjectView: defineModalView({
			component: DocumentsOfSingleObjectView,
			title: i18n.t("documents"),
			modalSize: "fullscreen",
			mapViewParamsToProps: (params) => {
				const objectId = params["objectId"]!.toString();
				const objectTypeStr = params["objectType"] as string;
				const readonly = params["readonly"] === true;
				return {
					objectRef: {
						objectType: resolveHandlebarsTemplate(objectTypeStr, params) as AavoObjectType,
						objectId: Number(resolveHandlebarsTemplate(objectId, params)),
					},
					editable: !readonly,
				};
			},
		}),
		pickableCustomersOrdersDataGrid: defineStaticView({
			component: PickableCustomersOrdersDataGrid,
			title: i18n.t("pickable_customer_orders"),
			icon: faBoxOpen,
			url: "/sales/picking/pickableCustomerOrders",
		}),
		pickableCustomersOrdersDataGridModal: defineModalView({
			component: PickableCustomersOrdersDataGrid,
			title: i18n.t("pickable_customer_orders"),
			mapViewParamsToProps: () => ({}),
		}),
		warehousePickerGroupsPage: defineStaticView({
			component: WarehousePickerGroupsDataGrid,
			title: i18n.t("picker_groups"),
			icon: faUserFriends,
			url: "/erp/warehouse/warehousePickerGroups",
		}),
		allInvoicesPage: defineStaticView({
			component: AllInvoicesDataGrid,
			title: i18n.t("all_invoices"),
			icon: faWallet,
			url: "/erp/sales/invoicing/allInvoices",
		}),
		billableCustomerOrdersPage: defineStaticView({
			component: BillableCustomerOrdersDataGrid,
			title: i18n.t("billable_customer_orders"),
			icon: faReceipt,
			url: "/erp/sales/invoicing/billableCustomerOrders",
		}),
		billableCustomerOrdersPageModal: defineModalView({
			component: BillableCustomerOrdersDataGrid,
			title: i18n.t("billable_customer_orders"),
			mapViewParamsToProps: () => ({}),
		}),
		cargoDriversDataGrid: defineStaticView({
			component: CargoDriversDataGrid,
			title: i18n.t("cargo_drivers"),
			icon: faTruckMoving,
			url: "/erp/warehouse/cargoDrivers",
		}),
		deliveryTermsDataGrid: defineStaticView({
			component: DeliveryTermsDataGrid,
			title: i18n.t("delivery_terms"),
			icon: faFileSignature,
			url: "/erp/warehouse/deliveryTerms",
		}),
		deliveryMethodDataGrid: defineStaticView({
			component: DeliveryMethodDataGrid,
			title: i18n.t("delivery_methods"),
			icon: faTrailer,
			url: "/erp/warehouse/deliveryMethods",
		}),
		reclamationCausesPage: defineStaticView({
			component: ReclamationCausesDataGrid,
			title: i18n.t("reclamation_causes"),
			icon: faViruses,
			url: "/erp/reclamations/reclamationCause",
		}),
		customerOrderImportPage: defineStaticView({
			component: CustomerOrderImportPage,
			title: i18n.t("import_customer_orders"),
			icon: faFileImport,
			url: "/erp/sales/customerOrderImport",
		}),
		reclamationCategoriesPage: defineStaticView({
			component: ReclamationCategoriesDataGrid,
			title: i18n.t("reclamation_categories"),
			icon: faShapes,
			url: "/erp/reclamations/reclamationCategory",
		}),
		allReclamationsPage: defineStaticView({
			component: AllReclamationsPage,
			title: i18n.t("reclamations"),
			icon: faFlag,
			url: "/erp/reclamations/reclamations",
		}),
		singleReclamationView: defineModalView({
			component: SingleReclamationView,
			title: i18n.t("reclamation"),
			mapViewParamsToProps: (params) => {
				const reclamationId = params["selectedReclamationId"]!.toString();
				return { reclamationId: Number(resolveHandlebarsTemplate(reclamationId, params)) };
			},
		}),
		tasksAssignedToMePage: defineStaticView({
			component: TasksAssignedToMePage,
			title: i18n.t("own_tasks"),
			icon: faThumbtack,
			url: "/tasks/my",
		}),
		tasksOnMyResponsibilityPage: defineStaticView({
			component: TasksOnMyResponsibilityPage,
			title: i18n.t("on_my_responsibility"),
			icon: faListAlt,
			url: "/tasks/onMyResponsibility",
		}),
		allTasksPage: defineStaticView({
			component: AllTasksPage,
			title: i18n.t("all_tasks"),
			icon: faClipboardList,
			url: "/tasks/allTasks",
		}),
		costEventItemPage: defineStaticView({
			component: CostEventItemsDataGrid,
			title: i18n.t("items"),
			icon: faIconsAlt,
			url: "/erp/common/costEvents",
		}),
		costEventPriceListPage: defineStaticView({
			component: CostEventPriceListPage,
			title: i18n.t("price_lists"),
			icon: faListDots,
			url: "/erp/common/costEventPriceLists",
		}),
		cChartEventCollectionPages: defineGeneratorView({
			baseUrl: `/spc/collectCChartEvents/controlChartGroup/`,
			icon: faTable,
			component: CChartEventCollectionView,
			generate: async () => {
				const chartGroups = await CChartEventCollectionApi.getUserEventCollectionChartGroups();
				return chartGroups.map((chartGroup) => ({
					generatedKey: chartGroup.chartGroupId.toString(),
					title: chartGroup.name,
					props: {
						controlChartGroupId: chartGroup.chartGroupId,
					},
				}));
			},
		}),
		cChartEventSearchPage: defineStaticView({
			component: CChartEventSearchPage,
			title: i18n.t("events"),
			icon: faMagnifyingGlass,
			url: "/spc/cChartEvents/search",
		}),
		allCustomerOrderPackagesPage: defineStaticView({
			component: AllCustomerOrderDeliveryPackagesView,
			title: i18n.t("all_packages"),
			icon: faBoxTissue,
			url: "/sales/picking/allDeliveryPackages",
		}),
		undeliveredCustomerOrderPackagesPage: defineStaticView({
			component: AllUndeliveredCustomerOrderPackagesView,
			title: i18n.t("undelivered_packages"),
			icon: faBox,
			url: "/sales/picking/undeliveredPackages",
		}),
		manualInventoryEventButtonsPage: defineStaticView({
			component: ManualInventoryActionsView,
			title: i18n.t("warehouse_actions"),
			icon: faClipboardList,
			url: "/warehouse/inventory/manualEvents",
		}),
		customerOrderContactInfoView: defineModalView({
			component: CustomerOrderContactInfoView,
			title: i18n.t("customer_order"),
			modalSize: "md",
			mapViewParamsToProps: (params) => {
				const customerOrderId = params["customerOrderId"]!.toString();
				return {
					customerOrderId: Number(resolveHandlebarsTemplate(customerOrderId, params)),
				};
			},
		}),
		costEventObjectTypeSettingsPage: defineStaticView({
			component: CostEventObjectTypeSettingsPage,
			title: i18n.t("settings"),
			icon: faCogs,
			url: "/erp/common/costEventObjectTypeSettings",
		}),
		customerOrdersPage: defineStaticView({
			component: CustomerOrdersPage,
			title: i18n.t("customer_orders"),
			icon: faCheckDouble,
			url: "/sales/customerOrders",
		}),
		customerOrdersModalView: defineModalView({
			component: CustomerOrdersPage,
			title: i18n.t("customer_orders"),
			mapViewParamsToProps: (params) => {
				return {
					onlyCustomerOrderToShowId: Number(
						resolveHandlebarsTemplate(params["onlyCustomerOrderToShowId"]!.toString(), params),
					),
				};
			},
		}),
		sitePartTestConfiguratorView: defineModalView<SitePartTestConfiguratorViewProps>({
			component: SitePartTestConfiguratorView,
			title: i18n.t("test_configuration"),
			mapViewParamsToProps: (params, closeModal) => {
				return {
					sitePartRevisionId: Number(
						resolveHandlebarsTemplate(params["sitePartRevisionId"]!.toString(), params),
					),
					closeDialog: closeModal,
				};
			},
		}),
		customerOrderLineConfiguratorView: defineModalView<CustomerOrderLineConfiguratorViewProps>({
			component: CustomerOrderLineConfiguratorView,
			title: i18n.t("configuration"),
			mapViewParamsToProps: (params, closeModal, refreshSourceView) => {
				return {
					customerOrderLineId: Number(
						resolveHandlebarsTemplate(params["customerOrderLineId"]!.toString(), params),
					),
					closeDialog: async () => {
						closeModal();
					},
					refreshSourceView: refreshSourceView,
				};
			},
		}),
		customerOrderLineBeforeCreateConfiguratorView: defineModalView<CustomerOrderLineConfiguratorViewProps>({
			component: CustomerOrderLineConfiguratorView,
			title: i18n.t("configuration"),
			mapViewParamsToProps: (params, closeModal, refreshSourceView) => {
				return {
					customerOrderLineId: Number(
						resolveHandlebarsTemplate(params["customerOrderLineId"]!.toString(), params),
					),
					closeDialog: async () => {
						closeModal();
					},
					refreshSourceView: refreshSourceView,
				};
			},
		}),
		customerOrderOfferLineConfiguratorView: defineModalView<CustomerOrderOfferLineConfiguratorViewProps>({
			component: CustomerOrderOfferLineConfiguratorView,
			title: i18n.t("configuration"),
			mapViewParamsToProps: (params, closeModal, refreshSourceView) => {
				return {
					customerOrderOfferLineId: Number(
						resolveHandlebarsTemplate(params["customerOrderOfferLineId"]!.toString(), params),
					),
					closeDialog: async () => {
						closeModal();
					},
					refreshSourceView: refreshSourceView,
				};
			},
		}),
		shopOrderLineConfiguratorView: defineModalView<ShopOrderLineConfiguratorViewProps>({
			component: ShopOrderLineConfiguratorView,
			title: i18n.t("configuration"),
			mapViewParamsToProps: (params, closeModal, refreshSourceView) => {
				return {
					shopOrderLineId: Number(resolveHandlebarsTemplate(params["shopOrderLineId"]!.toString(), params)),
					closeDialog: async () => {
						closeModal();
					},
					refreshSourceView: refreshSourceView,
				};
			},
		}),
		purchaseOrderLineConfiguratorView: defineModalView<PurchaseOrderLineConfiguratorViewProps>({
			component: PurchaseOrderLineConfiguratorView,
			title: i18n.t("configuration"),
			mapViewParamsToProps: (params, closeModal, refreshSourceView) => {
				return {
					purchaseOrderLineId: Number(
						resolveHandlebarsTemplate(params["purchaseOrderLineId"]!.toString(), params),
					),
					closeDialog: async () => {
						closeModal();
					},
					refreshSourceView: refreshSourceView,
				};
			},
		}),
		projectActivityMaterialConfiguratorView: defineModalView<ProjectActivityMaterialConfiguratorViewProps>({
			component: ProjectActivityMaterialConfiguratorView,
			title: i18n.t("configuration"),
			mapViewParamsToProps: (params, closeModal, refreshSourceView) => {
				return {
					activityMaterialLineId: Number(
						resolveHandlebarsTemplate(params["activityMaterialLineId"]!.toString(), params),
					),
					closeDialog: async () => {
						closeModal();
					},
					refreshSourceView: refreshSourceView,
				};
			},
		}),
		partConfigurationInspectingView: defineModalView<PartConfigurationInspectingViewProps>({
			component: PartConfigurationInspectingView,
			title: i18n.t("configuration"),
			mapViewParamsToProps: (params) => {
				console.log(params);
				return {
					partConfigurationId: Number(params["partConfigurationId"]!.toString()),
					showBom: params["showBom"] === true,
				};
			},
		}),
		customerOrderSalesConditionsOverviewView: defineStaticView({
			component: AllIncompleteCustomerOrdersSalesConditionsPage,
			title: i18n.t("customer_orders_sales_conditions"),
			icon: faSignature,
			url: "/sales/customerOrdersSalesConditions",
		}),
		sitePartPage: defineStaticView({
			component: SitePartsPage,
			title: i18n.t("parts"),
			icon: faSortNumericDown,
			url: "/warehouse/parts",
		}),
		salesPartsOverViewPage: defineStaticView({
			component: AllSalesPartsView,
			title: i18n.t("sales_parts"),
			icon: faSwatchbook,
			url: "/sales/salesParts",
		}),
		customerOrderOffersPage: defineStaticView({
			component: CustomerOrderOffersPage,
			title: i18n.t("offers"),
			icon: faCommentsDollar,
			url: "/sales/offers",
		}),
		customerOrderOffersModalView: defineModalView({
			component: CustomerOrderOffersPage,
			title: i18n.t("offers"),
			mapViewParamsToProps: (params) => {
				return {
					onlyCustomerOrderOfferToShowId: Number(
						resolveHandlebarsTemplate(params["onlyCustomerOrderOfferToShowId"]!.toString(), params),
					),
				};
			},
		}),
		deliverableCustomerOrdersView: defineStaticView({
			component: DeliverableCustomerOrdersDataGrid,
			title: i18n.t("deliverable_orders"),
			icon: faWarehouse,
			url: "/sales/delivery/deliverableCustomerOrders",
		}),
		deliverableCustomerOrdersModalView: defineModalView({
			component: DeliverableCustomerOrdersDataGrid,
			title: i18n.t("deliverable_orders"),
			mapViewParamsToProps: () => ({}),
		}),
		purchaseOrdersPage: defineStaticView({
			component: PurchaseOrdersView,
			title: i18n.t("purchase_orders"),
			url: "/purchase/purchaseOrders",
			icon: faStore,
		}),
		purchaseOrdersModalView: defineModalView({
			component: PurchaseOrdersView,
			title: i18n.t("purchase_order"),
			mapViewParamsToProps: (params) => ({
				onlyPurchaseOrderId: Number(
					resolveHandlebarsTemplate(params["onlyPurchaseOrderId"]!.toString(), params),
				),
			}),
		}),
		purchaseOrdersToReceivePage: defineStaticView({
			component: PurchaseOrdersToReceiveDataGrid,
			title: i18n.t("reception"),
			url: "/purchase/purchaseOrdersToReceive",
			icon: faConciergeBell,
		}),
		purchaseOrdersToReceiveModalView: defineModalView({
			component: PurchaseOrdersToReceiveDataGrid,
			title: i18n.t("reception"),
			mapViewParamsToProps: () => ({}),
		}),
		allPurchaseOrderLinesPage: defineStaticView({
			component: AllPurchaseOrderLinesView,
			title: i18n.t("all_purchase_order_lines"),
			url: "/purchase/allPurchaseOrderLines",
			icon: faThList,
		}),
		catalogPartsPage: defineStaticView({
			component: CatalogPartsPage,
			title: i18n.t("part_catalog"),
			icon: faFolder,
			url: "/productManagement/catalogParts",
		}),
		controlChartParentGroupsGeneratedPages: defineGeneratorView({
			baseUrl: `/spc/controlChartParentGroup/`,
			icon: faChartLine,
			component: ControlChartParentGroupInspectingView,
			generate: async () => {
				const chartParentGroups = await UserControlChartParentGroupsApi.getUserControlChartParentGroups();
				return chartParentGroups.map((parentGroup) => ({
					generatedKey: parentGroup.id.toString(),
					title: parentGroup.name,
					props: {
						parentGroupId: parentGroup.id,
					},
				}));
			},
		}),
		controlChartDataInputFormModal: defineModalView({
			component: ControlChartDataInputForm,
			title: i18n.t("input_data"),
			modalSize: "sm",
			mapViewParamsToProps: (params, _, refreshSourceView) => ({
				controlChartId: Number(resolveHandlebarsTemplate(params["control_chart_id"]!.toString(), params)),
				onCompleted: () => {
					refreshSourceView();
				},
			}),
		}),
		costEventForm: defineModalView({
			component: CostEventForm,
			title: i18n.t("cost_event"),
			modalSize: "md",
			mapViewParamsToProps: (params, closeModal, refreshSourceView) => ({
				newCostEventObjectRef: {
					objectType: params["objectType"] as CostEventObjectType,
					objectId: Number(resolveHandlebarsTemplate(params["objectId"]!.toString(), params)),
				},
				costEventPriceListId: Number(
					resolveHandlebarsTemplate(params["costEventPriceListId"]!.toString(), params),
				),
				costEventId:
					params["costEventId"] ?
						Number(resolveHandlebarsTemplate(params["costEventId"]!.toString(), params))
					:	undefined,
				onCompleted: () => {
					closeModal();
					refreshSourceView();
				},
			}),
		}),
		projectActivityJobQueuePage: defineStaticView({
			component: ProjectActivityJobQueueView,
			title: i18n.t("job_queue"),
			icon: faUserMd,
			url: "/project/jobQueue",
		}),
		vatCodesPage: defineStaticView({
			component: VatCodesDataGrid,
			title: i18n.t("vat_codes"),
			icon: faPercent,
			url: "/sales/vatCodes",
		}),
		controlChartsPage: defineStaticView({
			component: ControlChartsManagementView,
			title: i18n.t("control_charts"),
			icon: faBorderAll,
			url: "/spc/controlCharts",
		}),
		resourcesPage: defineStaticView({
			component: ResourcesManagementView,
			title: i18n.t("resources"),
			icon: faBezierCurve,
			url: "/spc/resources",
		}),
		projectTreeViewPage: defineStaticView({
			component: ProjectTreeViewContainer,
			title: i18n.t("tree_view"),
			icon: faSitemap,
			url: "/project/tree",
		}),
		projectTreeViewModalView: defineModalView({
			component: ProjectTreeViewContainer,
			title: i18n.t("tree_view"),
			mapViewParamsToProps: (params) => ({
				onlyProjectId: Number(resolveHandlebarsTemplate(params["_projectIdFilter"]!.toString(), params)),
			}),
		}),
		projectsPage: defineStaticView({
			component: ProjectsContainerView,
			title: i18n.t("projects"),
			icon: faProjectDiagram,
			url: "/project/projects",
		}),
		projectsPageModalView: defineModalView({
			component: ProjectsContainerView,
			title: i18n.t("projects"),
			mapViewParamsToProps: (params) => ({
				onlyProjectId: Number(resolveHandlebarsTemplate(params["_projectIdFilter"]!.toString(), params)),
			}),
		}),
		shopOrdersExtendedDocumentsViewModal: defineModalView({
			component: ShopOrdersExtendedDocumentsView,
			title: i18n.t("documents"),
			mapViewParamsToProps: (params) => ({
				shopOrderIds: JSON.parse(params["shopOrders"] as string).map((shopOrder: any) => shopOrder.shopOrderId),
			}),
		}),
		allCustomerOrderDeliveriesPage: defineStaticView({
			component: AllCustomerOrderDeliveriesDataGrid,
			title: i18n.t("all_deliveries"),
			icon: faAngleDoubleRight,
			url: "/sales/delivery/allCustomerOrderDeliveries",
		}),
		eInvoiceOperatorsPage: defineStaticView({
			component: EInvoiceOperatorsDataGrid,
			title: i18n.t("e_invoice_operators"),
			icon: faHandshakeAngle,
			url: "/sales/eInvoiceOperators",
		}),
		accountingManagingPage: defineStaticView({
			component: AccountingManagingView,
			title: i18n.t("accounting"),
			icon: faUniversity,
			url: "/sales/accounting/manage",
		}),
		customerGroupsPage: defineStaticView({
			component: CustomerGroupsDataGrid,
			title: i18n.t("customer_groups"),
			icon: faObjectGroup,
			url: "/sales/customerGroups",
		}),
		salesPartCategoriesPage: defineStaticView({
			component: SalesPartCategoriesDataGrid,
			title: i18n.t("sales_part_categories"),
			icon: faArchive,
			url: "/sales/salesPartCategories",
		}),
		billingPlanModelsPage: defineStaticView({
			component: BillingPlanModelsView,
			title: i18n.t("billing_plan_models"),
			icon: faMoneyBillAlt,
			url: "/sales/billingPlanModels",
		}),
		customerOrderTypesPage: defineStaticView({
			component: CustomerOrderTypesDataGrid,
			title: i18n.t("customer_order_types"),
			icon: faSuperscript,
			url: "/sales/customerOrderTypes",
		}),
		workCenterJobQueuePage: defineStaticView({
			component: WorkcenterJobQueueView,
			title: i18n.t("work_center_job_queue"),
			icon: faUserMd,
			url: "/production/workCenterJobQueue",
		}),
		productionLineJobQueuePage: defineStaticView({
			component: ProductionLineJobQueueView,
			title: i18n.t("production_line_job_queue"),
			icon: faBacon,
			url: "/production/productionLineJobQueue",
		}),
		productionLineGroupJobQueuePage: defineStaticView({
			component: ProductionLineGroupJobQueueView,
			title: i18n.t("production_line_group_job_queue"),
			icon: faIndustry,
			url: "/production/productionLineGroupJobQueue",
		}),
		shopOrdersPage: defineStaticView({
			component: ShopOrdersView,
			title: i18n.t("shop_orders"),
			icon: faFileCode,
			url: "/production/shopOrders",
		}),
		shopOrdersPageModal: defineModalView({
			component: ShopOrdersView,
			title: i18n.t("shop_orders"),
			mapViewParamsToProps: (params) => {
				console.log(params);
				return {
					onlyShopOrderId: Number(resolveHandlebarsTemplate(params["shopOrderId"]!.toString(), params)),
				};
			},
		}),
		shopOrderBomReservationFormModal: defineModalView({
			component: ShopOrderBomReservationForm,
			title: i18n.t("reserve"),
			modalSize: "md",
			mapViewParamsToProps: (params, closeModal, refreshSourceView) => {
				return {
					shopOrderId: Number(resolveHandlebarsTemplate(params["shopOrderId"]!.toString(), params)),
					onCompleted: () => {
						closeModal();
						refreshSourceView();
					},
				};
			},
		}),
		shopOrderBatchBomReservationFormModal: defineModalView({
			component: ShopOrderBatchBomReservationForm,
			title: i18n.t("reserve"),
			modalSize: "md",
			mapViewParamsToProps: (params, closeModal, refreshSourceView) => {
				return {
					shopOrderBatchId: Number(resolveHandlebarsTemplate(params["shopOrderBatchId"]!.toString(), params)),
					onCompleted: () => {
						closeModal();
						refreshSourceView();
					},
				};
			},
		}),
		salesPriceListsView: defineStaticView({
			component: SalesPriceListsView,
			title: i18n.t("price_lists"),
			icon: faMoneyBillWave,
			url: "/sales/pricing/priceLists",
		}),
		multipleProjectsCostEventsView: defineStaticView({
			component: MultipleProjectsCostEventsDataGrid,
			title: i18n.t("cost_events"),
			icon: faMoneyBillWave,
			url: "/project/costEvents",
		}),
		salespersonsView: defineStaticView({
			component: SalespersonsDataGrid,
			title: i18n.t("salespersons"),
			icon: faUserTie,
			url: "/sales/salespersons",
		}),
		shopOrderBomReserveAndPickViewModal: defineModalView({
			component: ShopOrderBomReserveAndPickView,
			title: i18n.t("reserve_and_pick"),
			modalSize: "lg",
			mapViewParamsToProps: (params, closeModal, refreshSourceView) => {
				const shopOrderOperationIdTemplate = params["shopOrderOperationId"]?.toString();
				return {
					shopOrderId: Number(resolveHandlebarsTemplate(params["shopOrderId"]!.toString(), params)),
					shopOrderOperationId:
						shopOrderOperationIdTemplate ?
							Number(resolveHandlebarsTemplate(shopOrderOperationIdTemplate, params))
						:	null,
					onCompleted: () => {
						closeModal();
						refreshSourceView();
					},
				};
			},
		}),
		unapprovedSalesCommissionLinesView: defineStaticView({
			title: i18n.t("unapproved_commission_installments"),
			icon: faUp,
			component: SalesCommissionUnapprovedLinesDataGrid,
			url: "/sales/salesCommissions/unapprovedSalesCommissionLines",
		}),
		salesCommissionPaymentCreationView: defineStaticView({
			title: i18n.t("commission_payment_creation"),
			icon: faFileInvoiceDollar,
			component: SalesCommissionPaymentCreationView,
			url: "/sales/salesCommissions/createPayment",
		}),
		salesCommissionPaymentsView: defineStaticView({
			title: i18n.t("commission_payments"),
			icon: faCalculator,
			component: SalesCommissionPaymentsView,
			url: "/sales/salesCommissions/payments",
		}),
		warehouseTransferView: defineStaticView({
			title: i18n.t("warehouse_transfers"),
			icon: faPallet,
			component: WarehouseTransferDataGrid,
			url: "/warehouse/warehouseTransfers",
		}),
		configuratorLibrariesView: defineStaticView({
			title: i18n.t("libraries"),
			icon: faBooks,
			component: ConfiguratorLibrariesView,
			url: "/configurator/libraries",
		}),
		configurationProductFamiliesView: defineStaticView({
			title: i18n.t("product_families"),
			icon: faObjectGroup,
			component: ConfigurationProductFamiliesView,
			url: "/configurator/productFamilies",
		}),
		deliveryPackageTypesPage: defineStaticView({
			title: i18n.t("package_types"),
			icon: faBoxesPacking,
			component: DeliveryPackageTypesDataGrid,
			url: "/warehouse/deliveryPackageTypes",
		}),
		warehouseTransferJobQueueView: defineStaticView({
			title: i18n.t("warehouse_job_queue"),
			icon: faTruckLoading,
			component: WarehouseTransferJobQueueView,
			url: "/warehouse/transferJobQueue",
		}),
		salesCommissionLineNamesView: defineStaticView({
			title: i18n.t("commission_line_names"),
			icon: faFileSignature,
			component: SalesCommissionLineNamesDataGrid,
			url: "/sales/salesCommissions/lineNames",
		}),
		billingPlanLinesToApproveView: defineStaticView({
			title: i18n.t("unapproved_billing_plan_lines"),
			icon: faScroll,
			component: CustomerOrderBillingPlanLinesToApproveDataGrid,
			url: "/sales/billingPlanLinesToApprove",
		}),
		projectActivityCostEventView: defineStaticView({
			title: i18n.t("add_cost_event"),
			icon: faPlus,
			component: ProjectActivityCostEventForm,
			url: "/project/projectActivityCostEvents",
		}),
		shopOrderBatchesView: defineStaticView({
			title: i18n.t("shop_order_batches"),
			icon: faCubes,
			component: ShopOrderBatchesView,
			url: "/production/shopOrderBatches",
		}),
		shopOrderBatchJobQueueView: defineStaticView({
			title: i18n.t("shop_order_batch_job_queue"),
			icon: faBacon,
			component: ShopOrderBatchJobQueueMobileView,
			url: "/production/shopOrderBatchJobQueue",
		}),
		productionLinesPage: defineStaticView({
			title: i18n.t("production_lines"),
			icon: faIndustry,
			component: ProductionLinesView,
			url: "/production/productionLines",
		}),
		customersPage: defineStaticView({
			title: i18n.t("customers"),
			icon: faUsers,
			component: CustomersView,
			url: "/sales/customers",
		}),
		surveyFormManagementPage: defineStaticView({
			title: i18n.t("survey_forms"),
			icon: faPen,
			component: SurveyFormsView,
			url: "/surveys/surveyForms",
		}),
		surveyCategoriesPage: defineStaticView({
			title: i18n.t("survey_categories"),
			icon: faObjectGroup,
			component: SurveyCategoriesDataGrid,
			url: "/surveys/surveyCategories",
		}),
		operationsPage: defineStaticView({
			title: i18n.t("operations"),
			icon: faSortNumericDown,
			component: OperationsDataGrid,
			url: "/production/operations",
		}),
		surveySubmissionsToAuditPage: defineStaticView({
			title: i18n.t("surveys_to_audit"),
			icon: faCheckDouble,
			component: SurveySubmissionsToAuditDataGrid,
			url: "/surveys/submissions/audit",
		}),
		projectActivityTypesPage: defineStaticView({
			title: i18n.t("activity_types"),
			icon: faBorderStyle,
			component: ProjectActivityTypesDataGrid,
			url: "/project/activityTypes",
		}),
		inventoryLevelProfileView: defineStaticView({
			title: i18n.t("inventory_level_profile"),
			icon: faTachometerAlt,
			component: InventoryLevelProfileView,
			url: "/warehouse/inventoryLevelProfile",
		}),
		workspaceCategoriesView: defineStaticView({
			title: i18n.t("categories"),
			icon: faShapes,
			component: WorkspaceCategoriesDataGrid,
			url: "/workspace/categories",
		}),
		pickableProjectsPage: defineStaticView({
			title: i18n.t("pickable_projects"),
			icon: faBoxOpen,
			component: PickableProjectsDataGrid,
			url: "/project/delivery/pickableProjects",
		}),
		deliverableProjectsPage: defineStaticView({
			component: DeliverableProjectsDataGrid,
			title: i18n.t("deliverable_projects"),
			icon: faWarehouse,
			url: "/project/delivery/deliverableProjects",
		}),
		allProjectDeliveryPackagesPage: defineStaticView({
			component: AllProjectDeliveryPackagesPage,
			title: i18n.t("all_packages"),
			icon: faBoxTissue,
			url: "/project/delivery/allDeliveryPackages",
		}),
		undeliveredProjectPackagesPage: defineStaticView({
			component: AllUndeliveredProjectPackagesView,
			title: i18n.t("undelivered_packages"),
			icon: faBox,
			url: "/project/delivery/undeliveredPackages",
		}),
		allProjectDeliveriesPage: defineStaticView({
			component: AllProjectDeliveriesDataGrid,
			title: i18n.t("all_deliveries"),
			icon: faAngleDoubleRight,
			url: "/project/delivery/allDeliveries",
		}),
		allCustomerOrderLinesView: defineStaticView({
			component: AllCustomerOrderLinesDataGrid,
			title: i18n.t("all_customer_order_lines"),
			icon: faList12,
			url: "/sales/allCustomerOrderLines",
		}),
		configurationProductFamilyCategoriesView: defineStaticView({
			component: ConfigurationProductFamilyCategoriesDataGrid,
			title: i18n.t("product_family_categories"),
			icon: faObjectGroup,
			url: "/configurator/productFamilyCategories",
		}),
		allInventoryLevelEventsPage: defineStaticView({
			component: AllInventoryLevelEventsDataGrid,
			title: i18n.t("inventory_level_events"),
			icon: faClipboardCheck,
			url: "/warehouse/inventory/allInventoryLevelEvents",
		}),
		configuratorTablesPage: defineStaticView({
			component: ConfiguratorTablesView,
			title: i18n.t("tables"),
			icon: faTable,
			url: "/configurator/tables",
		}),
		paymentTermsView: defineStaticView({
			component: PaymentTermsDataGrid,
			title: i18n.t("payment_term"),
			icon: faClipboardList,
			url: "/sales/paymentTerm",
		}),
		workspacesPage: defineStaticView({
			component: WorkspacesView,
			title: i18n.t("workspaces"),
			icon: faBriefcase,
			url: "/workspace/workspaces",
		}),
		effectiveTimeMeasurementView: defineStaticView({
			component: EffectiveTimeMeasuringView,
			title: i18n.t("time_measurement"),
			icon: faStopwatch,
			url: "/spc/timeMeasurement",
		}),
		cycleTimeMeasurementView: defineStaticView({
			component: CycleTimeMeasuringView,
			title: i18n.t("cycle_time_measurement"),
			icon: faRedo,
			url: "/spc/cycleTimeMeasurement",
		}),
		productionLineStatusGeneratedViews: defineGeneratorView({
			baseUrl: `/production/productionLinesStatus/`,
			icon: faConveyorBeltBoxes,
			component: ProductionLineStatusView,
			generate: async () => {
				const productionLines = await ProductionLineStatusViewApi.getProductionLinesForStatusViewGeneration();
				return productionLines.map((productionLine) => ({
					generatedKey: productionLine.productionLineId.toString(),
					title: productionLine.productionLineName,
					props: {
						productionLineId: productionLine.productionLineId,
					},
				}));
			},
		}),
		...MELLANO_VIEWS_BY_KEYS,
		...SALVOS_VIEWS_BY_KEYS,
	},
};
