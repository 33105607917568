import { FieldPath, FieldPathValue, useController, UseControllerProps } from "react-hook-form";
import { FormFieldLayoutProps, formFieldLayoutPropsToSx } from "src/components/common/forms/styles.ts";
import { DurationField, DurationFieldProps } from "../../inputFields/DurationField";
import { Duration } from "dayjs/plugin/duration";
import dayjs from "dayjs";
import { mergeSx } from "src/utils/styles.ts";

export interface FormDurationFieldProps<TFieldValues extends object, TFieldName extends FieldPath<TFieldValues>>
	extends Omit<DurationFieldProps, "value" | "ref" | "name">,
		Pick<UseControllerProps<TFieldValues, TFieldName>, "control" | "name" | "rules">,
		FormFieldLayoutProps {
	durationUnits: "seconds" | "milliseconds";
}

export const FormDurationField = <TFieldValues extends object, TFieldName extends FieldPath<TFieldValues>>({
	control,
	name,
	rules,
	durationUnits,
	onChange: onChangeProp,
	spanGridColumns,
	startNewGridRow,
	sx,
	...other
}: FormDurationFieldProps<TFieldValues, TFieldName>) => {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
		defaultValue: 0 as FieldPathValue<TFieldValues, TFieldName>,
	});

	return (
		<DurationField
			value={getValueAsDuration()}
			onChange={(value) => {
				onChangeProp?.(value);

				const mappedValue = mapValueToNumber(value);
				field.onChange(mappedValue);
			}}
			error={fieldState.error?.message}
			sx={mergeSx(
				formFieldLayoutPropsToSx({
					spanGridColumns,
					startNewGridRow
				}),
				sx,
			)}
			{...other}
		/>
	);

	function getValueAsDuration(): Duration | null {
		if (field.value == null) {
			return null;
		}
		return dayjs.duration({ [durationUnits]: field.value });
	}

	function mapValueToNumber(value: Duration | null): number | null {
		if (value == null) {
			return null;
		}
		switch (durationUnits) {
			case "seconds":
				return value.asSeconds();
			case "milliseconds":
				return value.asMilliseconds();
		}
	}
};
