import { formatNumber } from "src/utils/numberUtils.ts";
import i18n from "i18next";
import { SalvosCustomerOfferSummary } from "src/api/generated/tenants/salvos/erp/sales/customerOffer/salvosCustomerOfferSummary.ts";
import { CustomerOfferSummary } from "src/api/generated/erp/sales/customerOrderOffer/service/customerOfferSummary";

export const getSalvosCustomerOfferSummaryExtraFields = (summary: CustomerOfferSummary) => {
	if (!("discountMargin" in summary) || !("discountExtraMargin" in summary)) return [];

	const castSummary = summary as SalvosCustomerOfferSummary;

	return [
		{
			label: i18n.t("salvos.discount_margin"),
			value: `${formatNumber(castSummary.discountMargin)} €`,
		},
		{
			label: i18n.t("salvos.extra_discount_margin"),
			value: `${formatNumber(castSummary.discountExtraMargin)} €`,
		},
	];
};
