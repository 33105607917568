import { AavoDataGridRowContextMenuParams } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { CustomerOrderOfferView } from "src/api/generated/erp/db/types/tables/customerOrderOfferView.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { OpenCustomerButton } from "src/components/views/erp/utilComponents/OpenCustomerButton.tsx";
import i18n from "i18next";
import {
	faBan,
	faCopy,
	faEnvelope,
	faFileImport,
	faHandshake,
	faPrint,
	faShare,
	faThumbsDown,
	faTimesCircle,
} from "@fortawesome/pro-regular-svg-icons";
import {
	openLegacyCustomerOrderPreLineImportView,
	openLegacyDocumentSendingView,
} from "src/components/views/legacy/legacyViewAdapters.ts";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { CustomerOrderOfferActionsApi } from "src/api/generated/erp/sales/customerOrderOffer/api/customerOrderOfferActionsApi.ts";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { OpenObjectChangeLogButton } from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import { CreateOrderFromOfferForm } from "src/components/views/erp/sales/customerOrderOffer/CreateOrderFromOfferForm.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { CopyCustomerOrderOfferForm } from "src/components/views/erp/sales/customerOrderOffer/CopyCustomerOrderOfferForm.tsx";
import { useUserPermissions } from "src/components/views/erp/common/userPermissions.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";

export interface CustomerOrderOfferDataGridRowContextMenuProps
	extends AavoDataGridRowContextMenuParams<CustomerOrderOfferView> {
	refreshData: () => Promise<unknown>;
}

export const CustomerOrderOfferDataGridRowContextMenu = (props: CustomerOrderOfferDataGridRowContextMenuProps) => {
	const { onlySingleRowSelected, row, refreshData } = props;

	const openLegacyView = useOpenLegacyView();
	const showConfirmDialog = useConfirmDialog();
	const { openDialog } = useGenericDialog();
	const showInputDialog = useInputDialog();
	const tenantCustomizations = useTenantCustomizations();
	const userPermissions = useUserPermissions();
	const onlySalespersonData = userPermissions.sales.onlySalespersonOwnOfferData;

	return [
		onlySingleRowSelected && row.customerOrderId != null && !onlySalespersonData && (
			<OpenCustomerOrderButton key={"openCustomerOrder"} customerOrderId={row.customerOrderId} />
		),
		onlySingleRowSelected && <OpenCustomerButton key={"openCustomer"} customerId={row.customerId} />,
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"print"}
				label={i18n.t("print_offer")}
				icon={faPrint}
				onClick={async () => {
					const fileHandle = await CustomerOrderOfferActionsApi.printCustomerOrderOffer({
						customerOrderOfferId: row.customerOrderOfferId,
					});
					downloadFile(fileHandle);
				}}
			/>
		),
		onlySingleRowSelected && row.customerOrderOfferState === "INITIAL" && (
			<AsyncMenuButton
				key={"release"}
				label={i18n.t("release")}
				icon={faShare}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("release_offer_confirmation"),
					});
					if (!confirmed) return;
					await CustomerOrderOfferActionsApi.releaseCustomerOrderOffer({
						customerOrderOfferId: row.customerOrderOfferId,
					});
					await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && row.customerOrderOfferState === "INITIAL" && (
			<AsyncMenuButton
				key={"discard"}
				label={i18n.t("discard")}
				icon={faTimesCircle}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("discard_offer_confirmation"),
					});
					if (!confirmed) return;
					await CustomerOrderOfferActionsApi.discardCustomerOrderOffer({
						customerOrderOfferId: row.customerOrderOfferId,
					});
					await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && row.customerOrderOfferState === "OFFERED" && (
			<AsyncMenuButton
				key={"createCustomerOrder"}
				label={i18n.t("create_customer_order")}
				icon={faHandshake}
				onClick={() => {
					openDialog(({ closeDialog }) => ({
						title: i18n.t("create_customer_order"),
						size: "sm",
						content: (
							<CreateOrderFromOfferForm
								customerOrderOfferId={row.customerOrderOfferId}
								onCompleted={async () => {
									await closeDialog();
									await refreshData();
								}}
							/>
						),
					}));
				}}
			/>
		),
		onlySingleRowSelected && row.customerOrderOfferState === "OFFERED" && (
			<AsyncMenuButton
				key={"markAsLost"}
				label={i18n.t("mark_as_lost")}
				icon={faThumbsDown}
				onClick={async () => {
					const loseReason = await showInputDialog({
						type: "string",
						title: i18n.t("lose_reason"),
						fieldLabel: i18n.t("lose_reason"),
						defaultValue: "",
						fieldProps: {
							text: {
								multiLine: true,
							},
						},
					});
					if (loseReason === undefined) return;

					await CustomerOrderOfferActionsApi.markCustomerOrderOfferToLost({
						customerOrderOfferId: row.customerOrderOfferId,
						loseReason: loseReason,
					});
					await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && ["INITIAL", "OFFERED"].includes(row.customerOrderOfferState) && (
			<AsyncMenuButton
				key={"cancel"}
				label={i18n.t("cancel")}
				icon={faBan}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("cancel_offer_confirmation"),
					});
					if (!confirmed) return;
					await CustomerOrderOfferActionsApi.cancelCustomerOrderOffer({
						customerOrderOfferId: row.customerOrderOfferId,
					});
					await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"sendDocuments"}
				label={i18n.t("send_documents")}
				icon={faEnvelope}
				onClick={async () => {
					await openLegacyDocumentSendingView({
						openLegacyView: openLegacyView,
						objectRef: {
							objectType: "CUSTOMER_ORDER_OFFER",
							objectId: row.customerOrderOfferId,
						},
					});
				}}
			/>
		),
		onlySingleRowSelected && row.customerOrderOfferState === "INITIAL" && !onlySalespersonData && (
			<AsyncMenuButton
				key={"importLines"}
				label={i18n.t("import_lines")}
				icon={faFileImport}
				onClick={() => {
					openLegacyCustomerOrderPreLineImportView({
						openLegacyView: openLegacyView,
						targetType: "CUSTOMER_ORDER_OFFER",
						targetId: row.customerOrderOfferId,
						targetPackageLineId: null,
					});
				}}
			/>
		),
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"copyOffer"}
				label={i18n.t("copy_offer")}
				icon={faCopy}
				onClick={() => {
					openDialog(({ closeDialog }) => ({
						title: i18n.t("copy_offer"),
						size: "sm",
						content: (
							<CopyCustomerOrderOfferForm
								sourceOffer={row}
								onCompleted={async () => {
									await closeDialog();
									await refreshData();
								}}
							/>
						),
					}));
				}}
			/>
		),
		onlySingleRowSelected && (
			<OpenObjectChangeLogButton
				key={"changeLog"}
				objectRef={{
					objectType: "CUSTOMER_ORDER_OFFER",
					objectId: row.customerOrderOfferId,
				}}
			/>
		),
		tenantCustomizations.erp?.sales?.customerOfferContextMenuComponents?.(props),
	];
};
